import SoundTrack from '../../../../game/managers/soundManager/soundTrack';
import BgMusicTrack from './bgMusicTrack';
import SoundEffectsTrack from './soundEffectsTrack';
import BigWinTrack from './bigWinTrack';
import CachedSettingsManager from '../../../../cachedSettingsManager';
import SoundBase from '../../../../game/managers/soundManager/soundBase';

CachedSettingsManager.registerSetting('isSoundEnabled', false);

class SoundManager extends SoundTrack {
  private _bigWinTrack!: BigWinTrack;
  private _bgMusicTrack!: BgMusicTrack;
  private _soundEffectsTrack!: SoundEffectsTrack;

  mount() {
    this._bgMusicTrack = new BgMusicTrack('bgMusicTrack');
    this.registerTrack('bgMusicTrack', this._bgMusicTrack);
    this._bgMusicTrack.mount();
    this._soundEffectsTrack = new SoundEffectsTrack('soundEffectsTrack');
    this.registerTrack('soundEffectsTrack', this._soundEffectsTrack);
    this._soundEffectsTrack.mount();
    this._bigWinTrack = new BigWinTrack('bigWinTrack');
    this.registerTrack('bigWinTrack', this._bigWinTrack);
    this._bigWinTrack.mount(this._bgMusicTrack, this._soundEffectsTrack);

    CachedSettingsManager.addListener('isSoundEnabled', (isSoundEnabled) => {
      this.volume = isSoundEnabled ? 1 : 0;
    });

    this._bgMusicTrack.startGame();

    this._isMounted = true;
  }

  get bgMusicTrack() {
    return this._bgMusicTrack;
  }

  get soundEffectsTrack() {
    return this._soundEffectsTrack;
  }

  get bigWinTrack() {
    return this._bigWinTrack;
  }
}

export default SoundManager;
