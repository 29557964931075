import { Container, Text } from 'pixi.js';
import { formatAsCurrency } from '../../game/managers/currencyManager';

const changeComponentVisibility = (parent: Container, child: Container, isVisible: boolean) => {
  if (isVisible) {
    if (!parent.children.includes(child))
      parent.addChild(child);
    child.visible = true;
  }
  else {
    if (parent.children.includes(child))
      parent.removeChild(child);
    child.visible = false;
  }
};

const numberTextConsistentScaler = (
  text: Text,
  maxWidth: number,
  formatter?: (value: number) => string,
) => {
  let lastDigitCount = -1;

  const getUpdatedScale = (value: number) => {
    let targetScale = text.scale.x;
    const digitCount = Math.floor(Math.log10(value));

    if (digitCount !== lastDigitCount) {
      const bloatedNumber = Math.pow(10, digitCount) * 4;
      text.text = formatter ? formatter(bloatedNumber) : bloatedNumber;
      const lastScale = text.scale.x;
      text.scale.set(1);

      if (text.width > maxWidth) {
        const overlap = text.width - maxWidth;
        targetScale = (1 - overlap / text.width);
      }
      text.scale.set(lastScale);
      lastDigitCount = digitCount;
    }

    text.text = formatter ? formatter(value) : value;
    return { scale: targetScale };
  };

  return getUpdatedScale;
};

export {
  changeComponentVisibility,
  numberTextConsistentScaler,
};
