import PIXI, { Container, Particle, ParticleContainer } from 'pixi.js';
import { registerLogCategory } from '../../../../debug/privateLogger';

const log = registerLogCategory('ParticleRenderer');

// Used to manage the display and rendering of particles through the PIXI environment.
class ParticleRenderer {
  private _particleContainer!: ParticleContainer;
  // A list of properties that will possibly change on any particles that will be attached to this instance.
  // For max performance, use multiple ParticleRenderers with different dynamicPropsList if you have varying particles.
  private _dynamicPropsList!: string[];
  // The parent container that this ParticleRenderer will be attached to.
  private _parentContainer!: Container;

  constructor(
    parentContainer: Container,
    setup: (particleContainer: ParticleContainer) => void,
    dynamicPropsList: string[],
  ) {
    this._parentContainer = parentContainer;
    this._dynamicPropsList = dynamicPropsList;

    const dynamicProperties = this._dynamicPropsList.reduce((acc: Record<string, boolean>, prop) => {
      acc[prop] = true;
      return acc;
    }, {});
    this._particleContainer = new ParticleContainer({
      dynamicProperties,
    });

    this._parentContainer.addChild(this._particleContainer);
    setup(this._particleContainer);

    log(1)('ParticleRenderer', { _this: this, parentContainer, dynamicPropsList });
  }

  destroy() {
    log(2)('destroy', this);
    this._parentContainer.removeChild(this._particleContainer);
    this._particleContainer.destroy();
  }

  addParticle(particle: Particle) {
    log(3)('addParticle', particle);
    this._particleContainer.addParticle(particle);
  }

  removeParticle(particle: Particle) {
    log(3)('removeParticle', particle);
    this._particleContainer.removeParticle(particle);
  }
}

export default ParticleRenderer;
