import { Container } from 'pixi.js';
import { vec2 } from 'gl-matrix';

// Do not ask me why I needed to write this function.  I have no idea why pixi Container->toGlobal() returns incorrect
//   values!
// Also this function is barebones and does not yet account for rotation, anchors, etc.  Only scale and position offsets
const getLocalCoordsFor = (element: Container, target?: Container) => {
  let pos = vec2.fromValues(element.position.x, element.position.y);
  let walker: Container = element;
  while (walker !== target && walker.parent) {
    if (target && !walker.parent)
      throw new Error('Target not found in parent chain');

    pos[0] = pos[0] * walker.parent.scale.x + walker.parent.position.x;
    pos[1] = pos[1] * walker.parent.scale.y + walker.parent.position.y;
    walker = walker.parent;
  }

  return pos;
};

export {
  getLocalCoordsFor,
};
