class CurrencyManager {
  private static _instance: CurrencyManager; // Holds the singleton instance

  private _currency!: string;
  private _intlFormatter!: Intl.NumberFormat;

  constructor() {
    // @todo should an error be thrown if currency not provided, or should we decide on a default and move it to a
    // global?
    const currency = new URLSearchParams(window.location.search).get('currency') ?? 'usd';

    this._setCurrency(currency);
  }

  private _setCurrency(currency: string) {
    this._currency = currency;
    this._intlFormatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency,
    });
  }

  static getInstance(): CurrencyManager {
    if (!CurrencyManager._instance) CurrencyManager._instance = new CurrencyManager();

    return CurrencyManager._instance;
  }

  get currency() {
    return this._currency ?? undefined;
  }

  formatAsCurrency(amount: number) {
    if (typeof this._currency === 'undefined')
      throw new Error('unable to format currency number, currency not yet set');

    const formatted = this._intlFormatter.format(amount);

    return formatted.replace(/^[A-Za-z]+|[A-Za-z]+(?=[^A-Za-z]$)|[A-Za-z]+$/g, '').trim();
  }
}

const formatAsCurrency = (amount: number) => CurrencyManager.getInstance().formatAsCurrency(amount);

export default CurrencyManager;

export { formatAsCurrency };
