import { Application } from 'pixi.js';
import { sound } from '@pixi/sound';
import { IGameOutcome } from './dede/service/types';
import {
  AnteBetChangeListener,
  AssetsLoadedListener,
  AutoPlayChangeListener,
  ClickListener,
  OrientationChangeListener,
  ExplodeListener,
  FallCompleteListener,
  FreeSpinChangeListener,
  GameConfigChangeListener,
  GameLoadedListener,
  GameStateChangeListener,
  HistoryChangeListener,
  IFreeSpinStatus,
  IGameConfig,
  ISpinHistoryElement,
  Orientation,
  SymbolSelectionListener,
  SoundChangeListener,
  SpinCompleteListener,
  SpinListener,
  SpinWinAmountChangeListener,
  StakeChangeListener,
  TumbleListener,
  WinAmountChangeListener,
  IGamePosition,
  ResizeListener,
  InfoButtonClickListener,
  BasicListener,
  AssetsLoadingListener,
  BetButtonsEnabledChangeListener,
} from './types';
import GameEvent from './gameEvent';
import FpsCounter from '../debug/fpsCounter';
import CoreBalanceManager from '../game/managers/balanceManager';
import WinHistoryManager from '../game/managers/winHistoryManager';
import { SettingsManager } from '../game/managers/settingsManager';
import AssetLoader from './assetLoader';
import { LONG_DIMENSION, SHORT_DIMENSION } from './dede/resources/constants';
import Disabler from '../disabler';
import SessionIndicator from '../debug/sessionIndicator';

export class Game {
  sessionId!: number;
  id: string = '';
  app: Application;
  assetLoader!: AssetLoader;
  onSpin = new GameEvent<SpinListener>('onSpin');
  onSymbolSelection = new GameEvent<SymbolSelectionListener>('onSymbolSelection');
  onExplode = new GameEvent<ExplodeListener>('onExplode');
  onClick = new GameEvent<ClickListener>('onClick');
  onInfoButtonClick = new GameEvent<InfoButtonClickListener>('onInfoButtonClick');
  onSettingsButtonClick = new GameEvent<InfoButtonClickListener>('onSettingsButtonClick');
  onSoundChange = new GameEvent<SoundChangeListener>('onSoundChange');
  onPendingSpinResponseChange = new GameEvent<BasicListener>('onPendingSpinResponseChange');
  onWinAmountChange = new GameEvent<WinAmountChangeListener>('onWinAmountChange');
  onStakeChange = new GameEvent<StakeChangeListener>('onStakeChange');
  onOrientationChange = new GameEvent<OrientationChangeListener>('onOrientationChange');
  onLoaded = new GameEvent<GameLoadedListener>('onLoaded');
  onGameStateChange = new GameEvent<GameStateChangeListener>('onGameStateChange');
  onAnteBetChange = new GameEvent<AnteBetChangeListener>('onAnteBetChange');
  onTumble = new GameEvent<TumbleListener>('onTumble');
  onAssetsLoaded = new GameEvent<AssetsLoadedListener>('onAssetsLoaded');
  onFallComplete = new GameEvent<FallCompleteListener>('onFallComplete');
  onBeforeFallComplete = new GameEvent<FallCompleteListener>('onBeforeFallComplete');
  onSpinComplete = new GameEvent<SpinCompleteListener>('onSpinComplete');
  onHistoryChange = new GameEvent<HistoryChangeListener>('onHistoryChange');
  onAutoplayChange = new GameEvent<AutoPlayChangeListener>('onAutoplayChange');
  onGameConfigChange = new GameEvent<GameConfigChangeListener>('onGameConfigChange');
  onResize = new GameEvent<ResizeListener>('onResize');
  onFreeSpinChange = new GameEvent<FreeSpinChangeListener>('onFreeSpinChange');
  onNewSymbolsComing = new GameEvent<BasicListener>('onNewSymbolsComing');
  onOldSymbolsDropping = new GameEvent<BasicListener>('onOldSymbolsDropping');
  onSpacePressed = new GameEvent<BasicListener>('onSpacePressed');
  onGamePaused = new GameEvent<BasicListener>('onGamePaused');
  onGameUnPaused = new GameEvent<BasicListener>('onGameUnPaused');
  onAssetsLoading = new GameEvent<AssetsLoadingListener>('onAssetsLoading');
  onBetButtonsEnabledChange = new GameEvent<BetButtonsEnabledChangeListener>(
    'onBetButtonsEnabledChange'
  );

  static settings = new SettingsManager();
  private _pendingSpinResponse = false;
  private _freeSpinWinAmount = 0;
  private _freeSpinTotalMultiplier = 0;
  private _freeSpinActivated = false;
  private _freeSpinActivating = false;
  private _freeSpinSpinsStarted = false;
  private _winAmount: number = 0;
  private _spinWinAmount: number = 0;
  private _stake: number = 0;
  private _orientation: Orientation = 'portrait';
  private _loaded = false;
  private _isRunning: boolean = false;
  private _anteBetActive: boolean = false;
  private _history: ISpinHistoryElement[] = [];
  private _autoPlayCount: number = 0;
  private _gamePosition: IGamePosition = { width: 0, height: 0, xOffset: 0, yOffset: 0, scale: 1 };
  private _turboSpinActive = false;
  private _finishInstantly = false;

  protected _balanceManager!: CoreBalanceManager;
  protected winHistoryManager!: WinHistoryManager;

  betButtonsDisabler = new Disabler((disabled) =>
    this.onBetButtonsEnabledChange.triggerEvent(!disabled)
  );

  gameDisabler = new Disabler((disabled) => {
    if (disabled) this.onGamePaused.triggerEvent();
    else this.onGameUnPaused.triggerEvent();
  });

  private _config: IGameConfig = {
    payTable: {
      symbolPayouts: [],
    },
    limits: {
      defaultBet: 0,
      legalBets: [],
      autoBets: [],
      winSizes: [],
    },
    freeSpinBuyMultiplier: 0,
    scatterSymbol: 0,
    pingPeriodMilliseconds: 5000,
  };

  index = 0;
  initialSpinDone = false;

  constructor() {
    if (this.constructor === Game) {
      throw new Error('Cannot instantiate an abstract class.');
    }

    this.app = new Application();

    // Will register the fps counter system but won't run it unless told to do so from settings (see md file)
    new FpsCounter(this.app);
    new SessionIndicator(this.app);

    window.addEventListener('resize', this._handleResize);
    window.addEventListener('orientationchange', this._handleResize);
    setTimeout(() => {
      this.onOrientationChange.triggerEvent(this.getOrientation());
    }, 500);
    this.id = Math.random().toString(36).substring(2, 9);
    this.onAutoplayChange.addEventListener((event, { newAutoPlayCount, prev }) => {
      if (prev === 0 && newAutoPlayCount) {
        this.runReels();
      }
    });

    // get spacebar key press
    window.addEventListener('keydown', (e) => {
      if (e.code === 'Space') {
        if (this.betButtonsDisabler.disabled) {
          this.finishInstantlyActive = true;
        } else {
          this.runReels();
        }
        this.onSpacePressed.triggerEvent();
      }
    });

    this.onSpin.addEventListener(() => {
      this.betButtonsDisabler.disable('spin');
    });
    this.onSpinComplete.addEventListener(() => {
      this.betButtonsDisabler.enable('spin');
    });
  }

  private _handleResize = () => {
    const newOrientation = this.getOrientation();
    if (newOrientation !== this._orientation) {
      this._orientation = newOrientation;
      this.onOrientationChange.triggerEvent(newOrientation);
    }
  };

  // Abstract method
  runReels(buyFreeSpins: boolean = false): void {
    throw new Error("Abstract method 'runReels' must be implemented.");
  }

  simulate(response: string): void {
    throw new Error("Abstract method 'simulate' must be implemented.");
  }

  onGoldenBetClick(): void {
    throw new Error("Abstract method 'onGoldenBetClick' must be implemented.");
  }

  public get turboSpinActive(): boolean {
    return this._turboSpinActive;
  }

  public set turboSpinActive(value: boolean) {
    this._turboSpinActive = value;
  }

  public get finishInstantlyActive(): boolean {
    return this._finishInstantly;
  }

  public set finishInstantlyActive(value: boolean) {
    this._finishInstantly = value;
  }

  public get paused(): boolean {
    return this.gameDisabler.disabled;
  }

  public get pendingSpinResponse(): boolean {
    return this._pendingSpinResponse;
  }

  public set pendingSpinResponse(value: boolean) {
    this._pendingSpinResponse = value;
    this.onPendingSpinResponseChange.triggerEvent();
  }

  public get width(): number {
    return this._gamePosition.width;
  }

  public get height(): number {
    return this._gamePosition.height;
  }

  public get xOffset(): number {
    return this._gamePosition.xOffset;
  }

  public get yOffset(): number {
    return this._gamePosition.yOffset;
  }

  public get scale(): number {
    return this._gamePosition.scale;
  }

  public get history(): ISpinHistoryElement[] {
    return this._history;
  }

  public set history(value: ISpinHistoryElement[]) {
    this._history = value;
    this.onHistoryChange.triggerEvent(value);
  }

  get balanceManager() {
    return this._balanceManager;
  }

  public get loaded(): boolean {
    return this._loaded;
  }

  public set loaded(value: boolean) {
    if (value) {
      this._loaded = value;
      this.onLoaded.triggerEvent();
    }
  }

  public get autoPlayCount(): number {
    return this._autoPlayCount;
  }

  public set autoPlayCount(value: number) {
    if ((this.balanceManager?.balance || 0) < this.stake) {
      value = 0;
    }
    const prev = this._autoPlayCount;
    this._autoPlayCount = value;
    if (prev !== value) this.onAutoplayChange.triggerEvent({ newAutoPlayCount: value, prev });
  }

  public get winAmount(): number {
    return this._winAmount;
  }

  public set winAmount(value: number) {
    const prev = this._winAmount;
    this._winAmount = value;
    this.onWinAmountChange.triggerEvent({ newWinAmount: value, diff: Math.min(value - prev, 0) });
  }

  public get freeSpinWinAmount(): number {
    return this._freeSpinWinAmount;
  }

  public set freeSpinWinAmount(value: number) {
    const prev = this._freeSpinWinAmount;
    this._freeSpinWinAmount = value;
    if (prev !== value) this._handleFreeSpinChange();
  }

  public get freeSpinTotalMultiplier(): number {
    return this._freeSpinTotalMultiplier;
  }

  public set freeSpinTotalMultiplier(value: number) {
    const prev = this._freeSpinTotalMultiplier;
    this._freeSpinTotalMultiplier = value;
    if (prev !== value) this._handleFreeSpinChange();
  }

  public get freeSpinSpinsStarted(): boolean {
    return this._freeSpinSpinsStarted;
  }

  public set freeSpinSpinsStarted(value: boolean) {
    this._freeSpinSpinsStarted = value;
  }

  public get freeSpinActivated(): boolean {
    return this._freeSpinActivated;
  }

  public set freeSpinActivated(value: boolean) {
    this._freeSpinActivated = value;
    if (this._freeSpinActivated) {
      this.freeSpinActivating = false;
    } else {
      this._freeSpinSpinsStarted = false;
    }
    this._handleFreeSpinChange();
  }

  public get freeSpinActivating(): boolean {
    return this._freeSpinActivating;
  }

  public set freeSpinActivating(value: boolean) {
    this._freeSpinActivating = value;
    this._handleFreeSpinChange();
  }

  public get stake(): number {
    return this._stake;
  }

  public set stake(value: number) {
    this._stake = value;
    this.onStakeChange.triggerEvent(value);
  }

  public get isRunning(): boolean {
    return this._isRunning;
  }

  protected set isRunning(value: boolean) {
    this._isRunning = value;
    this.onGameStateChange.triggerEvent(value);
  }

  public get anteBetActive(): boolean {
    return this._anteBetActive;
  }

  public set anteBetActive(value: boolean) {
    const prev = this._anteBetActive;
    this._anteBetActive = value;
    if (prev !== value) {
      this.onAnteBetChange.triggerEvent(value);
    }
  }

  public get config(): IGameConfig {
    return this._config;
  }

  public set config(value: IGameConfig) {
    if (JSON.stringify(this._config) === JSON.stringify(value)) return;
    this._config = value;
    this.onGameConfigChange.triggerEvent(value);
  }

  // methods

  private _handleFreeSpinChange() {
    this.onFreeSpinChange.triggerEvent({
      isActivating: this._freeSpinActivating,
      activated: this._freeSpinActivated,
      totalWinning: this._freeSpinWinAmount,
      totalMultiplier: this._freeSpinTotalMultiplier,
    });
  }

  tumble(outcome: IGameOutcome) {
    this.onTumble.triggerEvent(outcome);
  }

  onMountDone() {
    this.loaded = true;
    sound.disableAutoPause = true;
    this.onOrientationChange.triggerEvent(this.getOrientation());
  }

  getOrientation(): Orientation {
    return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
  }

  handleWindowResize = (orientation: Orientation) => {
    if (!this.app.renderer) return;
    if (orientation === 'landscape') {
      const height = window.innerHeight * (LONG_DIMENSION / window.innerWidth);
      let scale = 1;
      let width = LONG_DIMENSION;
      if (height < SHORT_DIMENSION) {
        scale = height / SHORT_DIMENSION;
      }
      this.resize({
        scale,
        width,
        height,
        xOffset: 0,
        yOffset: 0,
      });
    } else {
      let scale = 1;
      let width = window.innerWidth * (LONG_DIMENSION / window.innerHeight);
      let height = LONG_DIMENSION;
      if (width < SHORT_DIMENSION) {
        scale = width / SHORT_DIMENSION;
      }
      this.resize({
        scale,
        width,
        height,
        xOffset: 0,
        yOffset: 0,
      });
    }

    this.app.renderer.resize(this.width, this.height);
    this.app.canvas.style.width = window.innerWidth + 'px';
    this.app.canvas.style.height = window.innerHeight + 'px';

    // this.renderCoordinates();
  };

  resize = (newPosition: IGamePosition) => {
    this._gamePosition = newPosition;
    this.onResize.triggerEvent(newPosition);
  };
}
