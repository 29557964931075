import { Progress } from 'antd';
import React, { memo } from 'react';
import styles from './index.module.css';

const GameLoader: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <div className={styles.container}>
      <img src="/assets/games/dede/logo/accamax/logo.webp" />
      <Progress
        style={{ color: '#EBEBEB' }}
        type="line"
        percent={Number((progress * 100).toFixed(2))}
        format={(progress) => <span style={{ color: 'white' }}>{progress}</span>}
        strokeColor="#FF5B00"
      />
    </div>
  );
};

export default memo(GameLoader);
