import { Container, Text } from 'pixi.js';
import { registerLogCategory } from '../../../../debug/privateLogger';
import { IEventDetails } from '../../../gameEvent';
import CoreBalanceManager from '../../../../game/managers/balanceManager';
import { TBalanceEvent } from '../../../../game/managers/balanceManager/balanceManager.types';
import LayoutManager from '../layoutManager';
import { getLocale } from '../../../../../localization';

const log = registerLogCategory('BalanceManager');

class BalanceManager extends CoreBalanceManager {
  // Used to prevent mount from happening twice
  private _pixiBalanceContainerIsMounted = false;

  private _updateValueOfTextControl!: (value?: number) => void;

  // Needs to be called after pixi app is ready to show stuff
  mountPixiBalanceContainer(_pixiContainer: Container) {
    log(2)('mountPixiBalanceContainer', {
      _pixiContainer,
      isMounted: this._pixiBalanceContainerIsMounted,
      dimensionsInterface: this._gameDimensionsInterface,
    });
    if (this._pixiBalanceContainerIsMounted) return;

    if (!this._gameDimensionsInterface)
      throw new Error('Cannot set up a balance container without access to dimensions information');

    const { updateValue, container } = LayoutManager.createBalanceStyleText(_pixiContainer, {
      x: this._gameDimensionsInterface.width - 10,
      y: this._gameDimensionsInterface.height - 20,
      anchorX: 1,
      label: getLocale('slots.ui.common.balance').toLocaleUpperCase(),
    });
    this._updateValueOfTextControl = updateValue;
    this._pixiContainer = container;

    this._pixiBalanceContainerIsMounted = true;

    this._updateBalanceText();
    this._updatePosition();

    this._gameDimensionsInterface.onResize.addEventListener(() => {
      this._updatePosition();
    });
  }

  private _updatePosition() {
    log(2)('updatePosition', {
      errorOut: !this._gameDimensionsInterface,
      pixiContainer: this._pixiContainer,
      game: this._gameDimensionsInterface,
    });

    if (!this._gameDimensionsInterface)
      throw new Error('Cannot update a balance container without access to dimensions information');

    this._pixiContainer.y = this._gameDimensionsInterface.height - 20;
    this._pixiContainer.x =
      this._gameDimensionsInterface.width -
      10 -
      (this._gameDimensionsInterface.width - this._gameDimensionsInterface.reelsContainerWidth) / 2;
  }

  private _updateBalanceText() {
    log(3)('_updateBalanceText', {
      isMounted: this._pixiBalanceContainerIsMounted,
      balance: this._balance,
      textEl: this._pixiContainer,
    });

    if (!this._pixiBalanceContainerIsMounted) return;

    this._updateValueOfTextControl(this._balance);
  }

  // A listener for this is set up by the core BalanceManager class
  protected _handleBalanceChanged(event: IEventDetails, balanceEvent: TBalanceEvent) {
    log(3)('_handleBalanceChanged called', { event, balanceEvent });
    this._updateBalanceText();
  }
}

export default BalanceManager;
