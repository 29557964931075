import { BitmapText, Sprite, Text, Texture, Ticker } from 'pixi.js';
import {
  DESTROY_Y_LIMIT,
  HIGH_SYMBOL_SCALE,
  JACKPOT_META_X_OFFSET,
  JACKPOT_META_Y_OFFSET,
  LOW_SYMBOL_SCALE,
  STANDARD_FPS,
  SYMBOL_GAP,
  SYMBOL_WIDTH,
} from '../../resources/constants';
import { ISymbolEvents } from './types';
import Reel from '../reel/index';
import { Game } from '../../../game';
import { Spine } from '@pixi/spine-pixi';
import delay from 'delay';
import { applyGoldenMoneyTextStyle } from '../../managers/textStyles';
import { vec2 } from 'gl-matrix';
import { getLocalCoordsFor } from '../../../../game/math/layoutUtils';
import GameEvent, { IEventDetails } from '../../../gameEvent';
import { registerLogCategory } from '../../../../debug/privateLogger';
import { simpleAnimatePropertiesTo } from '../../../../game/managers/animationManager';
import { fontSafeString } from '../../resources/fonts/fonts';
import { formatAsCurrency } from '../../../../game/managers/currencyManager';

export type TLandEvent = { firstLand: boolean };
export type TLandListener = (event: IEventDetails, symbolLandEvent: TLandEvent) => void;

const log = registerLogCategory('Symbol');

const turboSpeedMultiplier = 2;
const triggerParticlesY = 125;

const symbolTextSize = {
  multiplier: 384,
  large: 400,
  small: 100,
};
const symbolTextSpacingAdjust = {
  multiplier: -20,
  large: -30,
  small: -10,
};

export default class Symbol {
  border?: Sprite;
  falling = true;
  moveAfterDelay = false;
  stopAnimationNeeded = true;
  multiplierAnimationStarted = false;
  destroying = false;
  exploded = false;
  fallAnimationTime = 0;
  fallAnimationStarted = false;
  velocityY = 0;
  multiplier = 0;
  private _hasLandedBefore = false;
  private _hasPresentedBefore = false;

  // I know that there appears to be another event system entirely, but it would be too difficult for me to use that and
  // this is how the code is actually supposed to be written, so I'm going with this approach now.  The other event
  // stuff will eventually need to be cleaned up.

  // Whenever this symbol touches the end of its fall distance.  Can happen more than once if symbols below it explode.
  private _onSymbolLand = new GameEvent<TLandListener>('Symbol->onSymbolLand');
  // When the impact/stopping animation/sequence finishes (does not include any showboating)
  private _onSymbolStopComplete = new GameEvent('Symbol->onSymbolStopComplete');
  // When any and all showboating is finished
  private _onPresentationComplete = new GameEvent('Symbol->onPresentationComplete');

  private _removeOnAllReelsStoppedListener!: () => void;

  /** CONSTRUCTOR **/

  constructor(
    private reel: Reel,
    public symbolType: number,
    public spine: Spine,
    public position: { start: { x: number; y: number }; end: { y: number } },
    private events: ISymbolEvents,
    public index: number,
    moveAfterDelayTime: number,
    public jackpotSymbol = false,
    onAllReelsStopped: GameEvent
  ) {
    this.spine.x = this.position.start.x;
    this.spine.y = this.position.start.y;
    if (symbolType > 100000) {
      this.multiplier = symbolType - 100000;
    }
    this.fallDown(position.end.y);
    if (this.multiplier > 0) {
      // this.showText(`x${this.multiplier}`, '#4E5B7DFF', 480);
    }

    if (jackpotSymbol) {
      const jackpotSpine = Spine.from({
        skeleton: 'jackpotMetaData',
        atlas: 'jackpotMetaAtlas',
      });
      let scale =
        this.symbolType > 100000 ? 1 : this.symbolType < 4 ? HIGH_SYMBOL_SCALE : LOW_SYMBOL_SCALE;
      jackpotSpine.x = (JACKPOT_META_X_OFFSET * scale) / 2;
      jackpotSpine.y = (JACKPOT_META_Y_OFFSET * scale) / 2;
      jackpotSpine.scale.set(scale * 1.3);
      jackpotSpine.state.setAnimation(0, 'idle_coin', true);
      this.spine.addChild(jackpotSpine);
    }
    if (moveAfterDelayTime > 0) {
      this.moveAfterDelay = true;
      setTimeout(() => {
        this.moveAfterDelay = false;
      }, moveAfterDelayTime);
    }

    this._removeOnAllReelsStoppedListener = onAllReelsStopped.addEventListener(
      (event: IEventDetails) => this._handleAllReelsStopped()
    );

    log(1)('Symbol created', {
      reel,
      symbolType,
      spine,
      position,
      index,
      jackpotSymbol,
    });
  }

  destroy() {
    log(1)('Symbol destroyed');
    this.destroying = true;
  }

  /** READONLY */

  get hasLandedBefore() {
    return this._hasLandedBefore;
  }

  get onSymbolLand() {
    return this._onSymbolLand;
  }

  get onSymbolStopComplete() {
    return this._onSymbolStopComplete;
  }

  get onPresentationComplete() {
    return this._onPresentationComplete;
  }

  /** EVENT HANDLERS **/

  protected _symbolLand = () => {
    // @todo: This needs to go in a better place
    log(2)('Symbol landed', {
      symbolType: this.symbolType,
      index: this.index,
      reelIndex: this.reel.index,
    });
    if (this.index === 4) this.reel.game.soundManager.soundEffectsTrack.playSymLand();

    this._onSymbolLand.triggerEvent({ firstLand: !this._hasLandedBefore });
    this._hasLandedBefore = true;

    // This is a hack to keep post-presented animation display and not overwrite it with a second land animation.
    if (!this._hasPresentedBefore || this.symbolType <= 100000) {
      const stopAnimation = this.spine.state.setAnimation(0, 'stop', false);
      stopAnimation.listener = {
        complete: () => {
          log(3)(`Symbol stop complete`, {
            symbolType: this.symbolType,
            index: this.index,
            reelIndex: this.reel.index,
          });
          this._onSymbolStopComplete.triggerEvent();
        },
      };
    } else this._onSymbolStopComplete.triggerEvent();
  };

  // protected _handleAllReelsLanded = ({ firstLand }: TLandEvent) => {
  protected _handleAllReelsStopped = () => {
    if (this.symbolType <= 100000 || this._hasPresentedBefore)
      this._onPresentationComplete.triggerEvent();

    this._hasPresentedBefore = true;
  };

  showText(
    text: string | number,
    color: string,
    fontSize: number = 50,
    {
      useHeavyGlow = false,
    }: {
      useHeavyGlow?: boolean;
    } = {}
  ) {
    this.spine.zIndex = 100;
    // This timeout is needed as modifying heirarchy from inside an animation seems to crash the game.
    window.setTimeout(() => {
      const symbolCategory =
        this.symbolType === 9
          ? 'scatter'
          : this.symbolType > 100000
          ? 'multiplier'
          : this.symbolType < 4
          ? 'large'
          : 'small';
      if (symbolCategory === 'scatter') return;
      // let scale
      //   = (this.symbolType > 100000 ? 1 : (this.symbolType < 4 ? LOW_SYMBOL_SCALE : HIGH_SYMBOL_SCALE) * 1.5) * 1.25;

      const gameTextElement = new BitmapText({
        text: fontSafeString(
          'symbolOverlayFont',
          typeof text === 'string' ? text : formatAsCurrency(text)
        ),
        style: {
          fontFamily: 'symbolOverlayFont',
          fontSize: symbolTextSize[symbolCategory], // fontSize / scale,
          letterSpacing: symbolTextSpacingAdjust[symbolCategory],
        },
        zIndex: 100,
      });

      // const gameTextElement = new Text();
      // gameTextElement.style = {
      //   fontSize: fontSize / scale,
      //   fontFamily: 'symbolOverLayFont',
      // };
      // applyGoldenMoneyTextStyle(gameTextElement, { useHeavyGlow });
      // gameTextElement.text = text;
      // gameTextElement.zIndex = 100;
      // gameTextElement.style.fontFamily = 'symbolOverLayFont';

      const textContainer = new Sprite();
      textContainer.addChild(gameTextElement);
      // TODO: Ashley please check here. spine comes undefined sometimes.
      if (!this.spine) return;
      this.spine.addChild(textContainer);
      textContainer.x = -gameTextElement.width / 2;
      textContainer.y = -gameTextElement.height / 2;

      gameTextElement.alpha = 0;
      simpleAnimatePropertiesTo(
        250 / (this.reel.game.turboSpinActive || this.reel.game.finishInstantlyActive ? 2 : 1),
        gameTextElement,
        gameTextElement,
        { alpha: { endValue: 1 } }
      );
    }, 1);
  }

  animateWin() {
    this.spine.state.setAnimation(0, 'win', true);
    this.spine.state.timeScale =
      this.reel.game.turboSpinActive || this.reel.game.finishInstantlyActive
        ? turboSpeedMultiplier
        : 1;
  }

  select() {
    let selectSpine = Spine.from({ skeleton: 'paylineData', atlas: 'paylineAtlas' });
    this.reel.container.addChild(selectSpine);
    selectSpine.x = this.spine.x;
    selectSpine.y = this.spine.y;
    selectSpine.width = this.spine.width + SYMBOL_GAP / 1.5;
    selectSpine.height = this.spine.height + SYMBOL_GAP / 1.5;
    selectSpine.state.timeScale = 1.1;

    const winAnimation = selectSpine.state.setAnimation(0, 'win', false);
    this.spine.state.setAnimation(0, 'win', false);
    selectSpine.state.timeScale =
      this.reel.game.turboSpinActive || this.reel.game.finishInstantlyActive
        ? turboSpeedMultiplier
        : 1;
    winAnimation.listener = {
      complete: () => {
        setTimeout(async () => {
          selectSpine.state.timeScale = 1;
          this.reel.container.removeChild(selectSpine);
          await delay(5000);
          selectSpine.destroy();
        }, 0);
      },
    };
  }

  deselect() {
    if (this.border) {
      this.reel.container.removeChild(this.border);
      this.border.destroy();
      this.border = undefined;
    }
  }

  shake = (magnitude: number, duration: number) => {
    const originalX = this.spine.x;
    const originalY = this.spine.y;
    let elapsed = 0;
    const shakeUpdate = () => {
      const elapsedFraction = elapsed / duration;
      const damping = 1 - elapsedFraction;

      const offsetX = Math.random() * magnitude * 2 - magnitude;
      const offsetY = Math.random() * magnitude * 2 - magnitude;

      this.spine.x = originalX + offsetX * damping;
      this.spine.y = originalY + offsetY * damping;

      elapsed += this.reel.game.app.ticker.elapsedMS;

      if (elapsed < duration) {
        requestAnimationFrame(shakeUpdate);
      } else {
        this.spine.x = originalX;
        this.spine.y = originalY;
      }
    };
    shakeUpdate();
  };

  // Update Functions
  update(ticker: Ticker) {
    log(4)('Symbol update', {
      symbolType: this.symbolType,
      index: this.index,
      reelIndex: this.reel.index,
      falling: this.falling,
      destroying: this.destroying,
      exploded: this.exploded,
      moveAfterDelay: this.moveAfterDelay,
      y: this.spine.y,
      endY: this.position.end.y,
    });

    if (this.exploded) {
    } else if (this.falling) {
      if (!this.moveAfterDelay) {
        if (this.spine.y < this.position.end.y) this.updateFalling(ticker);
        else {
          this.spine.x = this.position.start.x;
          this.spine.y = this.position.end.y;
        }
      }

      const animateMultiplier = () => {
        if (!this.spine.state) return;
        const winAnimation = this.spine.state.setAnimation(0, 'win', false);
        this.spine.state.timeScale =
          2 *
          (this.reel.game.turboSpinActive || this.reel.game.finishInstantlyActive
            ? turboSpeedMultiplier
            : 1);
        winAnimation.listener = {
          complete: () => {
            this.spine.state.timeScale = 1;
            this.spine.state.setAnimation(0, 'win_idle', true);
            this._onPresentationComplete.triggerEvent();
          },
        };
      };

      if (
        this.symbolType > 100000 &&
        !this._hasPresentedBefore &&
        this.spine.y >= triggerParticlesY
      ) {
        this._hasPresentedBefore = true;
        const originalY = this.spine.y;
        // Need to temporarily move the symbol to the end position so the coordinate chain can be calculated correctly
        //   up the ancestor elements.
        this.spine.y = this.position.end.y;
        let _pos = getLocalCoordsFor(this.spine);
        vec2.sub(_pos, _pos, vec2.fromValues(40, 40));
        this.spine.y = originalY;

        if (!this.reel.game.jackpotManager.jackpotResponse?.winAmount) {
          // @ts-ignore
          this.reel.game.multiplierParticleStreamGenerator(_pos[0], _pos[1]).then(() => {
            window.setTimeout(
              () => {
                this.showText(`x${this.multiplier}`, '#4E5B7DFF', 480);
              },
              this.reel.game.turboSpinActive || this.reel.game.finishInstantlyActive ? 250 : 500
            );
            animateMultiplier();
          });
        } else {
          animateMultiplier();
        }
      }
    } else if (this.destroying) {
      if (this.spine.y < DESTROY_Y_LIMIT) this.updateFalling(ticker);
    }

    this.checkDestroyingFinished();
    this.checkFallingFinished();
  }

  updateFalling(ticker: Ticker) {
    const fpsRatio = ticker.FPS / STANDARD_FPS;

    const gravity = this.reel.game.finishInstantlyActive
      ? Game.settings.animation.symbolAnimation.finishInstantlyGravity
      : this.reel.game.turboSpinActive
      ? Game.settings.animation.symbolAnimation.turboSpinGravity
      : Game.settings.animation.symbolAnimation.gravity;

    this.velocityY += gravity / fpsRatio;
    this.spine.y += this.velocityY;
    this.spine.x += Game.settings.animation.symbolAnimation.wind / fpsRatio;
  }

  // Check Events
  checkDestroyingFinished() {
    if (this.destroying && this.spine.y >= DESTROY_Y_LIMIT) {
      this.destroying = false;
      this.events.onDestroy();
      if (!this.spine.destroyed) this.spine.destroy();
      this._onSymbolLand.removeAllListeners();
      this._onSymbolStopComplete.removeAllListeners();
      this._onPresentationComplete.removeAllListeners();
      this._removeOnAllReelsStoppedListener();
    }
  }

  checkFallingFinished() {
    if (this.falling && (this.spine.destroyed || this.spine?.y >= this.position.end.y)) {
      log(2)(`Symbol fall complete`, { index: this.index, reelIndex: this.reel.index });
      this.falling = false;
      this.velocityY = 0;
      this.spine.y = this.position.end.y;
      if (!this.spine.destroyed) this._symbolLand();
      this.events.onFallComplete();
    }
  }

  fallDown(newY: number, clearVelocity = true) {
    log(2)(`Symbol fall down`, {
      newY,
      clearVelocity,
      index: this.index,
      reelIndex: this.reel.index,
    });
    if (newY !== this.position.end.y) this.stopAnimationNeeded = true;
    this.position.end.y = newY;
    this.falling = true;

    this.fallAnimationStarted = false;
    if (clearVelocity) {
      this.velocityY = 0;
    }
  }

  fadeOut() {
    if (!this.multiplier) this.spine.alpha = 0.4;
  }

  fadeIn() {
    this.spine.alpha = 1;
  }

  explode() {
    let spine = Spine.from({ skeleton: 'blastData', atlas: 'blastAtlas' });
    spine.x = this.spine.x;
    spine.y = this.spine.y;
    spine.scale.set(0.1);
    spine.state.timeScale = 1;

    const animation = spine.state.setAnimation(0, 'explotion', false);
    spine.state.timeScale =
      this.reel.game.turboSpinActive || this.reel.game.finishInstantlyActive
        ? turboSpeedMultiplier
        : 1;
    let destroyTimeout: any;
    animation.listener = {
      complete: () => {
        spine.state.timeScale = 1;
        clearTimeout(destroyTimeout);
        destroyTimeout = setTimeout(() => {
          this.reel.container.removeChild(spine);
          spine.destroy();
          this.events.onExplodeComplete();
        }, 0);
      },
    };

    this.exploded = true;
    this.reel.container.addChild(spine);
    this.reel.container.removeChild(this.spine);
  }
}
