import { registerLogCategory } from '../../../debug/privateLogger';
import SoundBase from './soundBase';

const musicLog = registerLogCategory('Music');

class Music extends SoundBase {
  constructor(
    musicName: string,
    {
      loop = false,
      duckingVolumeMultiplier = 0.3,
      duckOutDuration = 300,
      maxVolume = 1,
    }: {
      loop?: boolean;
      duckingVolumeMultiplier?: number;
      duckOutDuration?: number;
      maxVolume?: number;
    } = {},
  ) {
    super(musicName, { duckingVolumeMultiplier, duckOutDuration, maxVolume });

    this.loop = loop;

    musicLog(1)('constructor', musicName);
  }

  play() {
    musicLog(2)('play', this._soundName);

    if (this.isPlaying)
      return;

    this._soundBasePlay();
  }
}

export default Music;
