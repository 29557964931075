type SettingChangeListener = (newValue: unknown, oldValue: unknown) => void;

class CachedSettingsManager {
  // Just using a singleton pattern here
  private static instance: CachedSettingsManager;

  // in page copy of settings for quicker access compared to localStorage
  private settings: { [key: string]: unknown } = {};
  private listeners: { [key: string]: Set<SettingChangeListener> } = {};
  private settingsIsDirty: boolean = false;
  // Used by debounce/throttling functionality of saving to localStorage
  private saveTimeout: number | null = null;

  private readonly localStorageSaveThrottleInterval = 500;
  private readonly storageKey = 'CachedSettingsManager';

  private constructor() {
    // Fetch settings from localStorage
    this.loadSettings();

    window.addEventListener('beforeunload', (event) => {
      // If they try to leave the page and there are unsaved settings, attempt to save it.
      if (this.settingsIsDirty) {
        localStorage.setItem(this.storageKey, JSON.stringify(this.settings));
        this.settingsIsDirty = false;
      }

      // Kill any currently running timers, we would already have saved
      if (this.saveTimeout) {
        window.clearTimeout(this.saveTimeout);
        this.saveTimeout = null;
      }
    });
  }

  // Singleton pattern stuff
  public static getInstance(): CachedSettingsManager {
    if (!CachedSettingsManager.instance) {
      CachedSettingsManager.instance = new CachedSettingsManager();
    }
    return CachedSettingsManager.instance;
  }

  private loadSettings(): void {
    const storedSettings = JSON.parse(localStorage.getItem(this.storageKey) ?? '{}');
    if (storedSettings) {
      // Update our page settings copy from localStorage if available
      this.settings = { ...this.settings, ...storedSettings };
    }
  }

  // This function saves to localStorage only if there are changes.
  // It normally does this after a delay in order to throttle the amount of calls.
  // If you call it with useEarlySave and there are no current timers/throttling happening,
  // it will save any changes immediately instead of waiting, but will still start a timer,
  // in case another save attempt comes through too quickly.
  private saveSettings(useEarlySave: boolean = false): void {
    if (this.saveTimeout === null) {
      if (useEarlySave && this.settingsIsDirty) {
        localStorage.setItem(this.storageKey, JSON.stringify(this.settings));
        this.settingsIsDirty = false;
      }

      this.saveTimeout = window.setTimeout(() => {
        if (this.settingsIsDirty) {
          localStorage.setItem(this.storageKey, JSON.stringify(this.settings));
          this.settingsIsDirty = false;
        }
        this.saveTimeout = null;
      }, this.localStorageSaveThrottleInterval);
    }
  }

  // Used to notify any subscribers waiting for changes on a particular setting
  private notifyListeners(settingName: string, newValue: unknown, oldValue: unknown): void {
    const settingListeners = this.listeners[settingName];
    if (settingListeners) {
      settingListeners.forEach((callback) => {
        callback(newValue, oldValue);
      });
    }
  }

  // Sets up a new setting and saves a default value for it immediately if it does not already exist
  public registerSetting(settingName: string, defaultValue: unknown): void {
    if (!(settingName in this.settings)) {
      this.settings[settingName] = defaultValue;
      // We do not use useEarlySave here since on app load there will probably be a bunch of calls to
      // registerSetting, and we want it throttled no matter what.
      this.saveSettings();
    }
  }

  public get(settingName: string): unknown {
    return this.settings[settingName];
  }

  public set(settingName: string, value: unknown): void {
    const oldValue = this.settings[settingName];
    this.settings[settingName] = value;
    this.settingsIsDirty = true;
    // We pass true here because most set calls will come from the developers console, and we want to save them
    // immediately if it is not too unperformant to do so.
    this.saveSettings(true);
    this.notifyListeners(settingName, value, oldValue);
  }

  public getAll(): { [key: string]: unknown } {
    return { ...this.settings };
  }

  // Used to add a listener for changes to a particular setting
  public addListener(settingName: string, callback: SettingChangeListener): () => void {
    if (!this.listeners[settingName])
      this.listeners[settingName] = new Set();

    this.listeners[settingName].add(callback);

    // Return a quick convenience function for unsubscribing, since most of the time callback will be
    // anonymous, making it awkward to run de-subscription yourself
    return () => this.removeListener(settingName, callback);
  }

  public removeListener(settingName: string, callback: SettingChangeListener): void {
    const settingListeners = this.listeners[settingName];
    if (settingListeners) {
      settingListeners.delete(callback);
    }
  }

  // Will clear all current page settings and remove them from the localStorage.
  // Will not restore any defaults, and will trigger all listeners.
  public clear(): void {
    this.settingsIsDirty = true;
    const oldSettings = this.settings;
    this.settings = {};
    // We pass true here because most clear calls will come from the developers console, and we want to save them
    // immediately if it is not too unperformant to do so.
    this.saveSettings(true);
    Object.entries(this.listeners).forEach(([settingName, settingListeners]) => {
      if (settingListeners)
        this.notifyListeners(settingName, undefined, oldSettings[settingName]);
    });
  }
}

export default CachedSettingsManager.getInstance();
