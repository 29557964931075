import * as PIXI from 'pixi.js';
import { registerLogCategory } from '../privateLogger';

const ticker = PIXI.Ticker.shared;

const log = registerLogCategory('fpsCounter');

class SessionIndicator {
  private isCreated: boolean = false;
  private app: PIXI.Application;
  private lastTime: number = performance.now();

  constructor(app: PIXI.Application) {
    log(1)('Constructor');

    this.app = app;
    this.register();
  }

  // Sets up the html for the counter and starts the PIXI tracker
  private setupContainer(): void {
    log(2)('Ran: setupCounterContainer');

    const sessionIndicatorDiv = document.createElement('div');
    sessionIndicatorDiv.id = 'session-indicator';
    if (window.game?.sessionId) sessionIndicatorDiv.textContent = '#' + window.game?.sessionId + '';
    document.body.appendChild(sessionIndicatorDiv);

    Object.assign(sessionIndicatorDiv.style, {
      position: 'fixed',
      top: '10px',
      right: '10px',
      padding: '5px 10px',
      // backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: '#ffffff',
      fontFamily: 'Arial, sans-serif',
      fontSize: '12px',
      zIndex: '1000',
      pointerEvents: 'none',
    });
    log(3)('Counter element created and attached', { el: sessionIndicatorDiv });

    ticker.add(() => {
      const currentTime = performance.now();
      const delta = currentTime - this.lastTime;
      if (delta >= 1000) {
        if (window.game?.sessionId)
          sessionIndicatorDiv.textContent = '#' + window.game?.sessionId + '';
        this.lastTime = currentTime;
      }
    });
    this.isCreated = true;
  }

  // Sets up the CachedSettingsManager setting for 'showFps' and starts watching it for changes
  // Upon receiving a change or onload, toggles/starts the tracker and shows/hides the counter
  private register(): void {
    log(2)('Ran: registerFpsCounter');

    if (!this.isCreated) this.setupContainer();
  }
}

export default SessionIndicator;
