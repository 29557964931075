import CachedSettingsManager from '../../cachedSettingsManager';
import getColorScheme from '../../environmentDetection/detectColorScheme';

import { TLoggerCategoriesStore, TLogLevel, TLogLevelsStore } from './privateLogger.types';
import { adjustRGBHashGammaForColorScheme, convertNameStringToRGBHash } from './privateLogger.utils';

const defaultLogLevel = 0;
const loggerLevelsStorageKey = 'privateLoggerLevels';
const loggerCategoriesStorageKey = 'privateLoggerCategories';

// Two separate storages are used, one for desired logging levels and one for category lookups
CachedSettingsManager.registerSetting(loggerLevelsStorageKey, {});
CachedSettingsManager.registerSetting(loggerCategoriesStorageKey, {});

// Get the current or stored desired logging level for the provided category
const getLogLevel = (category: string) => {
  const logLevels = CachedSettingsManager.get(loggerLevelsStorageKey) as TLogLevelsStore;
  return logLevels[category] ?? defaultLogLevel;
};

// Do we have a stored desired log level that would allow a log to show for the provided category
// at the provided (req) level.
const levelPassesCategory = (category: string, req: number) => {
  const logLevel = getLogLevel(category);

  return logLevel >= req;
};

// Performs the actual logging with the correct formatting
const processAndLogItems = (items: unknown[], categoryName: string, categoryColor: string) => {
  console.log(`%c${categoryName}`, `background-color: ${categoryColor};`, ...items);
};

// Checks to make sure we have an existing category with an assigned color.
// Also checks if that color is still valid (for instance, did we switch our color scheme?), if not, repopulates it.
const checkColor = (category: string, colorHash?: string) => {
  // Get dark/light mode
  const currentColorScheme = getColorScheme();
  const logCategories = CachedSettingsManager.get(loggerCategoriesStorageKey) as TLoggerCategoriesStore;

  // We can safely ignore the rest if the log category is populated and the colorScheme it was created with hasn't
  // changed.
  if ((logCategories[category]?.modeUsed === currentColorScheme))
    return;

  // If there is an existing registration color (baseColor, not adjusted), we can use that instead of regenerating it.
  // Otherwise, prefer a manually provided color if it exists and if not generate a new one from the category name.
  const _colorHash = logCategories[category]?.originalColor ?? colorHash ?? convertNameStringToRGBHash(category);
  // Adjust the chosen colors gamma based on detected colorScheme
  const adjustedColorHash = adjustRGBHashGammaForColorScheme(_colorHash as string, currentColorScheme);

  CachedSettingsManager.set(loggerCategoriesStorageKey, {
    ...logCategories,
    [category]: {
      category,
      bgColor: adjustedColorHash,
      originalColor: _colorHash,
      modeUsed: getColorScheme(),
    },
  });
};

// Called to set up (or retrieve if it exists) a new log category and sort out its color.
// Will also return the actual wrapper function you can use for logging
const registerLogCategory = (name: string, { colorHash }: { colorHash?: string } = {}) => {
  checkColor(name, colorHash);

  return (level: TLogLevel) => (...items: unknown[]) => _log(name, level, items);
};

// The raw log function used by the logger.  This is not exposed directly to developer usage
const _log = (category: string, reqLevel: TLogLevel, contents: unknown[]) => {
  // Make sure our category is set up and up to date
  checkColor(category);

  // Are we allowed to log something out based on the log requirement and the users desired level setting?
  if (!levelPassesCategory(category, reqLevel))
    return;

  const logCategories = CachedSettingsManager.get(loggerCategoriesStorageKey) as TLoggerCategoriesStore;
  const { bgColor } = logCategories[category];
  processAndLogItems(contents, category, bgColor);
};

// The function that actually sets and remembers the users logging preferences
const setLogLevel = (category: string, level: TLogLevel, noFeedback = false) => {
  const logLevels = CachedSettingsManager.get(loggerLevelsStorageKey) as TLogLevelsStore;

  CachedSettingsManager.set(loggerLevelsStorageKey, {
    ...logLevels,
    [category]: level,
  });

  // Any automated or developer set log levels, we may not want alerts to go out.  Use noFeedback for this.
  if (!noFeedback)
    console.log(`Log level for "${category}", set to: ${level}`);
};

const reset = () => {
  CachedSettingsManager.set(loggerLevelsStorageKey, {});
  CachedSettingsManager.set(loggerCategoriesStorageKey, {});
};

// The interface exposed to accamaxDebug.logger
const consoleInterface = {
  get: getLogLevel,
  set: setLogLevel,
  reset,
  getAll: () => ({ ...CachedSettingsManager.get(loggerLevelsStorageKey) as TLogLevelsStore }),
};

export {
  registerLogCategory,
  setLogLevel,
  reset,
  consoleInterface,
};
