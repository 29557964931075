// A fast hashing algorithm to convert strings to a color hash
const djb2HashToHexColor = (str: string) => {
  let hash = 4638;

  for (let i = 0; i < str.length; i++)
    hash = ((hash << 5) + hash) + str.charCodeAt(i); // hash * 33 + char

  const color = (hash & 0xFFFFFF).toString(16).padStart(6, '0');
  return `#${color}`;
};

// A convenient export wrapper name for the hashing algorithm
const convertNameStringToRGBHash = (str: string) => djb2HashToHexColor(str);

// Break a color hash string (#1A2B3C) into an array of integer RBG components
const convertRGBHashToColorParts = (colorString: string) => [
  parseInt(`0x${colorString.substring(0, 2)}`, 16),
  parseInt(`0x${colorString.substring(2, 4)}`, 16),
  parseInt(`0x${colorString.substring(4, 6)}`, 16),
];

// Convert an array of integer RGB components to a color hash string (#1A2B3C)
const convertRGBColorPartsToHash = ([redInt, greenInt, blueInt]: number[]) =>
  `#${((1 << 24) + (redInt << 16) + (greenInt << 8) + blueInt).toString(16).slice(1)}`;

// Adjusts a color hash string to be brighter or darker depending on if you pass in light or dark mode
const adjustRGBHashGammaForColorScheme = (colorString: string, colorMode: 'light' | 'dark') => {
  // Get the RGB components from the color hash string
  let colorParts = convertRGBHashToColorParts(colorString.replace(/^#/, ''));

  switch (colorMode) {
    case 'light':
      // If any color component would cause a darker color, push the whole color 50% towards white
      if (colorParts.some((val) => val < 128))
        colorParts = colorParts.map((val) => 255 - Math.round((255 - val) / 2));
      // If all combined color parts are too bright, darken the color a bit
      if (colorParts.reduce((aggr, val) => val + aggr, 0) > 600)
        colorParts = colorParts.map((val) => val - 64);
      break;
    case 'dark':
      // If any color component would cause a lighter color, push the whole color 50% towards black
      if (colorParts.some((val) => val >= 128))
        colorParts = colorParts.map((val) => Math.round(val / 2));
      // If all combined color parts are too dark, lighten the color a bit
      if (colorParts.reduce((aggr, val) => val + aggr, 0) < 180)
        colorParts = colorParts.map((val) => 128 - val);
      break;
    default:
      throw new Error('Invalid color scheme');
  }

  return convertRGBColorPartsToHash(colorParts);
};

export {
  convertNameStringToRGBHash,
  adjustRGBHashGammaForColorScheme,
};
