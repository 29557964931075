import React, { useState } from 'react';

type Option = {
  text: string;
  width: number;
  height: number;
};

type RadioButtonGroupProps = {
  options: Option[];
  name: string;
  onClick: (width: number, height: number) => void;
};

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ options, name, onClick }) => {
  const [selectedValue, setSelectedValue] = useState<string>('');

  const handleClick = (option: Option) => {
    const selected = `${option.width}x${option.height}`;
    setSelectedValue(selected);
    onClick(option.width, option.height);
  };

  return (
    <div>
      {options.map((option) => (
        <label key={option.text} style={{ display: 'block', margin: '8px 0' }}>
          <input
            type="radio"
            name={name}
            value={`${option.width}x${option.height}`}
            checked={selectedValue === `${option.width}x${option.height}`}
            onClick={() => handleClick(option)}
          />
          {option.text} ({option.width} x {option.height})
        </label>
      ))}
    </div>
  );
};

export default React.memo(RadioButtonGroup);
