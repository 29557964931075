import SoundTrack from '../../../../game/managers/soundManager/soundTrack';
import MainMusicTrack from './mainMusicTrack';
import FreeSpinTrack from './freeSpinTrack';

class BGMusicTrack extends SoundTrack {
  private _mainMusicTrack!: MainMusicTrack;
  private _freeSpinTrack!: FreeSpinTrack;
  private _currentSong: 'main' | 'freeSpin' | 'winMode' = 'main';

  mount() {
    this._mainMusicTrack = new MainMusicTrack('mainMusicTrack');
    this._mainMusicTrack.mount();
    this.registerTrack('mainMusicTrack', this._mainMusicTrack);
    this._freeSpinTrack = new FreeSpinTrack('freeSpinTrack');
    this._freeSpinTrack.mount();
    this.registerTrack('freeSpinTrack', this._freeSpinTrack);
    this.registerMusic('musBaseGameWinMode', { loop: true });

    this._isMounted = true;
  }

  startGame() {
    this._mainMusicTrack.playFromIntro();
    this._currentSong = 'main';
  }

  playMainMusic(transitionFromFreeSpin = false) {
    if (this._currentSong === 'main' || (this._currentSong === 'freeSpin' && !transitionFromFreeSpin))
      return;

    this._mainMusicTrack.play();
    this._currentSong = 'main';

    this.crossFade(
      [this.getSound('musBaseGameWinMode')!],
      this.getSound('mainMusicTrack')!,
      { stopAndRestoreVolume: true, duration: 1500 },
    );
  }

  playWinModeMusic() {
    if (this._currentSong !== 'main')
      return;

    this._currentSong = 'winMode';
    const winModeSong = this.getSound('musBaseGameWinMode')!;
    winModeSong.play();

    this.crossFade(
      [this.getSound('mainMusicTrack')!],
      winModeSong,
      { stopAndRestoreVolume: true },
    );
  }

  playFreeSpinMusic() {
    if (this._currentSong === 'freeSpin')
      return;

    this._currentSong = 'freeSpin';
    this._freeSpinTrack.play();

    this.crossFade(
      [this.getSound('musBaseGameWinMode')!, this.getSound('mainMusicTrack')!],
      this.getSound('freeSpinTrack')!,
      { stopAndRestoreVolume: true },
    );
  }

  stopFreeSpinMusic() {
    this._freeSpinTrack.stop();
    this._freeSpinTrack.onComplete(() => {
      this.playMainMusic(true);
    });
  }
}

export default BGMusicTrack;
