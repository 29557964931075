import { Ticker, Text, BitmapText } from 'pixi.js';

import { registerLogCategory } from '../../debug/privateLogger';

import { Game } from '../game';
import { IFreeSpinStatus } from '../types';

import { ReelsManager } from './managers/reelsManager';
import { ServiceManager } from './ServiceManager';
import { IGameOutcome } from './service/types';
import AssetLoader from '../assetLoader';
import { dedeAssets } from './resources/assets';
import { FreeSpinManager } from './FreeSpinManager';
import { BigWinManager } from './BigWinManager';
import { AnteBetManager } from './AnteBetManager';
import { AfterLoadManager } from './AfterLoadManager';
import { BackgroundManager } from './BackgroundManager';
import { InfoManager } from './InfoManager';
import DedeBalanceManager from './managers/balanceManager';
import { MainCharacter } from './models/mainCharacter';
import { IEventDetails } from '../gameEvent';
import GameController from './models/gameController';
import LayoutManager from './managers/layoutManager';
import WinHistoryManager from './managers/winHistoryManager';
import { JackpotManager } from './JackpotManager';
import SoundManager from './managers/soundManager';
import { LogoManager } from './LogoManager';
import CachedSettingsManager from '../../cachedSettingsManager';
import getMultiplierParticleStreamGenerator from '../../game/managers/animationManager/particleAnimation/particles/multiplierStream';
import { ANTEBET_MULTIPLIER } from './resources/constants';
import { SlideButton } from './models/slideButton';

const log = registerLogCategory('Dede-load');

export class Dede extends Game {
  public soundManager: SoundManager;
  public logoManager: LogoManager;
  public reelsManager: ReelsManager;
  public jackpotManager: JackpotManager;
  public mainCharacter: MainCharacter;
  private afterLoadManager: AfterLoadManager;
  public serviceManager: ServiceManager;
  public freeSpinManager: FreeSpinManager;
  public anteBetManager: AnteBetManager;
  public bigWinManager: BigWinManager;
  public infoManager: InfoManager;
  public controller: GameController;
  public backgroundManager: BackgroundManager;
  protected _balanceManager: DedeBalanceManager;
  protected _layoutManager: LayoutManager;
  winHistoryManager: WinHistoryManager;
  private multiplierParticleStreamGenerator!: ReturnType<
    typeof getMultiplierParticleStreamGenerator
  >;

  assetsLoaded = false;

  lazyAssetsLoaded = false;
  outcomes: IGameOutcome[] = [];
  currentOutcome?: IGameOutcome;
  prevOutcome?: IGameOutcome;

  constructor() {
    super();

    this.serviceManager = new ServiceManager(this);
    this.soundManager = new SoundManager('soundManager');
    this.logoManager = new LogoManager(this);
    this.freeSpinManager = new FreeSpinManager(this);
    this.anteBetManager = new AnteBetManager(this);
    this.bigWinManager = new BigWinManager(this);
    this.afterLoadManager = new AfterLoadManager(this);
    this.assetLoader = new AssetLoader(dedeAssets);
    this._layoutManager = new LayoutManager(this);
    this.backgroundManager = new BackgroundManager(this);
    this.infoManager = new InfoManager(this);
    this.controller = new GameController(this);
    this.mainCharacter = new MainCharacter(this);
    this.reelsManager = new ReelsManager(this, {
      onDestroy: () => {},
      onFallComplete: async (multiplier, outcome) => {
        const tumbleWinAmount = outcome?.tumbleWinAmount || 0;
        const totalWinAmount = outcome?.totalWinAmount || 0;

        this.reelsManager.printWinAmount(outcome);

        if (outcome?.isLastOutcome) {
          this.winAmount = totalWinAmount;
          if (outcome.isFreeSpin ? outcome.isLastFreeSpinOutcome : true) {
            this.isRunning = false;
            this._balanceManager.addToBalance(this.winAmount);
            if (outcome.isFreeSpin) this.betButtonsDisabler.enable('buyBonus');
          }
          if (outcome.isFreeSpin) this.freeSpinWinAmount = totalWinAmount;
        } else {
          if (this.initialSpinDone) this.isRunning = true;
          else {
            this.initialSpinDone = true;
            this.isRunning = false;
          }
        }
        const historyItem = {
          outcome,
          tumbleWinAmount,
          totalWinAmount,
          multiplier,
          spinCompleted: !!outcome?.isLastOutcome,
        };
        this.history = [...this.history, historyItem];

        if (outcome?.winnings.length) {
          this.winHistoryManager.addToWinHistory(historyItem);
          this.freeSpinManager.updateWinStreakTimer();
        }

        if (outcome ? outcome.isLastOutcome : true) {
          await this.onSpinComplete.triggerEvent({
            tumbleWinAmount,
            totalWinAmount,
            winningSymbols: outcome?.winningSymbols ?? [],
            outcome: outcome!,
          });
        }

        this.onFallComplete.triggerEvent({ winAmount: totalWinAmount, multiplier, outcome });

        if (!this.isRunning && this.autoPlayCount) {
          if (this.paused) {
            const handleGameUnPaused = () => {
              this.onGameUnPaused.removeEventListener(handleGameUnPaused);
              this.runReels();
            };
            this.onGameUnPaused.addEventListener(handleGameUnPaused);
          } else {
            this.runReels();
          }
        }
      },
    });
    this.jackpotManager = new JackpotManager(this);

    this._balanceManager = new DedeBalanceManager({
      gameDimensionsInterface: this,
    });
    this.winHistoryManager = new WinHistoryManager(this, this.soundManager);
    this.onSpin.addEventListener(() => {
      this.winHistoryManager.clearWinHistory();
    });

    window.addEventListener('resize', () => this.handleWindowResize(this.getOrientation()));
    window.addEventListener('orientationchange', () => {
      let initialHeight = window.innerHeight;
      let initialWidth = window.innerWidth;
      const checkDimensions = () => {
        if (window.innerWidth !== initialWidth || window.innerHeight !== initialHeight) {
          this.handleWindowResize(this.getOrientation());
        } else {
          requestAnimationFrame(checkDimensions);
        }
      };
      requestAnimationFrame(checkDimensions);
    });
    this.isRunning = true;
    window.game = this;
  }

  get reelsContainerWidth() {
    return this.reelsManager.backgroundSprite.getBounds().width;
  }

  renderCoordinates() {
    for (let i = 0; i < this.width + this.xOffset * 2; i += 100) {
      const text = new Text();
      text.text = i;
      text.style = { fill: 0xffffff, fontSize: 30 };
      text.x = i;
      text.y = 0;
      this.app.stage.addChild(text);
    }
    for (let i = 0; i < this.height; i += 100) {
      const text = new Text();
      text.text = i;
      text.style = { fill: 0xffffff, fontSize: 30 };
      text.x = 0;
      text.y = i;
      this.app.stage.addChild(text);
    }
  }

  load = async (onProgress: (progress: number) => void) => {
    this.assetLoader.load(
      (progress: number) => {
        this.onAssetsLoading.triggerEvent({
          initialAssetsProgress: progress,
          lazyAssetsProgress: 0,
        });
        if (progress === 1) {
          if (!this.assetsLoaded) {
            this.onAssetsLoaded.triggerEvent();
            this.assetsLoaded = true;
          }
        }
        onProgress(progress);
      },
      (progress: number) => {
        this.onAssetsLoading.triggerEvent({
          initialAssetsProgress: 1,
          lazyAssetsProgress: progress,
        });
        if (progress === 1) {
          this.lazyAssetsLoaded = true;

          this.soundManager.mount();
          this.soundManager.volume = CachedSettingsManager.get('isSoundEnabled') ? 1 : 0;

          this.onSymbolSelection.addEventListener(() => {
            this.soundManager.soundEffectsTrack.playSymHighlight();
          });

          this.onExplode.addEventListener(() => {
            this.soundManager.soundEffectsTrack.playSymExplode();
          });

          this.onClick.addEventListener(() => {
            this.soundManager.soundEffectsTrack.playClick();
          });
        }
      },
      (progress) => {}
    );
  };

  async mount() {
    await this.backgroundManager.init();
    await this.serviceManager.initGame();

    await this.app.init({
      background: '#232222FF',
    });
    const gameContainer = document.getElementById('gameContainer');
    if (!gameContainer) return false;
    gameContainer.appendChild(this.app.canvas);
    window.__PIXI_APP__ = this.app;
    this.handleWindowResize(this.getOrientation());

    this.afterLoadManager.mountAfterLoadScreen(async () => {
      await this.mainCharacter.mount();
      this.reelsManager.mount();
      this.logoManager.mount();
      this._layoutManager.setupPixiContainers();
      this._balanceManager.mountPixiBalanceContainer(this.app.stage);
      this.winHistoryManager.mount(
        this._layoutManager.winHistoryContainer,
        this._layoutManager.totalWinContainer
      );
      this.infoManager.mount(this.app.stage);
      this.jackpotManager.mount(this.stake, this.onStakeChange); // Must be mounted after reelsManager
      this.controller.mount();
      this.app.ticker.add((ticker) => this.update(ticker));

      this.multiplierParticleStreamGenerator = getMultiplierParticleStreamGenerator(this);

      this.onMountDone();
    });
  }

  update(ticker: Ticker) {
    this.reelsManager.update(ticker);
  }

  async runReels(buyFreeSpins: boolean = false) {
    if (!this.initialSpinDone) return;
    this.onSpin.triggerEvent();
    this.winAmount = 0;
    this.isRunning = true;
    if (this.autoPlayCount > 0) {
      this.autoPlayCount--;
    }
    this.history = [];
    const spendAmount = buyFreeSpins
      ? this.stake * this.config.freeSpinBuyMultiplier
      : this.anteBetActive
      ? this.stake * ANTEBET_MULTIPLIER
      : this.stake;
    if ((this._balanceManager.balance ?? 0) < spendAmount) {
      if (this.autoPlayCount !== 0) {
        this.autoPlayCount = 0;
      }
      return;
    }
    this._balanceManager.subtractFromBalance(spendAmount);
    this.soundManager.soundEffectsTrack.playTumble();

    this.reelsManager.runReels(buyFreeSpins);
  }

  async simulate(response: string) {
    this.serviceManager.loadSimulateResponse(response);
    await this.runReels();
  }

  async runFreeSpinReels(outcomes: IGameOutcome[]) {
    this.onSpin.triggerEvent();
    this.isRunning = true;
    this.history = [];
    this.reelsManager.runReelsWithOutcomes(outcomes);

    await new Promise((resolve) => setTimeout(resolve, 0));
    const freeSpinEndListener = (event: IEventDetails, freeSpinStatus: IFreeSpinStatus) => {
      if (!freeSpinStatus.activated) {
        this.onFreeSpinChange.removeEventListener(freeSpinEndListener);
      }
    };
    this.onFreeSpinChange.addEventListener(freeSpinEndListener);
  }
}
