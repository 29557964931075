import { Container, Sprite, Text, Texture } from 'pixi.js';
import { IPosition } from '../../../types';

class TextButton {
  pressed = false;
  background: Sprite;
  maskSprite?: Sprite;
  content!: Sprite;
  container: Sprite;
  private _texts: Text[] = [];
  private _disabled = false;
  private _scaleX = 1;
  private _scaleY = 1;
  constructor(
    parent: Container,
    private buttonOptions: {
      background: {
        texture: string;
        disabledTexture?: string;
        position: IPosition;
      };
      content?: {
        texture: string;
        position: IPosition;
        hideWhenDisabled?: boolean;
        scale?: number;
      };
      texts?: {
        text: string;
        fontSize?: number;
        fontFamily?: string;
        color?: number;
        alignment?: 'left' | 'center';
        position: IPosition;
      }[];
      onClick: () => void;
      disabledOpacity?: number;
    }
  ) {
    this.container = new Sprite();
    this.background = new Sprite(Texture.from(buttonOptions.background.texture));

    this.container.x = buttonOptions.background.position.x;
    this.container.y = buttonOptions.background.position.y;
    this.background.anchor.set(0.5);
    if (buttonOptions.background.position.angle)
      this.background.angle = buttonOptions.background.position.angle;
    this.container.addChild(this.background);
    const width = buttonOptions.background.position.width || 0;
    const height = buttonOptions.background.position.height || 0;
    this._scaleX = (width || this.background.width) / this.background.width;
    this._scaleY = (height || this.background.height) / this.background.height;
    this.container.scale.set(this._scaleX, this._scaleY);
    if (buttonOptions.content) {
      this.content = new Sprite(Texture.from(buttonOptions.content.texture));
      if (buttonOptions.content.position.width)
        this.content.width = buttonOptions.content.position.width;
      if (buttonOptions.content.position.height)
        this.content.height = buttonOptions.content.position.height;
      if (buttonOptions.content.scale) this.content.scale.set(buttonOptions.content.scale);
      this.content.x = buttonOptions.content.position.x;
      this.content.y = buttonOptions.content.position.y;
      this.content.anchor.set(0.5);
      if (buttonOptions.content.position.angle)
        this.content.angle = buttonOptions.content.position.angle;
      this.container.addChild(this.content);
    }

    if (buttonOptions.background.disabledTexture) {
      this.maskSprite = new Sprite(Texture.from(buttonOptions.background.disabledTexture));
      this.maskSprite.width = buttonOptions.background.position.width || 0;
      this.maskSprite.height = buttonOptions.background.position.height || 0;
      this.maskSprite.anchor.set(0.5);
      if (buttonOptions.background.position.angle)
        this.maskSprite.angle = buttonOptions.background.position.angle;
      this.maskSprite.alpha = 0.7;
      this.maskSprite.visible = false;
      this.container.addChild(this.maskSprite);
    }

    if (buttonOptions.texts?.length)
      buttonOptions.texts.forEach((textOptions, i) => {
        const text = new Text();
        this._texts[i] = text;
        text.style = {
          fontFamily: textOptions.fontFamily || 'Arial',
          fontSize: textOptions.fontSize || 24,
          fill: textOptions.color || 0xffffff,
          align: textOptions.alignment || 'center',
        };
        text.text = textOptions.text;
        if (textOptions.alignment !== 'left') text.anchor.set(0.5);
        else text.anchor.set(0, 0.5);
        text.x = textOptions.position.x;
        text.y = textOptions.position.y;
        this.container.addChild(text);
      });

    this.container.interactive = true;
    this.container.cursor = 'pointer'; // Set cursor to pointer on hover
    this.container.anchor.set(0.5);
    parent.addChild(this.container);

    this.container.on('pointerdown', () => {
      this.container.scale.set(this.container.scale.x * 0.9, this.container.scale.y * 0.9);
      this.container.tint = 0xaaaaaa;
      this.pressed = true;
    });
    this.container.on('pointerup', () => {
      if (!this.pressed) return;
      this.container.scale.set(this.container.scale.x / 0.9, this.container.scale.y / 0.9);
      this.container.tint = 0xffffff;
      buttonOptions.onClick();
      this.pressed = false;
    });
    this.container.on('pointerupoutside', () => {
      if (!this.pressed) return;
      this.container.scale.set(this.container.scale.x / 0.9, this.container.scale.y / 0.9);
      this.container.tint = 0xffffff;
      this.pressed = false;
    });
  }

  setText(text: string, index: number, fontSize?: number) {
    if (this._texts[index]) {
      this._texts[index].text = text;
      if (fontSize) this._texts[index].style.fontSize = fontSize;
    }
  }

  get texts() {
    return this._texts.map((text) => text.text);
  }

  set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.container.interactive = !disabled;
    this.container.cursor = disabled ? 'not-allowed' : 'pointer';
    if (this.buttonOptions.background.disabledTexture && this.maskSprite) {
      if (disabled) this.maskSprite.visible = true;
      else this.maskSprite.visible = false;
    }

    if (this.buttonOptions.content?.hideWhenDisabled && this.content) {
      this.content.visible = !disabled;
    }

    if (disabled) {
      this.container.alpha = this.buttonOptions.disabledOpacity || 1;
    } else {
      this.container.alpha = 1;
    }
  }

  get disabled() {
    return this._disabled;
  }

  set visible(visible: boolean) {
    this.container.visible = visible;
  }

  get visible() {
    return this.container.visible;
  }

  destroy() {
    this.container.destroy();
  }

  doClickEffect() {
    this.container.scale.set(this.container.scale.x * 0.9, this.container.scale.y * 0.9);
    this.container.tint = 0xaaaaaa;
    setTimeout(() => {
      this.container.scale.set(this.container.scale.x / 0.9, this.container.scale.y / 0.9);
      this.container.tint = 0xffffff;
    }, 100);
  }
}

export default TextButton;
