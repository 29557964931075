import SoundTrack from '../../../../game/managers/soundManager/soundTrack';
import SoundBase from '../../../../game/managers/soundManager/soundBase';
import BgMusicTrack from './bgMusicTrack';
import SoundEffectsTrack from './soundEffectsTrack';

class BigWinTrack extends SoundTrack {
  private _bigWinMusicTypePlaying: SoundBase | undefined = undefined;
  private _bigWinMusicType!: 'big' | 'mega' | 'olympus';
  private _bgMusicTrack!: BgMusicTrack;
  private _soundEffectsTrack!: SoundEffectsTrack;

  mount(bgMusicTrack: BgMusicTrack, soundEffectsTrack: SoundEffectsTrack) {
    this.registerMusic('musBigWin');
    this.registerMusic('musBigWinEnd');
    this.registerMusic('musMegaWin');
    this.registerMusic('musMegaWinEnd');
    this.registerMusic('musOlympusWin');
    this.registerMusic('musOlympusWinEnd');

    this._bgMusicTrack = bgMusicTrack;
    this._soundEffectsTrack = soundEffectsTrack;

    this._isMounted = true;
  }

  private _getBigWinMusic(type: 'big' | 'mega' | 'olympus') {
    return this.getSound(`mus${type.substring(0, 1).toUpperCase() + type.substring(1)}Win`) as SoundBase;
  }

  private _getBigWinMusicEnd(type: 'big' | 'mega' | 'olympus') {
    return this.getSound(`mus${type.substring(0, 1).toUpperCase() + type.substring(1)}WinEnd`) as SoundBase;
  }

  playBigWin(type: 'big' | 'mega' | 'olympus') {
    if (this._bigWinMusicTypePlaying)
      this._bigWinMusicTypePlaying.stop();

    const bigWinMusic = this._getBigWinMusic(type);

    this._bigWinMusicTypePlaying = bigWinMusic;
    this._bigWinMusicType = type;

    this._bgMusicTrack.duckForSound(bigWinMusic);
    this._soundEffectsTrack.duckForSound(bigWinMusic);

    bigWinMusic.play();
  }

  transitionToBigWinEnd() {
    if (!this._bigWinMusicTypePlaying || !this._bigWinMusicType)
      return;

    const type = this._bigWinMusicType;
    const bigWinMusic = this._getBigWinMusic(type);
    const bigWinMusicEnd = this._getBigWinMusicEnd(type);

    this._bgMusicTrack.duckForSound(bigWinMusicEnd);
    this._soundEffectsTrack.duckForSound(bigWinMusicEnd);

    this.crossFade([bigWinMusic], bigWinMusicEnd, { stopAndRestoreVolume: true, duration: 1500 });
    bigWinMusicEnd.play();
  }

  getBigWinEndDuration(winType: 'big' | 'mega' | 'olympus') {
    return this._getBigWinMusicEnd(winType).duration;
  }
}

export default BigWinTrack;
