import { Sprite, Texture } from 'pixi.js';
import GameController from '.';
import Button from '../button';
import {
  PORTRAIT_INFO_BUTTON_CONTENT_X,
  PORTRAIT_INFO_BUTTON_CONTENT_Y,
  PORTRAIT_INFO_BUTTON_X,
  PORTRAIT_INFO_BUTTON_Y,
  SMALL_BUTTON_WIDTH,
  SMALL_BUTTON_HEIGHT,
  PORTRAIT_SOUND_BUTTON_X,
  PORTRAIT_SOUND_BUTTON_Y,
  PORTRAIT_SETTINGS_BUTTON_X,
  PORTRAIT_SETTINGS_BUTTON_Y,
} from './constants';

import CachedSettingsManager from '../../../../cachedSettingsManager';

export const mountPortraitButtons = (controller: GameController) => {
  controller.settingsButton = new Button(controller.container, {
    backgroundTexture: 'config',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.onSettingsButtonClick.triggerEvent();
    },
    backgroundPosition: {
      x: PORTRAIT_SETTINGS_BUTTON_X,
      y: PORTRAIT_SETTINGS_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: 0,
      y: 0,
    },
  });

  controller.soundButton = new Button(controller.container, {
    backgroundTexture: CachedSettingsManager.get('isSoundEnabled') ? 'sound' : 'soundDisabled',
    onClick: () => {
      controller.game.onClick.triggerEvent();

      CachedSettingsManager.set('isSoundEnabled', !CachedSettingsManager.get('isSoundEnabled'));
      controller.soundButton.background.texture = Texture.from(
        CachedSettingsManager.get('isSoundEnabled') ? 'sound' : 'soundDisabled'
      );
    },
    backgroundPosition: {
      x: PORTRAIT_SOUND_BUTTON_X,
      y: PORTRAIT_SOUND_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: 0,
      y: 0,
    },
  });

  controller.infoButton = new Button(controller.container, {
    backgroundTexture: 'info',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.onInfoButtonClick.triggerEvent();
    },
    backgroundPosition: {
      x: PORTRAIT_INFO_BUTTON_X,
      y: PORTRAIT_INFO_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: PORTRAIT_INFO_BUTTON_CONTENT_X,
      y: PORTRAIT_INFO_BUTTON_CONTENT_Y,
    },
  });
};
