import { BlurFilter, Container, Graphics, Point, Sprite, Text, Texture } from 'pixi.js';
import { Dede } from '.';
import Popup from './models/popup';
import { Spine } from '@pixi/spine-pixi';
import { ColorGradientFilter, DropShadowFilter, GlowFilter, OutlineFilter } from 'pixi-filters';
import { applyGoldenMoneyTextStyle } from './managers/textStyles';
import { getLocale } from '../../../localization';

export class AfterLoadManager {
  container?: Container;
  text!: Text;
  ribbonText!: Text;
  onOkClick!: () => void;

  constructor(private game: Dede) {}

  private mountFeature(
    assetName: string,
    index: number,
    scale: number,
    options?: {
      offsets?: { portrait?: { x: number; y: number }; landscape?: { x: number; y: number } };
      text?: {
        content: string;
      };
      headerSprite?: {
        sprite: string;
        xOffset?: number;
        yOffset?: number;
        contentXOffset?: number;
        contentYOffset?: number;
        contentScale?: number;
      };
      addBackgroundFrame?: boolean;
    }
  ) {
    let frameSprite: Sprite;
    if (options?.addBackgroundFrame) {
      frameSprite = new Sprite(Texture.from('featuresFrame'));
      frameSprite.anchor.set(0.5);
      frameSprite.scale.set((1 / scale) * 0.45);
      this.container!.addChild(frameSprite);
    }

    const spine = Spine.from({ skeleton: assetName + 'Data', atlas: assetName + 'Atlas' });
    spine.state.setAnimation(
      0,
      assetName === 'preview' ? 'animation' : assetName === 's10' ? 'feature_screen' : 'win_idle',
      true
    );
    spine.scale.set(scale);

    if (options?.headerSprite) {
      const ribbonSprite = new Sprite(Texture.from('featuresTopRibbon'));
      ribbonSprite.anchor.set(0.5);
      ribbonSprite.scale.set((1 / scale) * 0.43);
      ribbonSprite.y = ribbonSprite.y + (options.headerSprite.yOffset || 0);
      ribbonSprite.x = ribbonSprite.x + (options.headerSprite.xOffset || 0);
      spine!.addChild(ribbonSprite);

      const headerSprite = new Sprite(Texture.from(options.headerSprite.sprite));
      headerSprite.anchor.set(0.5);
      headerSprite.scale.set((1 / scale) * (options.headerSprite.contentScale || 0.42));
      headerSprite.y = ribbonSprite.y + (options.headerSprite.contentYOffset || 0);
      headerSprite.x = ribbonSprite.x + (options.headerSprite.contentXOffset || 0);
      spine!.addChild(headerSprite);
    }
    this.container!.addChild(spine);

    this.container!.scale.set(1.2);

    this.game.onResize.addEventListener(() => {
      const orientation = this.game.getOrientation();
      if (orientation === 'portrait') {
        spine.x = this.game.width / 2 + (options?.offsets?.portrait?.x || 0);
        spine.y = (this.game.height / 5) * (index + 1) + (options?.offsets?.portrait?.y || 0);
      } else {
        spine.x = (this.game.width / 4) * (index + 1) + (options?.offsets?.landscape?.x || 0);
        spine.y = this.game.height / 2 + (options?.offsets?.landscape?.y || 0);
      }
      if (frameSprite) {
        frameSprite.x = spine.x;
        frameSprite.y = spine.y - 15;
      }
    });

    if (options?.text) {
      const text = new Text();
      text.text = options.text.content;
      text.style = {
        fill: 0xffffff,
        fontSize: 500,
        fontFamily: 'kanitRegular',
      };
      text.anchor.set(0.5);
      spine.addChild(text);
      applyGoldenMoneyTextStyle(text, { useHeavyGlow: false });
    }
  }

  mountAfterLoadScreen(onOkClick?: () => void) {
    console.log('mounting after load screen', onOkClick);
    this.onOkClick = onOkClick!;
    // this.popup!.mount();
    this.container = new Container();
    this.container.width = this.game.width;
    this.container.height = this.game.height;
    this.container.interactive = true;

    this.game.app.stage.addChild(this.container);

    const blurFilter = new BlurFilter();
    blurFilter.blur = 10;
    this.game.backgroundManager.spine.filters = [blurFilter];

    const bottomRibbonSprite = new Sprite(Texture.from('featuresBottomRibbon'));
    bottomRibbonSprite.anchor.set(0.5);
    bottomRibbonSprite.scale.set(0.5);
    bottomRibbonSprite.y = this.game.height - 100;
    bottomRibbonSprite.x = this.game.width / 2;

    this.ribbonText = new Text();
    this.ribbonText.text = getLocale('slots.ui.dede.winUpToXX', '5000x');
    this.container.addChild(this.ribbonText);
    this.ribbonText.anchor.set(0.5);
    this.ribbonText.zIndex = 2;

    this.ribbonText.style = {
      fontSize: 50,
      letterSpacing: 7,
      fontFamily: 'kanitRegular',
    };

    applyGoldenMoneyTextStyle(this.ribbonText, { useHeavyGlow: false });
    this.container.addChild(bottomRibbonSprite);

    this.mountFeature('s10', 0, 0.25, {
      offsets: {
        landscape: { x: -20, y: -25 },
        portrait: { x: -35, y: -125 },
      },
      headerSprite: {
        sprite: 'featuresFreeSpin',
        contentYOffset: -90,
        yOffset: -400,
        xOffset: 140,
      },
    });
    this.mountFeature('multiplier', 1, 0.27, {
      offsets: { landscape: { x: 0, y: -100 } },
      text: {
        content: 'x500',
      },
      headerSprite: {
        sprite: 'featuresMultipliers',
        contentYOffset: -80,
        yOffset: -450,
        contentScale: 0.35,
      },
    });
    this.mountFeature('preview', 2, 1, {
      headerSprite: { sprite: 'featuresTumble', contentYOffset: -24, yOffset: -140 },
      addBackgroundFrame: true,
      offsets: {
        portrait: { x: 0, y: 100 },
      },
    });

    this.text = new Text();
    this.text.text = getLocale('slots.ui.dede.pressContinue');
    this.container.addChild(this.text);
    this.text.anchor.set(0.5);

    this.text.style = {
      fontSize: 60,
      letterSpacing: 7,
      fontFamily: 'kanitRegular',
    };

    applyGoldenMoneyTextStyle(this.text, { useHeavyGlow: false });

    const handleResize = () => {
      const orientation = this.game.getOrientation();
      if (this.text.width > this.game.width) {
        this.text.style.fontSize = 40 * (this.game.width / this.text.width);
        this.ribbonText.style.fontSize = 40 * (this.game.width / this.text.width);
      }

      if (orientation === 'portrait') {
        this.text.y = this.game.height - 100;
        this.text.x = this.game.width / 2;
        this.ribbonText.y = this.game.height - 300;
        this.ribbonText.x = this.game.width / 2;
        bottomRibbonSprite.y = this.text.y - 200;
        bottomRibbonSprite.x = this.game.width / 2;
        this.container!.scale.set(1);
        this.container!.x = 0;
      } else {
        this.text.y = this.game.height / 2 + 280;
        this.text.x = this.game.width / 2;
        this.ribbonText.y = this.game.height / 2 + 150;
        this.ribbonText.x = this.game.width / 2;
        bottomRibbonSprite.y = this.text.y - 130;
        bottomRibbonSprite.x = this.game.width / 2;
        this.container!.scale.set(1);
        this.container!.x = 0;
      }
    };

    this.game.onResize.addEventListener(handleResize);

    const unmountAfterLoadScreen = () => {
      console.log('unmounting after load screen', this);
      this.container?.destroy();
      this.game.backgroundManager.spine.filters = [];
      this.onOkClick();
      window.removeEventListener('click', unmountAfterLoadScreen);
      this.game.onResize.removeEventListener(handleResize);
    };
    window.addEventListener('click', unmountAfterLoadScreen);
  }
}
