import ParticleEmitter, { TEmitterProgessParamType } from '../../particleAnimation/particleEmitter';
import { Particle, Point, Texture } from 'pixi.js';
import ParticleRenderer from '../../particleAnimation/particleRenderer';
import ParticleEmitterTimeline from '../../particleAnimation/particleEmitterTimeline';
import { getRandomPointInCircle } from '../../../../math/generationFunctions';
import { vec2 } from 'gl-matrix';
import { applyWaveMotion } from '../animationForces';
import { lerp } from '../../../../math/interpolationFunctions';
import { Dede } from '../../../../../games/dede';

const ActiveMultiplierStreams = new Set<() => Promise<void>>();

const getMultiplierParticleStreamGenerator = (game: Dede) => {
  return async (x: number, y: number) => {
    const speed = 1.6 * (game.turboSpinActive ? 2.5 : 1);

    const averageParticleSpacing = 1 / speed;
    const maxParticleCount = 400;
    const emitterGenerationDuration = maxParticleCount * averageParticleSpacing;

    function* particleCreationPredictor(progress: TEmitterProgessParamType) {
      let emitterTime = progress.emitterTime;
      let emitterProgress = progress.emitterProgress;
      let multiplier = 1;
      let emissionTime = emitterTime;

      while (emissionTime < emitterGenerationDuration) {
      // The following code is using smaller over large number division to drastically increase the time between
      // particles being emitted when the emitter is close to the start or end of the emmitters lifespan.  This
      // effectively reduces the number of particles in a linear fashion the closer you get to the start or end.
        if (emitterProgress < 0.5)
          multiplier = Math.min(0.5 / emitterProgress, 40 / speed);
        else if (emitterProgress > 0.5)
          multiplier = Math.min(0.5 / (1 - emitterProgress), 40 / speed);

        emissionTime += Math.random() * multiplier;

        const newValues: TEmitterProgessParamType
        = yield (emissionTime + progress.elapsedTimeAtStart - progress.emitterProgress);
        emitterProgress = newValues.emitterProgress;
        emitterTime = newValues.emitterTime;
      }
    }

    const _emitParticle = (
      progress: TEmitterProgessParamType,
      removeParticle: () => void,
      index: number,
      { phaseStartBase, x, y }: { phaseStartBase: number; x: number; y: number },
    ) => {
      const baseScale = Math.random() + 0.25;

      const lotusPositionalBone = game.mainCharacter.spineAnimator!.spine!.skeleton.slots.find(
        (slot) => slot.data.name === 'petal_2',
      )!.bone;
      const lotusPositionPoint = game.mainCharacter.spineAnimator!.spine!.toGlobal(
        new Point(lotusPositionalBone.worldX, lotusPositionalBone.worldY),
      );

      const particle = new Particle({
        texture: Texture.from('glowingParticle1'),
        scaleX: baseScale,
        scaleY: baseScale,
      });
      const { x: cx, y: cy } = getRandomPointInCircle(20, false);
      particle.x = lotusPositionPoint.x + cx;
      particle.y = lotusPositionPoint.y + cy;
      const start = vec2.fromValues(particle.x, particle.y);

      const { x: x2, y: y2 } = getRandomPointInCircle(20, false);
      const end = vec2.fromValues(x + x2, y + y2);
      const particleMaxAge = Math.sqrt(Math.pow(x - lotusPositionPoint.x, 2) + Math.pow(y - lotusPositionPoint.y, 2)) / speed;

      const particleBurstDuration = Math.random() * 50;
      const particleFadeDuration = particleBurstDuration / 3;
      const growSize = baseScale + Math.random() * 2;

      return {
        particle,
        update: (deltaTime: number, particleAge: number, timelineElapsedTime: number) => {
          const progress = particleAge / particleMaxAge;

          if (particleAge < particleMaxAge) {
            const posVec = applyWaveMotion(
              start,
              end,
              progress,
              Math.random() * 20 + 30,
              1,
              Math.random() * 0.2 - 0.1 + phaseStartBase,
              true,
            );
            particle.x = posVec[0];
            particle.y = posVec[1];
          }
          else if (particleAge < particleMaxAge + particleBurstDuration) {
            const scale = lerp(baseScale, growSize, (particleAge - particleMaxAge) / particleBurstDuration);
            particle.scaleX = scale;
            particle.scaleY = scale;
          }
          else if (particleAge < particleMaxAge + particleBurstDuration + particleFadeDuration)
            particle.alpha = 1 - (particleAge - particleMaxAge - particleBurstDuration) / particleFadeDuration;
          else
            removeParticle();
        },
      };
    };

    const particleRenderer = new ParticleRenderer(
      game.app.stage,
      (particleContainer) => {
        particleContainer.zIndex = 100000;
        particleContainer.blendMode = 'add';
      },
      ['x', 'y', 'scale', 'alpha'],
    );

    // doing the wierd limit because phaseStart is being clamped at the moment not rolled over
    const phaseStartBase = Math.random() * 0.8 + 0.1;

    const emitParticle = (
      progress: TEmitterProgessParamType,
      removeParticle: () => void,
      index: number,
    ) => {
      return _emitParticle(
        progress,
        removeParticle,
        index,
        {
          phaseStartBase,
          x,
          y,
        },
      );
    };

    const particleEmitter = new ParticleEmitter(
      particleCreationPredictor,
      emitParticle,
      particleRenderer,
    );

    const particleEmitterTimeline = new ParticleEmitterTimeline(
      { main: particleEmitter },
      [{ startTime: 0, endTime: emitterGenerationDuration, particleEmitterName: 'main' }],
    );

    await particleEmitterTimeline.isBusyPromise;
    particleRenderer.destroy();
  };
};

export default getMultiplierParticleStreamGenerator;
