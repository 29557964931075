import { BitmapText, Container, Sprite, Text, Texture } from 'pixi.js';
import WinHistoryManagerCore from '../../../../game/managers/winHistoryManager';
import { IEventDetails } from '../../../gameEvent';
import {
  TWinHistoryEvent,
  TWinHistoryItem,
} from '../../../../game/managers/winHistoryManager/winHistory.types';
import { formatAsCurrency } from '../../../../game/managers/currencyManager';
import accamaxDebug, { getDebugName } from '../../../../debug';
import { simpleAnimatePropertiesTo } from '../../../../game/managers/animationManager';
import {
  performFadeInAnimation,
  performFadeOutAnimation,
  performPulseAnimation,
} from '../../../../game/managers/animationManager/animations';
import { registerLogCategory } from '../../../../debug/privateLogger';
import { changeComponentVisibility } from '../../../../layoutTools/pixiComponentManagement';
import ScrollableContainerInterface, {
  TSnapScrollEvent,
} from '../../../../layoutTools/scrollableContainerInterface';
import SoundManager from '../soundManager';
import { FreeSpinManager } from '../../FreeSpinManager';
import { getLocale } from '../../../../../localization';
import { IGameOutcome } from '../../service/types';
import { Dede } from '../..';
import { fontSafeString } from '../../resources/fonts/fonts';

const log = registerLogCategory('WinHistoryManager');

const verticalElementsAllowed = 4;
const horizontalElementsAllowed = 1;
const winHistoryWidthLimit = 320;
const winningsBarHeight = 204;

const winHistoryFontSize = 38;
const textOffset = -2.5;

const dividerWidth = 3;
const dividerHeight = 60;
const symbolCountPadLeft = 25;
const symbolCountTextWidth = 25;
const symbolSpaceLeft = 10;
const symbolWidth = 45;
const symbolHeight = 45;
const winAmountPadRight = 18;

const historyXOffset = 30;
const historyYOffset = 150;

const mainHeadingFontSize = 45;
const subHeadingFontSize = 30;
const tumbleWinValueXOffset = 320;
const tumbleWinValueYOffset = 0;
const totalWinValueXOffset = 320;
const totalWinValueYOffset = 40;
const subValueFontSize = 30;
const mainValueFontSize = 45;
const totalWinXOffset = 25;

const totalWinFadeInDuration = 300;
const totalWinFadeOutDuration = 300;

const winTotalUpdateAnimation = {
  maxBrightness: 2,
  maxGlowStrength: 8,
  maxGlowDistance: 12,
  maxGrowScale: 1.1,
  durationGrow: 150,
  durationShrink: 150,
};

class WinHistoryManager extends WinHistoryManagerCore {
  private _historyCells: Container[];
  private _historyValueElements: Text[];
  private _historySymbolElements: Sprite[];
  private _historyDividers: Sprite[];
  private _historySymbolCountElements: Text[];
  private _symbolTextures!: Texture[];
  private _soundManager!: SoundManager;
  private _isMounted = false;
  private _tumbleWinCurrentValue = 0;
  private _container!: Container;
  private _tumbleWinLabel!: Text;
  private _totalWinLabel!: Text;
  private _tumbleWinValueText!: BitmapText;
  private _tumbleWinTimeout?: NodeJS.Timeout;
  private _totalWinValueText!: BitmapText;
  private _appWinHistoryContainer!: Container;
  private _appTotalWinContainer!: Container;
  private _winHistoryScrollingInterface!: ScrollableContainerInterface;

  constructor(private game: Dede, soundManager: SoundManager) {
    log(1)('constructor', { gameStatsInterface: game });
    super();

    this._historyCells = [];
    this._historyValueElements = [];
    this._historySymbolElements = [];
    this._historySymbolCountElements = [];
    this._historyDividers = [];
    this._soundManager = soundManager;

    accamaxDebug.debug.addFakeWinHistoryItem = () => {
      this._addWinHistoryItem(
        Math.floor(Math.random() * 10),
        Math.pow(Math.random() * 2.5 + 0.8, 5),
        Math.floor(Math.pow(Math.random() * 2.5, 2)) + 8
      );
    };
  }

  mount(winHistoryContainer: Container, totalWinContainer: Container) {
    log(1)('mount', {
      winHistoryContainer,
      totalWinContainer,
      gameStatsInterface: this.game,
    });

    this._appWinHistoryContainer = winHistoryContainer;
    this._appTotalWinContainer = totalWinContainer;
    this._historyCells = [];
    this._historyDividers = [];
    this._historyValueElements = [];
    this._historySymbolElements = [];
    this._historySymbolCountElements = [];

    const cellWidth = winHistoryWidthLimit / horizontalElementsAllowed;

    for (let i = 1; i < horizontalElementsAllowed; i++) {
      // const dividerSprite = new Sprite(Texture.from('winHistoryDivider'));
      const dividerSprite = new Sprite();
      this._appWinHistoryContainer.addChild(dividerSprite);
      this._historyDividers.push(dividerSprite);
      dividerSprite.anchor.set(0.5);
      dividerSprite.x = i * cellWidth;
      dividerSprite.y = winningsBarHeight / 2;
      dividerSprite.height = dividerHeight;
      dividerSprite.width = dividerWidth;
      dividerSprite.alpha = 0;
    }

    this._symbolTextures = [
      Texture.from('s1'),
      Texture.from('s2'),
      Texture.from('s3'),
      Texture.from('s4'),
      Texture.from('s5'),
      Texture.from('s6'),
      Texture.from('s7'),
      Texture.from('s8'),
      Texture.from('s9'),
      Texture.from('s10'),
    ];

    this._winHistoryScrollingInterface = new ScrollableContainerInterface(
      this._appWinHistoryContainer,
      {
        activeRegion: {
          x: 0,
          y: 0,
          width: winHistoryWidthLimit,
          height: winningsBarHeight,
        },
      }
    );

    this._winHistoryScrollingInterface.onSnapScroll.addEventListener(
      (event, { newIndexX }: TSnapScrollEvent) => {
        log(2)('onSnapScroll', { newIndexX });
        this._soundManager.soundEffectsTrack.playClick();
        this._updateWinHistoryCellPositions(newIndexX);
      }
    );

    this._winHistoryScrollingInterface.enableSnapScrolling({
      dragXSnapScrollThreshold: winHistoryWidthLimit / horizontalElementsAllowed / 1.5,
      snapScrollXRange: 1,
      snapScrollYRange: 1,
      wheelChangesWhichAxis: 'x',
    });

    this._deleteAllHistoryCells();

    this.game.freeSpinManager.onFreeSpinTransition.addEventListener((event, isActive) => {
      this._handleFreeSpinModeChanged(event, isActive);
    });

    const historyText = new Text();
    this._appWinHistoryContainer.addChild(historyText);
    historyText.anchor.set(0.5);
    historyText.x = 195;
    historyText.y = 120;
    historyText.text = getLocale('slots.ui.common.history').toUpperCase();
    historyText.style = {
      fill: 0xffffff,
      fontSize: 40,
      fontFamily: 'brlnsr',
    };
  }

  private async _deleteAllHistoryCells() {
    log(2)('_deleteAllHistoryCells', { historyCells: this._historyCells });
    if (!this._historyCells) return;

    // await performFadeOutAnimation(this._appWinHistoryContainer, winHistoryElementFadeOutDuration);

    this._historyCells.forEach((container) => {
      if (container.parent) container.parent.removeChild(container);

      container.destroy();
    });

    this._historyDividers.forEach((historyDivider) => {
      historyDivider.alpha = 0;
    });

    this._historyCells = [];
    this._historyValueElements = [];
    this._historySymbolElements = [];
    this._historySymbolCountElements = [];

    this._winHistoryScrollingInterface.updateSnapScrollRange({
      snapScrollXRange: 1,
      snapScrollYRange: 1,
    });
  }

  private async _addWinHistoryItem(symbolIndex: number, winValue: number, symbolCount: number) {
    // await performFadeOutAnimation(this._appWinHistoryContainer, winHistoryElementFadeOutDuration);

    this._historyCells.push(this._createCell(symbolIndex, winValue, symbolCount));

    this._updateWinHistoryCellPositions();

    this._winHistoryScrollingInterface.updateSnapScrollRange({
      snapScrollXRange: Math.max(
        1,
        Math.ceil(this._historyCells.length / verticalElementsAllowed) -
          horizontalElementsAllowed +
          1
      ),
      snapScrollYRange: 1,
    });

    // return performFadeInAnimation(this._appWinHistoryContainer, winHistoryElementFadeInDuration);
  }

  private _updateWinHistoryCellPositions(hOffset = 0) {
    log(3)('_updateWinHistoryCellPositions', {
      hOffset,
      historyCells: this._historyCells,
      historyDividers: this._historyDividers,
      winHistoryWidthLimit,
      horizontalElementsAllowed,
      verticalElementsAllowed,
    });

    const cellWidth = winHistoryWidthLimit / horizontalElementsAllowed;
    const cellHeight = winningsBarHeight / verticalElementsAllowed;
    const visibleCellsCount = horizontalElementsAllowed * verticalElementsAllowed;
    const visibleStart = hOffset * 2;

    this._historyCells.forEach((historyCell, ind) => {
      const visibleIndex = this._historyCells.length - ind - 1 - visibleStart;
      const hPosition = Math.floor(visibleIndex / verticalElementsAllowed);
      const vPosition = visibleIndex % verticalElementsAllowed;
      log(4)('updateWinHistoryItemPosition', {
        ind,
        visibleIndex,
        hPosition,
        vPosition,
        visibleCellsCount,
        historyCell,
        cellWidth,
        cellHeight,
      });

      if (hPosition > 0 && !vPosition && hPosition < horizontalElementsAllowed)
        this._historyDividers[hPosition - 1].alpha = 1;

      historyCell.x = cellWidth * hPosition + historyXOffset;
      historyCell.y = cellHeight * (vPosition + 0.5) + historyYOffset;
      changeComponentVisibility(
        this._appWinHistoryContainer,
        historyCell,
        visibleIndex < visibleCellsCount && visibleIndex >= 0
      );
    });
  }

  private _createCell(symbolIndex: number, winValue: number, symbolCount: number) {
    log(3)('_createCell', {
      symbolIndex,
      winValue,
      symbolCount,
      historyCells: this._historyCells,
      historySymbolCountElements: this._historySymbolCountElements,
      gameStatsInterface: this.game,
      symbolTextures: this._symbolTextures,
      historySymbolElements: this._historySymbolElements,
      historyValueElements: this._historyValueElements,
      historyDividers: this._historyDividers,
    });

    const container = new Container();
    this._appWinHistoryContainer.addChild(container);

    const symbolCountText = new Text();
    container.addChild(symbolCountText);
    this._historySymbolCountElements.push(symbolCountText);
    symbolCountText.anchor.set(0, 0.5);
    symbolCountText.x = symbolCountPadLeft;
    symbolCountText.y = textOffset;
    symbolCountText.text = symbolCount;
    symbolCountText.style = {
      fill: 0xffffff,
      fontSize: winHistoryFontSize,
      fontFamily: 'brlnsr',
    };

    const symbolSprite = new Sprite(this._symbolTextures[symbolIndex]);
    container.addChild(symbolSprite);
    this._historySymbolElements.push(symbolSprite);
    symbolSprite.anchor.set(0, 0.5);
    symbolSprite.x = symbolCountPadLeft + symbolCountTextWidth + symbolSpaceLeft;
    symbolSprite.y = 0;
    symbolSprite.width = symbolWidth;
    symbolSprite.height = symbolHeight;

    const winAmountText = new Text();
    container.addChild(winAmountText);
    this._historyValueElements.push(winAmountText);
    winAmountText.anchor.set(1, 0.5);
    winAmountText.text = formatAsCurrency(winValue);
    winAmountText.style = {
      fill: 0xffffff,
      fontSize: winHistoryFontSize,
      fontFamily: 'brlnsr',
    };
    winAmountText.x = winHistoryWidthLimit / horizontalElementsAllowed - winAmountPadRight;
    winAmountText.y = textOffset;

    return container;
  }

  _handleFreeSpinModeChanged(event: IEventDetails, isActive: boolean) {
    log(2)('_handleFreeSpinModeChanged', { isActive });
  }

  private _createContainer(isFreeSpin: boolean) {
    log(1)('_createTotalWinContainer');

    this._container = new Container();
    this._appTotalWinContainer.addChild(this._container);
    this._container.alpha = 0;
    this._isMounted = true;
    this._container.x = totalWinXOffset;

    accamaxDebug.debug.totalWinDebug = {};

    const createHeadingTexts = () => {
      const tumbleWinLabel = new Text();
      this._container.addChild(tumbleWinLabel);
      tumbleWinLabel.text = getLocale('slots.ui.common.tumbleWin');
      tumbleWinLabel.style = {
        fill: 0x000000,
        fontSize: subHeadingFontSize,
      };
      tumbleWinLabel.x = tumbleWinValueXOffset - tumbleWinLabel.width - 10;
      this._tumbleWinLabel = tumbleWinLabel;

      const totalWinLabel = new Text();
      this._container.addChild(totalWinLabel);
      totalWinLabel.text = getLocale('slots.ui.common.totalWin');
      totalWinLabel.style = {
        fill: 0x000000,
        fontSize: mainHeadingFontSize,
      };
      totalWinLabel.x = tumbleWinValueXOffset - totalWinLabel.width - 10;
      totalWinLabel.y = totalWinValueYOffset;
      totalWinLabel.visible = isFreeSpin;
      this._totalWinLabel = totalWinLabel;
    };

    // @ts-ignore
    accamaxDebug.debug.totalWinDebug.fullRestore = () => {
      this._container.parent.removeChild(this._container);
      this._createContainer(true);
    };

    const tumbleWinValueText = new BitmapText({
      text: fontSafeString('symbolOverlayFont', ' '),
      style: {
        fontFamily: 'symbolOverlayFont',
        fontSize: subValueFontSize,
      },
    });

    this._container.addChild(tumbleWinValueText);
    this._tumbleWinValueText = tumbleWinValueText;
    tumbleWinValueText.text = '';

    tumbleWinValueText.y = tumbleWinValueYOffset;
    tumbleWinValueText.x = tumbleWinValueXOffset;

    const totalWinValueText = new BitmapText({
      text: fontSafeString('goldenTextFont', ' '),
      style: {
        fontFamily: 'goldenTextFont',
        fontSize: mainValueFontSize,
      },
    });

    this._container.addChild(totalWinValueText);
    this._totalWinValueText = totalWinValueText;
    totalWinValueText.text = '';
    totalWinValueText.y = totalWinValueYOffset;
    totalWinValueText.x = totalWinValueXOffset;
    totalWinValueText.visible = isFreeSpin;

    createHeadingTexts();

    this._container.y = 10;
  }

  private async _updateWinTexts(
    tumbleWin: number,
    multiplier: number,
    tumbleWinTotal: number,
    totalWin: number,
    isFreeSpin: boolean
  ) {
    if (!this._appTotalWinContainer) return;
    console.log('updateTexts', isFreeSpin);

    if (!this._isMounted) {
      this._createContainer(isFreeSpin);
      this._isMounted = true;
    }

    this._totalWinLabel.visible = isFreeSpin;
    this._totalWinValueText.visible = isFreeSpin;
    this._tumbleWinLabel.visible = true;
    this._tumbleWinValueText.visible = true;

    this._tumbleWinLabel.style.fontSize = isFreeSpin ? subHeadingFontSize : mainHeadingFontSize;
    this._tumbleWinLabel.x = tumbleWinValueXOffset - this._tumbleWinLabel.width - 10;
    this._tumbleWinValueText.style.fontSize = isFreeSpin ? subValueFontSize : mainValueFontSize;

    // Full fade out
    if (this._tumbleWinCurrentValue && !tumbleWin) {
      log(4)('perform master fade out');
      await simpleAnimatePropertiesTo(
        totalWinFadeOutDuration,
        this._container,
        this._container,
        { alpha: { endValue: 0 } },
        { debugName: getDebugName('winHistoryManager-totalWinFadeOut') }
      ).promise;
      log(4)('master fade out complete');

      this._tumbleWinCurrentValue = tumbleWin;
      return;
    }

    let inPromise: Promise<void> | undefined = undefined;
    // Full fade in
    if (tumbleWin && !this._tumbleWinCurrentValue) {
      log(4)('perform master fade in');
      this._tumbleWinValueText.text = fontSafeString(
        'symbolOverlayFont',
        formatAsCurrency(tumbleWin) + ' ' + multiplier + 'x'
      );
      this._totalWinValueText.text = fontSafeString('goldenTextFont', formatAsCurrency(totalWin));

      inPromise = simpleAnimatePropertiesTo(
        totalWinFadeInDuration,
        this._container,
        this._container,
        { alpha: { endValue: 1 } },
        { debugName: getDebugName('winHistoryManager-totalWinFadeIn') }
      ).promise;
    }

    // Transition value (existing or full fade in)
    if (tumbleWin) {
      log(4)('perform value transition animation');
      inPromise = performPulseAnimation(this._tumbleWinValueText, {
        midChangeCallback: () => {
          this._tumbleWinValueText.text = fontSafeString(
            'symbolOverlayFont',
            formatAsCurrency(tumbleWin) + (multiplier ? '  x ' + multiplier : '')
          );
          clearTimeout(this._tumbleWinTimeout!);
          this._tumbleWinTimeout = setTimeout(() => {
            this._tumbleWinValueText.text = fontSafeString(
              'symbolOverlayFont',
              formatAsCurrency(tumbleWinTotal)
            );
          }, 3000);
          if (isFreeSpin)
            this._totalWinValueText.text = fontSafeString(
              'goldenTextFont',
              formatAsCurrency(totalWin)
            );
        },
        ...winTotalUpdateAnimation,
      });
    }

    if (inPromise) {
      await inPromise;
      log(4)('fadeIn / valueTransition animation(s) complete');

      this._tumbleWinCurrentValue = tumbleWin;
    }
  }

  protected _handleWinHistoryChanged(
    event: IEventDetails,
    { winHistoryItem, winHistoryEventType }: TWinHistoryEvent
  ) {
    log(2)('_handleWinHistoryChanged', {
      winHistoryItem,
      winHistoryEventType,
      historyCells: this._historyCells,
    });

    switch (winHistoryEventType) {
      case 'clear':
        this._deleteAllHistoryCells();
        console.log('clear');
        if (!window.game?.freeSpinSpinsStarted) this._updateWinTexts(0, 0, 0, 0, false);
        else {
          this._tumbleWinLabel.visible = false;
          this._tumbleWinValueText.visible = false;
          this._tumbleWinValueText.text = '';
        }
        break;

      case 'add':
        const _outcome = (winHistoryItem as TWinHistoryItem).outcome as IGameOutcome;
        _outcome.winnings.forEach(({ winAmount, winningSymbol, symbols }) => {
          this._addWinHistoryItem(winningSymbol, winAmount, symbols.length);
          const isLastWinningOutcome =
            _outcome.index === (_outcome.spinData!.outcomeLength || 0) - 2;
          const totalWin = (
            _outcome.isFreeSpin
              ? isLastWinningOutcome
                ? _outcome.spinData!.runningTotal
                : (_outcome.prevSpinData?.runningTotal || 0) + _outcome.runningTotal
              : isLastWinningOutcome
              ? _outcome.spinData!.winAmount
              : _outcome.runningTotal
          ) as number;
          const tumbleWin = _outcome.runningTotal;
          let multiplier = isLastWinningOutcome
            ? ((_outcome.isFreeSpin
                ? _outcome.spinData!.aggregatedMultiplier
                : _outcome.multiplier) as number)
            : 0;
          const tumbleWinTotal = _outcome.spinData!.winAmount as number;
          if (!_outcome.spinData!.outcomes.some((el) => el.multiplier)) multiplier = 0;
          if (_outcome.winningSymbols.includes(this.game.config.scatterSymbol)) multiplier = 0;

          this._updateWinTexts(
            tumbleWin,
            multiplier,
            tumbleWinTotal,
            totalWin,
            !!_outcome.isFreeSpin
          );
        });
        break;

      default:
        throw new Error('Unsupported event type for winHistoryEventType.');
    }
  }
}

export default WinHistoryManager;
