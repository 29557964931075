import { Container, Point, Rectangle } from 'pixi.js';
import GameEvent, { IEventDetails } from '../../games/gameEvent';
import { registerLogCategory } from '../../debug/privateLogger';

type TDragEvent = {
  deltaX: number;
  deltaY: number;
  dragStartX: number;
  dragStartY: number;
  currentX: number;
  currentY: number;
  totalDragX: number;
  totalDragY: number;
  dragStartedContainer: Container;
};

type TWheelEvent = {
  deltaY: number;
};

type TSnapScrollEvent = {
  indexChangeX: number;
  indexChangeY: number;
  newIndexX: number;
  newIndexY: number;
};

type TDragListener = (event: IEventDetails, dragEvent: TDragEvent) => void;
type TWheelListener = (event: IEventDetails, wheelEvent: TWheelEvent) => void;
type TSnapScrollListener = (event: IEventDetails, snapScrollEvent: TSnapScrollEvent) => void;

const defaultMinWheelDeltaSnapScrollThreshold = 49;

const log = registerLogCategory('ScrollableContainerInterface');

class ScrollableContainerInterface {
  private _container!: Container;
  private _isMouseOverContainer: boolean = false;
  private _isDragging: boolean = false;
  private _lastPosition!: Point;
  private _dragStartPoint!: Point | undefined;
  private _dragXSnapScrollThreshold!: number;
  private _dragYSnapScrollThreshold!: number;
  private _onDrag = new GameEvent<TDragListener>('scrollableContainerInterface->onDrag');
  private _onWheel = new GameEvent<TWheelListener>('\'scrollableContainerInterface->onWheel');
  private _onSnapScroll = new GameEvent<TSnapScrollListener>('\'scrollableContainerInterface->onSnapScroll');
  private _snapScrollXIndex = 0;
  private _snapScrollYIndex = 0;
  private _snapScrollXRange!: number | undefined;
  private _snapScrollYRange!: number | undefined;

  constructor(
    container: Container,
    { activeRegion }: { activeRegion?: { x: number; y: number; width: number; height: number } } = {},
  ) {
    log(1)('constructor', { container, activeRegion });
    this._container = container;

    this._makeContainerInteractive();

    if (typeof activeRegion !== 'undefined')
      this.updateActiveRegion(activeRegion);
  }

  public updateActiveRegion({ x, y, width, height }: { x: number; y: number; width: number; height: number }) {
    log(2)('updateActiveRegion', { x, y, width, height });
    this._container.hitArea = new Rectangle(x, y, width, height);
  }

  private _makeContainerInteractive() {
    log(1)('_makeContainerInteractive');

    this._container.interactive = true;
    this._isMouseOverContainer = false;

    this._container
      .on('pointerdown', (event) => {
        log(3)('pointerdown', { event });
        this._isDragging = true;
        this._lastPosition = event.data.global.clone();
      })
      .on('pointermove', (event) => {
        log(4)('pointermove', { event });
        const currentPosition = event.data.global;

        if (this._isDragging && typeof this._lastPosition !== 'undefined') {
          if (!this._dragStartPoint)
            this._dragStartPoint = currentPosition.clone();

          const deltaX = currentPosition.x - this._lastPosition.x;
          const deltaY = currentPosition.y - this._lastPosition.y;

          this._onDrag.triggerEvent({
            deltaX,
            deltaY,
            dragStartX: this._dragStartPoint.x,
            dragStartY: this._dragStartPoint.y,
            currentX: currentPosition.x,
            currentY: currentPosition.y,
            totalDragX: currentPosition.x - this._dragStartPoint.x,
            totalDragY: currentPosition.y - this._dragStartPoint.y,
            dragStartedContainer: this._container,
          });
        }

        this._lastPosition = currentPosition.clone();
      })
      .on('pointerup', () => {
        log(3)('pointerup');
        this._isDragging = false;
        this._dragStartPoint = undefined;
      })
      .on('pointerupoutside', () => {
        log(3)('pointerupoutside');
        this._isDragging = false;
        this._dragStartPoint = undefined;
      })
      .on('pointerover', () => {
        log(3)('pointerover');
        this._isMouseOverContainer = true;
      })
      .on('pointerout', () => {
        log(3)('pointerout');
        this._isMouseOverContainer = false;
      });

    window.addEventListener('wheel', (event) => {
      log(2)('addEventListener->wheel', { event });
      if (this._isMouseOverContainer) {
        this._onWheel.triggerEvent({
          deltaY: event.deltaY,
        });

        event.preventDefault();
      }
    }, { passive: false });
  }

  enableSnapScrolling({
    minWheelDeltaSnapScrollThreshold = defaultMinWheelDeltaSnapScrollThreshold,
    dragXSnapScrollThreshold,
    dragYSnapScrollThreshold,
    constrainToSingleAxis = true,
    snapScrollXRange,
    snapScrollYRange,
    snapScrollXIndexStart = 0,
    snapScrollYIndexStart = 0,
    wheelChangesWhichAxis = 'y',
  }: {
    minWheelDeltaSnapScrollThreshold?: number;
    dragXSnapScrollThreshold?: number;
    dragYSnapScrollThreshold?: number;
    constrainToSingleAxis?: boolean;
    snapScrollXRange?: number;
    snapScrollYRange?: number;
    snapScrollXIndexStart?: number;
    snapScrollYIndexStart?: number;
    wheelChangesWhichAxis: 'x' | 'y';
  }) {
    log(2)('enableSnapScrolling', {
      minWheelDeltaSnapScrollThreshold,
      dragXSnapScrollThreshold,
      dragYSnapScrollThreshold,
      constrainToSingleAxis,
      snapScrollXRange,
      snapScrollYRange,
      snapScrollXIndexStart,
      snapScrollYIndexStart,
      wheelChangesWhichAxis,
    });

    let accumulatedWheelDelta = 0;
    this._snapScrollXIndex = snapScrollXIndexStart;
    this._snapScrollYIndex = snapScrollYIndexStart;
    if (typeof snapScrollXRange !== 'undefined')
      this._snapScrollXRange = snapScrollXRange;
    if (typeof snapScrollYRange !== 'undefined')
      this._snapScrollYRange = snapScrollYRange;
    if (typeof dragXSnapScrollThreshold !== 'undefined')
      this._dragXSnapScrollThreshold = dragXSnapScrollThreshold;
    if (typeof dragYSnapScrollThreshold !== 'undefined')
      this._dragYSnapScrollThreshold = dragYSnapScrollThreshold;

    this._onWheel.addEventListener((event, wheelEvent) => {
      log(3)('_onWheel.addEventListener', { event, wheelEvent });

      if (this._isMouseOverContainer) {
        accumulatedWheelDelta += wheelEvent.deltaY;

        if (Math.abs(accumulatedWheelDelta) >= minWheelDeltaSnapScrollThreshold) {
          let change = Math.sign(accumulatedWheelDelta);
          if (wheelChangesWhichAxis === 'x')
            this._triggerSnapScrollCheck(change, 0);
          else
            this._triggerSnapScrollCheck(0, change);

          accumulatedWheelDelta = 0;
        }
      }
    });

    this._onDrag.addEventListener((event, dragEvent) => {
      log(4)('_onDrag.addEventListener', { event, dragEvent });

      if (constrainToSingleAxis) {
        if (Math.abs(dragEvent.deltaX) > Math.abs(dragEvent.deltaY)) {
          if (dragXSnapScrollThreshold && Math.abs(dragEvent.totalDragX) >= dragXSnapScrollThreshold)
            this._triggerSnapScrollCheck(-Math.sign(dragEvent.totalDragX), 0);
        }
        else {
          if (dragYSnapScrollThreshold && Math.abs(dragEvent.totalDragY) >= dragYSnapScrollThreshold)
            this._triggerSnapScrollCheck(0, -Math.sign(dragEvent.totalDragY));
        }
      }
      else {
        if (dragXSnapScrollThreshold && Math.abs(dragEvent.totalDragX) >= dragXSnapScrollThreshold)
          this._triggerSnapScrollCheck(-Math.sign(dragEvent.totalDragX), 0);

        if (dragYSnapScrollThreshold && Math.abs(dragEvent.totalDragY) >= dragYSnapScrollThreshold)
          this._triggerSnapScrollCheck(0, -Math.sign(dragEvent.totalDragY));
      }
    });
  }

  private _triggerSnapScrollCheck(xChange: number, yChange: number) {
    log(3)('_triggerSnapScrollCheck', {
      xChange,
      yChange,
      _snapScrollXIndex: this._snapScrollXIndex,
      _snapScrollYIndex: this._snapScrollYIndex,
      _snapScrollXRange: this._snapScrollXRange,
      _snapScrollYRange: this._snapScrollYRange,
    });

    const prevXIndex = this._snapScrollXIndex;
    const prevYIndex = this._snapScrollYIndex;

    if (xChange && typeof this._snapScrollXRange !== 'undefined')
      this._snapScrollXIndex = Math.max(0, Math.min(this._snapScrollXIndex + xChange, this._snapScrollXRange - 1));
    else if (xChange)
      this._snapScrollXIndex += xChange;

    if (yChange && typeof this._snapScrollYRange !== 'undefined')
      this._snapScrollYIndex = Math.max(0, Math.min(this._snapScrollYIndex + yChange, this._snapScrollYRange - 1));
    else if (yChange)
      this._snapScrollYIndex += yChange;

    if (prevXIndex !== this._snapScrollXIndex || prevYIndex !== this._snapScrollYIndex) {
      log(3)('snap scroll triggered', {
        indexChangeX: this._snapScrollXIndex - prevXIndex,
        indexChangeY: this._snapScrollYIndex - prevYIndex,
        newIndexX: this._snapScrollXIndex,
        newIndexY: this._snapScrollYIndex,
      });

      if (this._dragStartPoint) {
        let newPointX = this._dragStartPoint.x;
        let newPointY = this._dragStartPoint.y;
        if (prevXIndex !== this._snapScrollXIndex)
          newPointX = this._dragStartPoint.x -= (this._dragXSnapScrollThreshold * Math.sign(xChange));
        if (prevYIndex !== this._snapScrollYIndex)
          newPointY = this._dragStartPoint.y -= (this._dragYSnapScrollThreshold * Math.sign(yChange));

        this._dragStartPoint = new Point(newPointX, newPointY);
      }

      this._onSnapScroll.triggerEvent({
        indexChangeX: this._snapScrollXIndex - prevXIndex,
        indexChangeY: this._snapScrollYIndex - prevYIndex,
        newIndexX: this._snapScrollXIndex,
        newIndexY: this._snapScrollYIndex,
      });
    }
  }

  updateSnapScrollRange({
    snapScrollXRange,
    snapScrollYRange,
    snapScrollXIndexStart = 0,
    snapScrollYIndexStart = 0,
  }: {
    snapScrollXRange?: number;
    snapScrollYRange?: number;
    snapScrollXIndexStart?: number;
    snapScrollYIndexStart?: number;
  }) {
    log(2)('updateSnapScrollRange', {
      snapScrollXRange,
      snapScrollYRange,
      snapScrollXIndexStart,
      snapScrollYIndexStart,
    });
    this._snapScrollXRange = snapScrollXRange;
    this._snapScrollYRange = snapScrollYRange;
    this._snapScrollXIndex = snapScrollXIndexStart;
    this._snapScrollYIndex = snapScrollYIndexStart;
  }

  get onDrag() {
    return this._onDrag;
  }

  get onWheel() {
    return this._onWheel;
  }

  get onSnapScroll() {
    return this._onSnapScroll;
  }
};

export default ScrollableContainerInterface;

export type {
  TDragEvent,
  TDragListener,
  TSnapScrollEvent,
  TSnapScrollListener,
  TWheelEvent,
  TWheelListener,
};
