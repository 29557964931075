import { Container, Text } from 'pixi.js';
import { simpleAnimatePropertiesTo, simpleAnimationDuration } from '../index';
import { AdjustmentFilter, GlowFilter } from 'pixi-filters';
import { numberTextConsistentScaler } from '../../../../layoutTools/pixiComponentManagement';
import { formatAsCurrency } from '../../currencyManager';
import { lerp } from '../../../math/interpolationFunctions';

const performFadeOutInAnimation = async (
  element: Container,
  {
    durationOut = 500,
    durationIn = 500,
    midChangeCallback,
  }: {
    durationOut?: number;
    durationIn?: number;
    midChangeCallback?: () => void;
  } = {},
) => {
  await simpleAnimatePropertiesTo(durationOut, element, element, { alpha: { endValue: 0 } }).promise;
  midChangeCallback?.();
  return simpleAnimatePropertiesTo(durationIn, element, element, { alpha: { endValue: 1 } }).promise;
};

const performPulseAnimation = async (
  element: Container,
  {
    durationGrow = 250,
    durationShrink = 250,
    midChangeCallback,
    maxGrowScale = 1,
    maxBrightness = 1,
    maxGlowStrength = 0,
    glowColor = 0xfcfffa,
    maxGlowDistance = 0,
  }:
  {
    durationGrow?: number;
    durationShrink?: number;
    midChangeCallback?: () => void;
    maxGrowScale?: number;
    returnScale?: number;
    maxBrightness?: number;
    maxGlowStrength?: number;
    glowColor?: number;
    maxGlowDistance?: number;
  },
) => {
  const startScale = element.scale.x;
  let brightnessFilter: AdjustmentFilter;
  let glowFilter: GlowFilter;
  const filters = [];
  const origFilters = [
    ...(element.filters ? (Array.isArray(element.filters) ? element.filters : [element.filters]) : []),
  ];

  if (maxBrightness !== 1) {
    brightnessFilter = new AdjustmentFilter({
      brightness: 1,
    });
    filters.push(brightnessFilter);
  }
  if (maxGlowStrength) {
    glowFilter = new GlowFilter({
      distance: 1,
      outerStrength: 0.01,
      color: glowColor,
    });
    filters.push(glowFilter);
  }

  element.filters = [
    ...origFilters,
    ...filters,
  ];

  await simpleAnimationDuration(durationGrow, ({ progress }) => {
    if (maxGrowScale !== 1) {
      const newScale = lerp(startScale, startScale * maxGrowScale, progress);
      element.scale.set(newScale, newScale);
    }

    if (brightnessFilter)
      brightnessFilter.brightness = lerp(1, maxBrightness, progress);

    if (glowFilter) {
      glowFilter.outerStrength = lerp(0, maxGlowStrength, progress);
      glowFilter.distance = lerp(0, maxGlowDistance, progress);
    }
  }).promise;

  const currentValues = {
    brightness: maxBrightness,
    scale: startScale * maxGrowScale,
    distance: maxGlowDistance,
    strength: maxGlowStrength,
  };
  const returnValues = {
    brightness: 1,
    scale: startScale,
    distance: 1,
    strength: 0.01,
    ...(midChangeCallback?.() ?? {}),
  };

  await simpleAnimationDuration(durationShrink, ({ progress }) => {
    if (maxGrowScale !== 1) {
      const newScale = lerp(currentValues.scale, returnValues.scale, progress);
      element.scale.set(newScale, newScale);
    }

    if (brightnessFilter)
      brightnessFilter.brightness = lerp(currentValues.brightness, returnValues.brightness, progress);

    if (glowFilter) {
      glowFilter.outerStrength = lerp(currentValues.strength, returnValues.strength, progress);
      glowFilter.distance = lerp(currentValues.distance, returnValues.distance, progress);
    }
  }).promise;

  element.filters = origFilters;
};

const performFadeOutAnimation = (element: Container, duration: number = 300) => {
  return simpleAnimatePropertiesTo(
    duration,
    element,
    element,
    { alpha: { endValue: 0 } },
  ).promise;
};

const performFadeInAnimation = (element: Container, duration: number = 300) => {
  return simpleAnimatePropertiesTo(
    duration,
    element,
    element,
    { alpha: { endValue: 1 } },
  ).promise;
};

const performNumberCountUp = (
  element: Text,
  duration: number,
  startValue: number,
  endValue: number,
  {
    formatter,
    updater,
  }: {
    formatter?: (value: number) => string;
    updater?: (value: number) => void;
  },
) => {
  return simpleAnimationDuration(duration, ({ progress }) => {
    const value = lerp(startValue, endValue, progress);
    if (updater)
      updater(value);
    element.text = formatter ? formatter(value) : value;
  }).promise;
};

const performCurrencyCountUp = (
  element: Text,
  duration: number,
  startValue: number,
  endValue: number,
  maxWidth: number,
) => {
  const uid = Math.random();
  const getUpdatedScale = numberTextConsistentScaler(element, maxWidth, formatAsCurrency);

  return simpleAnimationDuration(duration, ({ progress }) => {
    const value = lerp(startValue, endValue, progress);
    const scale = getUpdatedScale(value).scale;

    element.scale.set(scale);
    element.text = formatAsCurrency(value);
  }).promise;
};

export {
  performFadeOutInAnimation,
  performPulseAnimation,
  performFadeOutAnimation,
  performFadeInAnimation,
  performCurrencyCountUp,
};
