const lerp = (start: number, end: number, progress: number) => {
  return start + (end - start) * progress;
};

const quantizedLerp = (steps = 10, start: number, end: number, progress: number) => {
  // Ensure t is clamped between 0 and 1
  progress = Math.min(Math.max(progress, 0), 1);

  // Divide the range into `steps` evenly spaced positions
  const stepSize = 1 / (steps - 1);

  // Find the nearest step
  const quantizedProgress = Math.round(progress / stepSize) * stepSize;

  return lerp(start, end, quantizedProgress);
};

export {
  lerp,
  quantizedLerp,
};
