import { Graphics, Sprite, Texture } from 'pixi.js';
import Button from '../button';
import {
  BLOCK_CONTAINER_X_OFFSET,
  BLOCK_CONTAINER_Y_OFFSET,
  CONTAINER_Y_OFFSET,
  PORTRAIT_Y_OFFSET,
  TEXT_BUTTONS_CONTAINER_LANDSCAPE_X_OFFSET,
  TEXT_BUTTONS_CONTAINER_LANDSCAPE_Y_OFFSET,
  TEXT_BUTTONS_CONTAINER_PORTRAIT_X_OFFSET,
  TEXT_BUTTONS_CONTAINER_PORTRAIT_Y_OFFSET,
} from './constants';
import { mountPortraitButtons } from './portraitButtons';
import { mountLandscapeButtons } from './landscapeButtons';
import { Orientation } from '../../../types';
import { SHORT_DIMENSION } from '../../resources/constants';
import { Dede } from '../..';
import { mountCommonButtons } from './commonButtons';
import TextButton from '../textButton';

export default class GameController {
  container = new Graphics();
  textButtonsContainer = new Graphics();
  blockContainer = new Graphics();
  width = 0;
  height = 0;

  buyBonusButton!: TextButton;
  goldenBetButton!: TextButton;
  subBetButton!: Button;
  settingsButton!: Button;
  soundButton!: Button;
  addBetButton!: Button;
  turboSpinButton!: Button;
  stakeButton!: Button;
  spinButton!: Button;
  infoButton!: Button;
  autoPlayButton!: Button;
  spinContainer!: Sprite;
  orientation!: Orientation;

  constructor(public game: Dede) {
    this.container.width = this.width;
    this.container.height = this.height;
    this.container.addChild(this.blockContainer);
    this.container.zIndex = 5;
    this.textButtonsContainer.zIndex = 5;
    game.onFreeSpinChange.addEventListener((e, freeSpins) => {
      if (game.freeSpinActivated) {
        this.textButtonsContainer.visible = false;
      } else {
        this.textButtonsContainer.visible = true;
      }
    });
  }

  handleResize = () => {
    const orientation = this.game.getOrientation();

    let scale = this.game.reelsManager.scale;

    if (orientation === 'portrait') {
      this.container.x = this.game.width / 2 - (this.blockContainer.width * scale) / 2;
      this.container.y =
        this.game.reelsManager.container.y +
        this.game.reelsManager.container.width -
        PORTRAIT_Y_OFFSET * scale;

      this.textButtonsContainer.x =
        this.game.reelsManager.container.x + TEXT_BUTTONS_CONTAINER_PORTRAIT_X_OFFSET * scale;
      this.textButtonsContainer.y =
        this.game.reelsManager.container.y +
        this.game.reelsManager.container.width -
        TEXT_BUTTONS_CONTAINER_PORTRAIT_Y_OFFSET * scale;

      this.blockContainer.x = 0;
      this.blockContainer.y = 0;
    } else {
      this.container.x = (this.game.width - this.game.reelsManager.container.width) / 2;
      this.container.y =
        this.game.reelsManager.container.y +
        this.game.reelsManager.container.height +
        CONTAINER_Y_OFFSET * scale;

      this.textButtonsContainer.x =
        this.game.reelsManager.container.x +
        this.game.reelsManager.container.width +
        TEXT_BUTTONS_CONTAINER_LANDSCAPE_X_OFFSET * scale;

      this.textButtonsContainer.y =
        this.game.reelsManager.container.y +
        this.game.reelsManager.container.height +
        TEXT_BUTTONS_CONTAINER_LANDSCAPE_Y_OFFSET * scale;

      this.blockContainer.x =
        this.game.reelsManager.container.width / scale + BLOCK_CONTAINER_X_OFFSET;
      this.blockContainer.y = BLOCK_CONTAINER_Y_OFFSET;
    }
    if (orientation !== this.orientation) {
      this.mountButtons();
      this.orientation = orientation;
    }
    this.container.scale.set(scale);
    this.textButtonsContainer.scale.set(scale);
  };

  destroyButtons() {
    this.buyBonusButton?.destroy();
    this.goldenBetButton?.destroy();
    this.subBetButton?.destroy();
    this.addBetButton?.destroy();
    this.turboSpinButton?.destroy();
    this.stakeButton?.destroy();
    this.spinButton?.destroy();
    this.infoButton?.destroy();
    this.autoPlayButton?.destroy();
    this.spinContainer?.destroy();
    this.soundButton?.destroy();
    this.settingsButton?.destroy();
  }

  mountButtons() {
    this.destroyButtons();
    const orientation = this.game.getOrientation();
    mountCommonButtons(this);
    if (orientation === 'portrait') {
      mountPortraitButtons(this);
    } else {
      mountLandscapeButtons(this);
    }
  }

  mount() {
    this.game.app.stage.addChild(this.container);
    this.game.app.stage.addChild(this.textButtonsContainer);

    this.container.scale.set(this.game.reelsManager.scale);
    // this.blockContainer.scale.set(this.game.reelsManager.scale);

    this.orientation = this.game.getOrientation();
    this.mountButtons();

    this.game.onBetButtonsEnabledChange.addEventListener((e, value) => {
      this.spinButton.content.texture = Texture.from(value ? 'spin' : 'spinDisabled');
      this.stakeButton.disabled = !value;
      this.subBetButton.disabled = !value;
      this.addBetButton.disabled = !value;
      // this.turboSpinButton.disabled = !value;
      this.buyBonusButton.disabled = !value;
      this.goldenBetButton.disabled = !value;
      // this.textButtonsContainer.alpha = !value ? 0.5 : 1;

      if (this.game.autoPlayCount === 0) {
        this.autoPlayButton.disabled = !value;
      }
    });

    this.game.onAnteBetChange.addEventListener((e, value) => {
      // this.buyBonusButton.container.alpha = !value ? 0.5 : 1;
      console.log('ante bet change', value);
      this.buyBonusButton.disabled = value;
    });

    this.game.onSpinComplete.addEventListener(() => {
      if (this.game.finishInstantlyActive) {
        this.game.finishInstantlyActive = false;
      }
    });

    this.game.onFreeSpinChange.addEventListener((e, freeSpinData) => {
      if (freeSpinData.isActivating || freeSpinData.activated) {
        this.textButtonsContainer.visible = false;
        this.blockContainer.visible = false;
      } else {
        this.textButtonsContainer.visible = true;
        this.blockContainer.visible = true;
      }
    });

    this.handleResize();
    this.handleResize();
    this.game.onResize.addEventListener(this.handleResize, -3);
  }
}
