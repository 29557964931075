import { Sprite, Texture } from 'pixi.js';
import { Dede } from '.';
import { IFreeSpinStatus } from '../types';
import { Spine } from '@pixi/spine-pixi';

export class BackgroundManager {
  spine!: Spine;
  freeSpinStatus?: IFreeSpinStatus;
  constructor(private game: Dede) {}

  unMountSpine() {
    if (this.spine) {
      this.game.app.stage.removeChild(this.spine);
    }
  }

  mountSpine(isFreeSpin = false) {
    if (!isFreeSpin) {
      this.spine = Spine.from({ skeleton: 'backgroundData', atlas: 'backgroundAtlas' });
      this.spine.state.setAnimation(0, 'animation', true);
    } else {
      this.spine = Spine.from({ skeleton: 'fsBackgroundData', atlas: 'fsBackgroundAtlas' });
      this.spine.state.setAnimation(0, 'animation', true);
    }
    this.spine.scale.set(0.5);
    this.game.app.stage.addChild(this.spine);
    this.handleResize();
  }

  handleResize = () => {
    this.spine.x = this.game.width / 2;
    this.spine.y = this.game.height / 2;
    this.spine.scale.set(0.5);
  };

  async init() {
    this.mountSpine();

    this.game.onResize.addEventListener(this.handleResize);
    this.handleResize();

    this.game.freeSpinManager.onFreeSpinTransition.addEventListener((event, isActive: boolean) => {
      this.processFreeSpinBackgroundChange(isActive);
    });
  }

  processFreeSpinBackgroundChange(isFreeSpin = false) {
    if (!this.spine) return;

    this.unMountSpine();
    this.mountSpine(isFreeSpin);
  }
}
