import CachedSettingsManager from '../../cachedSettingsManager';
import * as PIXI from 'pixi.js';
import { registerLogCategory } from '../privateLogger';

const ticker = PIXI.Ticker.shared;

const log = registerLogCategory('fpsCounter');

class FpsCounter {
  private counterIsCreated: boolean = false;
  // This toggle is used to avoid running some code inside the PIXI tracker if we don't currently
  // need to because the counter is turned off.
  private counterIsActive: boolean = false;
  // Counts frames between 1 second reset intervals
  private frameCount: number = 0;
  // Used to track delta between frames
  private lastTime: number = performance.now();
  private app: PIXI.Application;

  constructor(app: PIXI.Application) {
    log(1)('Constructor');

    this.app = app;
    this.registerFpsCounter();
  }

  // Sets up the html for the counter and starts the PIXI tracker
  private setupCounterContainer(): void {
    log(2)('Ran: setupCounterContainer');

    const fpsCounter = document.createElement('div');
    fpsCounter.id = 'fps-counter';
    fpsCounter.textContent = 'FPS: 0';
    document.body.appendChild(fpsCounter);

    Object.assign(fpsCounter.style, {
      position: 'fixed',
      top: '10px',
      left: '10px',
      padding: '5px 10px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: '#ffffff',
      fontFamily: 'Arial, sans-serif',
      fontSize: '18px',
      zIndex: '1000',
      pointerEvents: 'none',
    });
    log(3)('Counter element created and attached', { el: fpsCounter });

    ticker.add(() => {
      // No need to run this code if the Fps is disabled
      if (!this.counterIsActive)
        return;

      this.frameCount++;
      const currentTime = performance.now();
      const delta = currentTime - this.lastTime;

      // We update the fps counter every second
      if (delta >= 1000) {
        log(4)('1 second elapsed, attempt counter update', {
          el: fpsCounter,
          frameCount: this.frameCount,
          lastTime: this.lastTime,
          delta,
        });

        const fps = Math.round((this.frameCount * 1000) / delta);
        fpsCounter.textContent = `FPS: ${fps}`;
        this.frameCount = 0;
        this.lastTime = currentTime;
      }
    });

    // Just to make sure we don't run setupCounterContainer again.
    this.counterIsCreated = true;
  }

  // Sets up the CachedSettingsManager setting for 'showFps' and starts watching it for changes
  // Upon receiving a change or onload, toggles/starts the tracker and shows/hides the counter
  private registerFpsCounter(): void {
    log(2)('Ran: registerFpsCounter');

    const toggleCounter = (value: boolean): void => {
      const fpsCounter = document.getElementById('fps-counter');

      log(3)('attempt counter visibility toggle', {
        value,
        isCreated: this.counterIsCreated,
        isActive: this.counterIsActive,
        fpsCounter,
      });

      // A truthy value from the setting for 'showFps'
      if (value) {
        // we only want to start tracking and create html for the counter once
        if (!this.counterIsCreated)
          this.setupCounterContainer();

        // The if is for Typescripts benefit
        if (fpsCounter)
          fpsCounter.style.display = 'block';
      }
      else if (fpsCounter)
        fpsCounter.style.display = 'none';

      // This toggle is
      this.counterIsActive = value;
    };

    CachedSettingsManager.registerSetting(
      'showFps',
      // Default for the setting based on if we are on localhost or not
      window.location.hostname.includes('localhost'),
    );

    CachedSettingsManager.addListener('showFps', (value: unknown) => {
      log(2)('showFps setting changed event', { value });

      // Nested function here to get around Typescript issue of unknown -> boolean
      toggleCounter(value as boolean);
    });
    // Manual trigger toggle here as addListener does not to initial triggers
    toggleCounter(CachedSettingsManager.get('showFps') as boolean);
  }
}

export default FpsCounter;
