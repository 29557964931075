import SoundTrack from '../../../../game/managers/soundManager/soundTrack';

class SoundEffectsTrack extends SoundTrack {
  mount() {
    this.registerSoundEffect('sfxSymExplode');
    this.registerSoundEffect('sfxSymLand', { debounceTime: 100 });
    this.registerSoundEffect('sfxSymHighlight');
    this.registerSoundEffect('sfxClick');
    this.registerSoundEffect('sfxBonusBuyOpen');
    this.registerSoundEffect('sfxBonusBuyConfirm');
    this.registerSoundEffect('sfxScatterLand', { debounceTime: 100 });
    this.registerSoundEffect('sfxScatterWin', { debounceTime: 100 });
    this.registerSoundEffect('sfxMultiplierLand', { debounceTime: 100 });
    this.registerSoundEffect('sfxMultiplierReveal', { debounceTime: 100 });
    this.registerSoundEffect('musTumble');

    this._isMounted = true;
  }

  playTumble() {
    this.getSound('musTumble')!.play();
  }

  playSymExplode() {
    this.getSound('sfxSymExplode')!.play();
  }

  playSymLand() {
    this.getSound('sfxSymLand')!.play();
  }

  playSymHighlight() {
    this.getSound('sfxSymHighlight')!.play();
  }

  playClick() {
    this.getSound('sfxClick')!.play();
  }

  playBonusBuyOpen() {
    this.getSound('sfxBonusBuyOpen')!.play();
  }

  playBonusBuyConfirm() {
    this.getSound('sfxBonusBuyConfirm')!.play();
  }

  playScatterLand() {
    this.getSound('sfxScatterLand')!.play();
  }

  playScatterWin() {
    return new Promise<void>((resolve) => {
      const scatterWin = this.getSound('sfxScatterWin')!;
      scatterWin.play();
      scatterWin.onComplete(() => {
        resolve();
      });
    });
  }

  playMultiplierLand() {
    this.getSound('sfxMultiplierLand')!.play();
  }

  playMultiplierReveal() {
    this.getSound('sfxMultiplierReveal')!.play();
  }
}

export default SoundEffectsTrack;
