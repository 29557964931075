import cachedSettingsManager from '../cachedSettingsManager';
import { consoleInterface as logger } from './privateLogger';

const debug: Record<string, unknown> = {};

const accamaxDebug = {
  settings: cachedSettingsManager,
  logger,
  debug,
};

window.accamaxDebug = accamaxDebug;

const randomChars = (len = 8) => [...Array(len)].map(
  () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.random() * 62),
).join('');

const getDebugName = (name?: string) => {
  return (name ? (name + '--') : '') + randomChars();
};

export default accamaxDebug;

export {
  getDebugName,
};
