import { Container, Graphics, Text } from 'pixi.js';
import { Dede } from '.';
import { IFreeSpinStatus } from '../types';
import accamaxDebug from '../../debug';
import { Spine } from '@pixi/spine-pixi';
import { simpleAnimatePropertiesTo } from '../../game/managers/animationManager';
import { performCurrencyCountUp } from '../../game/managers/animationManager/animations';
import GameEvent from '../gameEvent';
import { applyGoldenMoneyTextStyle } from './managers/textStyles';

const bigWinReelSpineScaleFactor = 0.00016;
const bigWinReelXOffsetRatio = 0.5;
const bigWinReelYOffsetRatio = 0.25;
const bigWinDuration = 5800;
const bigWinFadeDuration = 500;
const bigWinCountUpDuration = 1300;
const bigWinValueMaxWidth = 1000;
const bigWinValueFontSize = 112;
const bigWinValueOffsetY = 328;
const bigWinMusicPostPlayBuffer = 500;

const winSizeIndex = {
  big: 0,
  mega: 1,
  olympus: 2,
};

export class BigWinManager {
  freeSpinStatus?: IFreeSpinStatus;
  private _bigWinContainer?: Container;
  private _bigWinValueText!: Text;
  private _game!: Dede;
  private _spine?: Spine;
  private _modalMask!: Graphics;
  private _startBigWinEndMusicTimer: ReturnType<typeof setTimeout> | undefined = undefined;
  private _isEndingSequence = false;

  constructor(private game: Dede) {
    game.onSpinComplete.addEventListener((event, { totalWinAmount, tumbleWinAmount }) => {
      const winType = this._getWinType(tumbleWinAmount);
      if (winType) {
        GameEvent.mainQueue.add(() => this._mountPopup(tumbleWinAmount, winType), 5);
      }
    });

    this._game = game;
    this._game.onResize.addEventListener(() => this._handleResize());
    accamaxDebug.debug.triggerBigWin = (amount = 150) => {
      const winType = this._getWinType(amount);
      if (!winType) {
        console.error(`${amount} is not a big win amount`);
        return;
      }
      this._mountPopup(amount, this._getWinType(amount) as 'big' | 'mega' | 'olympus');
    };

    game.onFreeSpinChange.addEventListener((event, status) => {
      this.freeSpinStatus = status;
    });
  }

  private _getWinType(winAmount: number) {
    const winSizes = this._game.config?.limits.winSizes;
    if (!winSizes) return null;

    if (winAmount >= this._game.stake * winSizes[winSizeIndex.olympus]) return 'olympus';
    if (winAmount >= this._game.stake * winSizes[winSizeIndex.mega]) return 'mega';
    if (winAmount >= this._game.stake * winSizes[winSizeIndex.big]) return 'big';

    return null;
  }

  private _getWinThreshold(winType: 'big' | 'mega' | 'olympus') {
    const winSizes = this._game.config?.limits.winSizes;
    return winSizes ? winSizes[winSizeIndex[winType]] * this._game.stake : null;
  }

  _mountPopup(winAmount: number, winType: 'big' | 'mega' | 'olympus') {
    return new Promise<void>((resolve) => {
      this.game.betButtonsDisabler.disable('bigWin');
      this.game.gameDisabler.disable('bigWin');
      this._bigWinContainer = new Container();
      this._game.app.stage.addChild(this._bigWinContainer);
      this._bigWinContainer.zIndex = 40;
      const prefix = winType === 'big' ? 'B' : winType === 'mega' ? 'M' : 'O';
      // this._bigWinContainer.alpha = 0;
      this._spine = Spine.from({ skeleton: `winData`, atlas: `winAtlas` });
      const animation = this._spine.state.setAnimation(0, `win${prefix}_start`, false);
      animation.listener = {
        complete: () => {
          const idleAnimation = this._spine!.state.setAnimation(0, `win${prefix}_idle`, true);
          let animationCount = 0;
          idleAnimation.listener = {
            complete: () => {
              if (++animationCount === 4) {
                const idleAnimation = this._spine!.state.setAnimation(0, `win${prefix}_end`, false);
                idleAnimation.listener = {
                  complete: () => {
                    requestAnimationFrame(() => {
                      resolve();
                      this._unmountPopup();
                    });
                  },
                };
              }
            },
          };
        },
      };

      this._bigWinContainer.addChild(this._spine);

      const modalMask = new Graphics();
      this._game.app.stage.addChild(modalMask);
      modalMask.rect(0, 0, this._game.app.screen.width, this._game.app.screen.height);
      modalMask.fill(0x000000);
      modalMask.alpha = 0;
      modalMask.zIndex = 39;
      this._modalMask = modalMask;

      this._bigWinValueText = new Text();
      this._bigWinContainer.addChild(this._bigWinValueText);
      this._bigWinValueText.anchor.set(0.5);
      this._bigWinValueText.y = bigWinValueOffsetY;

      this._bigWinValueText.style = {
        fontSize: bigWinValueFontSize,
      };

      applyGoldenMoneyTextStyle(this._bigWinValueText);

      performCurrencyCountUp(
        this._bigWinValueText,
        bigWinCountUpDuration,
        0,
        winAmount,
        bigWinValueMaxWidth
      );

      simpleAnimatePropertiesTo(bigWinFadeDuration * 2, this._modalMask, this._modalMask, {
        alpha: { endValue: 0.75 },
      });

      setTimeout(() => {
        this._performFadeOut();
      }, bigWinDuration - bigWinFadeDuration);

      this._isEndingSequence = false;
      this._game.soundManager.bigWinTrack.playBigWin(winType);

      if (this._startBigWinEndMusicTimer) clearTimeout(this._startBigWinEndMusicTimer);

      this._startBigWinEndMusicTimer = setTimeout(() => {
        this._isEndingSequence = true;
        this._game.soundManager.bigWinTrack.transitionToBigWinEnd();
        clearTimeout(this._startBigWinEndMusicTimer);
      }, bigWinDuration - this._game.soundManager.bigWinTrack.getBigWinEndDuration(winType) + bigWinMusicPostPlayBuffer);

      this._handleResize();
    });
  }

  private _performFadeOut() {
    simpleAnimatePropertiesTo(
      bigWinFadeDuration,
      this._modalMask,
      this._modalMask,
      { alpha: { endValue: 0 } },
      { autoEndOnError: true }
    );

    return simpleAnimatePropertiesTo(
      bigWinFadeDuration,
      this._bigWinValueText!,
      this._bigWinValueText!,
      { alpha: { endValue: 0 } },
      { autoEndOnError: true }
    ).promise;
  }

  private _unmountPopup() {
    this.game.gameDisabler.enable('bigWin');
    this.game.betButtonsDisabler.enable('bigWin');
    this._isEndingSequence = false;
    this._bigWinContainer!.parent.removeChild(this._bigWinContainer!);
    this._bigWinContainer!.destroy();
    this._bigWinContainer = undefined;
    this._modalMask.parent.removeChild(this._bigWinContainer!);
    this._modalMask.destroy();
  }

  private _handleResize() {
    if (!this._bigWinContainer) return;

    const globalDimensions = this._game.reelsManager.containerSprite.getBounds();

    this._modalMask!.width = this._game.app.screen.width;
    this._modalMask!.height = this._game.app.screen.height;

    this._bigWinContainer!.x = globalDimensions.x + globalDimensions.width * bigWinReelXOffsetRatio;
    this._bigWinContainer!.y =
      globalDimensions.y + globalDimensions.height * bigWinReelYOffsetRatio;
    this._bigWinContainer!.scale = globalDimensions.width * bigWinReelSpineScaleFactor;
  }
}
