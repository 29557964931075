import en from './resources/en.json';
import tr from './resources/tr.json';
import es from './resources/es.json';

const language = new URLSearchParams(window.location.search).get('lang') || 'en';
const languageData = language === 'en' ? en : language === 'tr' ? tr : es;

export const getLocale = (key: keyof typeof languageData, ...args: any[]) => {
  if (!languageData[key]) return key;
  if (args.length)
    return languageData[key].replace(/{(\d+)}/g, (match, number) => {
      return typeof args[number] !== 'undefined' ? args[number] : match;
    });
  return languageData[key];
};
