import { registerLogCategory } from '../../../../debug/privateLogger';

const log = registerLogCategory('error');

const safeFonts = {
  symbolOverlayFont: { supportedChars: 'x0123456789.,$£€¥', regexPartials: 'x0123456789\.,$£€¥' },
  goldenTextFont: { supportedChars: 'x0123456789.,$£€¥', regexPartials: 'x0123456789\.,$£€¥' },
};

const fontSafeString = (font: keyof typeof safeFonts, text: string) => {
  const supportedChars = safeFonts[font].regexPartials;
  if (text.match(new RegExp(`[^${supportedChars}]`)))
    log(1)(`fontSafeString: Detected unsafe characters.  Supported chars are: ${font}->"${safeFonts[font].supportedChars}".  Invalid string: "${text}"`);
  return text.replace(new RegExp(`[^${supportedChars}]`, 'g'), '');
};

export {
  fontSafeString,
};
