import { Graphics, Sprite, Texture, Text, Container } from 'pixi.js';
import { Dede } from '.';
import { formatAsCurrency } from '../../game/managers/currencyManager';
import GameEvent, { IEventDetails } from '../gameEvent';
import { StakeChangeListener } from '../types';
import { performPulseAnimation } from '../../game/managers/animationManager/animations';
import { IGameSpinResponse, IJackpotResponse } from './service/types';
import Popup from './models/popup';
import { getLocale } from '../../../localization';
import { splitSentence } from '../../utils/text';
import accamaxDebug from '../../debug';
import { Spine } from '@pixi/spine-pixi';
import delay from 'delay';
import { fontSafeString } from './resources/fonts/fonts';
import { TransitionManager } from '../../game/managers/transitionManager';

const X_OFFSET = 1355;
const Y_OFFSET = -10;
const COLUMN_SIZE = 266.666;
const JACKPOT_BANNER_TEXT_X_OFFSET = 2;
const JACKPOT_BANNER_TEXT_Y_OFFSET = 45;
const JACKPOT_BANNER_FONT_SIZE = 34;
const JACKPOT_BANNER_TEXT_LIMIT = 220;
const JACKPOT_MINOR_STAKE_MULTIPLIER = 25;
const JACKPOT_MINI_STAKE_MULTIPLIER = 10;
const COIN_HEIGHT = 410;
const COIN_WIDTH = 410;

const COIN_GAP = 100;

// jackpot enums
export enum JackpotTier {
  Mini = 1,
  Minor = 2,
  Major = 3,
  Grand = 4,
}

class ScaleTrackedText extends Text {
  public lastDigitCount = 1;
}

export class JackpotManager {
  container = new Graphics();
  jackpotGameContainer!: Graphics;
  coinsContainer!: Sprite;
  jackpotGameBackground!: Sprite;
  vaultSpine!: Spine;
  coins: {
    tier?: JackpotTier;
    spine: Spine;
  }[] = [];

  jackpotResponse: IJackpotResponse | undefined;
  clickIndex = 0;
  disableClick = false;
  coinScale = 0.5;

  private _grandText!: ScaleTrackedText;
  private _grandSprite!: Sprite;
  private _grandJackpotValue!: number;
  private _majorText!: ScaleTrackedText;
  private _majorSprite!: Sprite;
  private _majorJackpotValue!: number;
  private _minorText!: ScaleTrackedText;
  private _minorSprite!: Sprite;
  private _miniText!: ScaleTrackedText;
  private _miniSprite!: Sprite;
  private _jackpotLabelAnimationPromise?: Promise<void>;
  private _stakeValue!: number;
  private _stakeUpdateIsQueued: boolean = false;
  private _transitionManager: TransitionManager;

  jackpotPopup!: Popup;

  constructor(private game: Dede) {
    accamaxDebug.debug.triggerJackpot = (tier = 'Mini') => {
      window.testData = { jackpotTier: tier };
      this.game.runReels(false);
    };
    this._transitionManager = new TransitionManager(this.game);
  }

  async mount(stake: number, onStakeChange: GameEvent<StakeChangeListener>) {
    this._stakeValue = stake;

    let result = this._createJackpotVariant('jpGrand', 0x8e2014, this._grandJackpotValue, 0);
    this._grandSprite = result.sprite;
    this._grandText = result.valueText;
    result = this._createJackpotVariant('jpMajor', 0xa77300, this._majorJackpotValue, 1);
    this._majorSprite = result.sprite;
    this._majorText = result.valueText;
    result = this._createJackpotVariant(
      'jpMinor',
      0x3d7825,
      stake * JACKPOT_MINOR_STAKE_MULTIPLIER,
      2
    );
    this._minorSprite = result.sprite;
    this._minorText = result.valueText;
    result = this._createJackpotVariant(
      'jpMini',
      0x2e487b,
      stake * JACKPOT_MINI_STAKE_MULTIPLIER,
      3
    );
    this._miniSprite = result.sprite;
    this._miniText = result.valueText;

    this.game.reelsManager.container.addChild(this.container);

    this.container.x = X_OFFSET;
    this.container.y = Y_OFFSET;
    this.container.zIndex = 5;

    onStakeChange.addEventListener((event: IEventDetails, newStake: number) => {
      this._stakeValue = newStake;
      this._handleStakeChanged(newStake);
    });
  }

  private _updateJackpotVariantValue(
    value: number,
    container: Container,
    text: ScaleTrackedText,
    performAnimation = true
  ) {
    if (!value || !text || !container) return;

    const origScale = text.scale.x;
    let targetScale = origScale;

    const textUpdateCallback = () => {
      const digitCount = Math.floor(Math.log10(value));
      if (digitCount !== text.lastDigitCount) {
        text.text = formatAsCurrency(Math.pow(10, digitCount) * 4);
        const lastScale = text.scale.x;
        text.scale.set(1);

        if (text.width > JACKPOT_BANNER_TEXT_LIMIT) {
          const overlap = text.width - JACKPOT_BANNER_TEXT_LIMIT;
          targetScale = 1 - overlap / text.width;
        }
        text.scale.set(lastScale);
        text.lastDigitCount = digitCount;
      }

      text.text = formatAsCurrency(value);
      return { scale: targetScale };
    };

    if (!performAnimation) {
      textUpdateCallback();
      return;
    }

    return performPulseAnimation(text, {
      midChangeCallback: textUpdateCallback,
      maxBrightness: 2,
      maxGlowStrength: 8,
      maxGlowDistance: 12,
      maxGrowScale: 1.1,
      durationGrow: 150,
      durationShrink: 150,
    });
  }

  private _createJackpotVariant(
    spriteName: string,
    color: number,
    value: number,
    position: number
  ) {
    const sprite = new Sprite(Texture.from(spriteName));
    this.container.addChild(sprite);
    sprite.x = COLUMN_SIZE * position;

    const valueText = new ScaleTrackedText();
    sprite.addChild(valueText);
    valueText.anchor.set(0.5, 0.5);
    valueText.x = sprite.width / 2 + JACKPOT_BANNER_TEXT_X_OFFSET;
    valueText.y = JACKPOT_BANNER_TEXT_Y_OFFSET;
    valueText.lastDigitCount = 0;
    valueText.style = {
      fill: color,
      fontSize: JACKPOT_BANNER_FONT_SIZE,
      fontFamily: 'brlnsr',
    };

    if (value) this._updateJackpotVariantValue(value, sprite, valueText, false);

    return { valueText, sprite };
  }

  public updateJackpotValues({
    majorJackpotValue,
    grandJackpotValue,
  }: {
    majorJackpotValue: number;
    grandJackpotValue: number;
  }) {
    if (this._grandJackpotValue !== grandJackpotValue) {
      this._grandJackpotValue = grandJackpotValue;
      this._jackpotLabelAnimationPromise = this._updateJackpotVariantValue(
        grandJackpotValue,
        this._grandSprite,
        this._grandText
      );
    }

    if (this._majorJackpotValue !== majorJackpotValue) {
      this._majorJackpotValue = majorJackpotValue;
      this._updateJackpotVariantValue(majorJackpotValue, this._majorSprite, this._majorText);
    }
  }

  private async _handleStakeChanged(newStake: number) {
    this._stakeValue = newStake;
    if (this._stakeUpdateIsQueued) return;

    if (this._jackpotLabelAnimationPromise) {
      this._stakeUpdateIsQueued = true;
      await this._jackpotLabelAnimationPromise;
    }

    this._jackpotLabelAnimationPromise = this._updateJackpotVariantValue(
      this._stakeValue * JACKPOT_MINOR_STAKE_MULTIPLIER,
      this._minorSprite,
      this._minorText
    );
    this._updateJackpotVariantValue(
      this._stakeValue * JACKPOT_MINI_STAKE_MULTIPLIER,
      this._miniSprite,
      this._miniText
    );
    this._stakeUpdateIsQueued = false;
  }

  turnCoin(x: number, y: number, tier: JackpotTier) {
    let resolve = () => {};

    let promise = new Promise<void>((res) => (resolve = res));
    const tierString = JackpotTier[tier];
    const startAnimation = this.coins[x + y * 4].spine.state.setAnimation(0, 'start', false);
    startAnimation.listener = {
      complete: () => {
        setTimeout(() => {
          this.coinsContainer.removeChild(this.coins[x + y * 4].spine);
          this.coins[x + y * 4].spine.destroy();

          setTimeout(() => {
            this.coins[x + y * 4].spine = Spine.from({
              skeleton: `jackpotCoin${tierString}Data`,
              atlas: `jackpotCoin${tierString}Atlas`,
            });
            this.coins[x + y * 4].tier = tier;

            this.coins[x + y * 4].spine.x = x * (COIN_WIDTH + COIN_GAP);
            this.coins[x + y * 4].spine.y = y * (COIN_HEIGHT + COIN_GAP);
            const secondAnimation = this.coins[x + y * 4].spine.state.setAnimation(
              0,
              'start',
              false
            );
            secondAnimation.listener = {
              complete: () => {
                resolve();
                const idleAnimation = this.coins[x + y * 4].spine.state.setAnimation(
                  0,
                  'after_turn',
                  true
                );
              },
            };
            this.coinsContainer.addChild(this.coins[x + y * 4].spine);
          }, 0);
        }, 0);
      },
    };
    return promise;
  }

  mountCoin(x: number, y: number) {
    let coinSpine = Spine.from({
      skeleton: 'jackpotCoinIdleData',
      atlas: 'jackpotCoinIdleAtlas',
    });
    this.coins[x + y * 4] = {
      spine: coinSpine,
    };

    const animation = coinSpine.state.setAnimation(0, 'idle', false);
    const handleClick = async () => {
      if (this.disableClick) return;
      this.disableClick = true;
      await this.turnCoin(x, y, this.jackpotResponse!.miniGameRevealOrder[this.clickIndex++]);
      let sameTierCounts = {
        [JackpotTier.Mini]: 0,
        [JackpotTier.Minor]: 0,
        [JackpotTier.Major]: 0,
        [JackpotTier.Grand]: 0,
      };
      for (let i = 0; i < this.clickIndex; i++) {
        sameTierCounts[this.jackpotResponse!.miniGameRevealOrder[i] as JackpotTier]++;
      }

      const tierWith3 = Object.keys(sameTierCounts).find(
        (key) => sameTierCounts[Number(key) as JackpotTier] === 3
      );
      if (tierWith3) {
        const promises: any[] = [];
        for (let x = 0; x < 4; x++) {
          for (let y = 0; y < 3; y++) {
            if (!this.coins[x + y * 4].tier) {
              promises.push(
                this.turnCoin(x, y, this.jackpotResponse!.miniGameRevealOrder[this.clickIndex++])
              );
            }
          }
        }

        await Promise.all(promises);

        // make shine winning coins
        this.coins
          .filter((coin) => coin.tier === Number(tierWith3))
          .forEach((coin) => {
            coin.spine.state.setAnimation(0, 'idle', true);
          });
        await delay(2000);
        const resultPopup = new Popup(
          [
            { label: getLocale('slots.ui.common.congratulations') },
            ...splitSentence(
              getLocale(
                'slots.ui.common.youWonXXJackpot',
                JackpotTier[Number(tierWith3) as JackpotTier]
              ),
              25
            ).map((el) => ({ label: el })),

            {
              label: fontSafeString(
                'goldenTextFont',
                formatAsCurrency(this.jackpotResponse?.winAmount as number)
              ),
              fontFamily: 'goldenTextFont',
              marginTop: 45,
            },
          ],
          {
            onClose: () => {
              this.unmountJackpotGame();
              this.game.gameDisabler.enable('jackpot');
            },
            zIndex: 102,
          }
        );
        resultPopup.mount();
      }
      this.disableClick = false;
    };

    animation.listener = {
      complete: () => {},
    };

    coinSpine.interactive = true;
    coinSpine.on('pointerup', handleClick);

    coinSpine.x = x * (COIN_WIDTH + COIN_GAP);
    coinSpine.y = y * (COIN_HEIGHT + COIN_GAP);

    this.coinsContainer.addChild(coinSpine);
  }

  handleResize = () => {
    if (!this.vaultSpine || !this.coinsContainer) return;
    this.coinScale = this.game.reelsManager.scale * 0.5;
    this.coinsContainer.scale.set(this.coinScale);
    this.vaultSpine.scale.set(this.game.reelsManager.scale * 0.65);
    const width = (COIN_WIDTH * 4 + COIN_GAP * 3) * this.coinScale;
    const height = (COIN_HEIGHT * 3 + COIN_GAP * 2) * this.coinScale;
    this.coinsContainer.x = this.game.width / 2 - width / 2 + (COIN_WIDTH * this.coinScale) / 2;
    this.coinsContainer.y = this.game.height / 2 - height / 2 + (COIN_HEIGHT * this.coinScale) / 2;
    this.vaultSpine.x = this.game.width / 2;
    this.vaultSpine.y = this.game.height / 2;
  };

  async animateVault() {
    let resolve = () => {};
    let promise = new Promise<void>((res) => (resolve = res));
    const openAnimation = this.vaultSpine.state.setAnimation(0, 'vault_open', false);
    openAnimation.listener = {
      complete: () => {
        setTimeout(() => {
          this.coinsContainer.zIndex = 1;
          this.coins.forEach((coin) => {
            coin.spine.visible = true;
          });
        }, 0);
        this.vaultSpine.state.setAnimation(0, 'idle', false);
        resolve();
      },
    };
    return promise;
  }

  async mountJackpotGame() {
    this.jackpotGameContainer = new Graphics();
    this.jackpotGameBackground = new Sprite(Texture.from('jackpotBackground'));
    this.jackpotGameBackground.scale.set(4);
    this.jackpotGameBackground.anchor.set(0.5);
    this.jackpotGameBackground.x = this.game.width / 2;
    this.jackpotGameBackground.y = this.game.height / 2;
    this.jackpotGameBackground.visible = false;
    this.jackpotGameContainer.addChild(this.jackpotGameBackground);
    this.jackpotGameContainer.x = 0;
    this.jackpotGameContainer.y = 0;
    this.jackpotGameContainer.zIndex = 100;
    this.coinsContainer = new Sprite();
    this.coinsContainer.anchor.set(0.5);

    this.vaultSpine = Spine.from({
      skeleton: `jackpotVaultData`,
      atlas: `jackpotVaultAtlas`,
    });

    const transitionAnimationTime = this._transitionManager.animateTransition();
    delay(transitionAnimationTime / 2).then(() => {
      this.game.onResize.addEventListener(this.handleResize);
      this.jackpotGameContainer.addChild(this.coinsContainer);
      this.game.app.stage.addChild(this.jackpotGameContainer);

      for (let x = 0; x < 4; x++) {
        for (let y = 0; y < 3; y++) {
          this.mountCoin(x, y);
        }
      }
      this.coins.forEach((coin) => {
        coin.spine.visible = false;
      });
      this.jackpotGameBackground.visible = true;
      this.jackpotGameContainer.addChild(this.vaultSpine);
      this.animateVault();

      this.handleResize();
    });
  }

  unmountJackpotGame() {
    this.coinsContainer.removeChildren();
    this.coinsContainer.destroy();
    const closeAnimation = this.vaultSpine.state.setAnimation(0, 'vault_close', false);
    closeAnimation.listener = {
      complete: () => {
        this.game.onResize.removeEventListener(this.handleResize);
        const animationTime = this._transitionManager.animateTransition(true);
        delay(animationTime / 2).then(() => {
          setTimeout(() => {
            this.jackpotGameContainer.destroy();
          }, 100);
        });
      },
    };
  }

  processSpinResponse(spinResponse: IGameSpinResponse) {
    this.jackpotResponse = spinResponse.jackpotResponse;
    this.clickIndex = 0;
    this.coins = [];
    this.disableClick = false;

    if (this.jackpotResponse?.winAmount > 0) {
      const handleFallComplete = () => {
        this.game.gameDisabler.disable('jackpot');
        const jackpotSprite = new Sprite(Texture.from('jackpot'));
        jackpotSprite.anchor.set(0.5);
        this.jackpotPopup = new Popup(
          splitSentence(getLocale('slots.ui.common.congratulationsForEnteringJackpot'), 25).map(
            (el) => ({ label: el })
          ),
          {
            onClose: () => {
              // this.game.gameDisabler.enable('jackpot');
              this.mountJackpotGame();
            },
            sprites: [{ sprite: jackpotSprite, yOffset: 75 }],
          }
        );
        this.jackpotPopup.mount();
        this.game.onBeforeFallComplete.removeEventListener(handleFallComplete);
      };

      this.game.onBeforeFallComplete.addEventListener(handleFallComplete);
    }
  }
}
