import SoundTrack from '../../../../game/managers/soundManager/soundTrack';

class MainMusicTrack extends SoundTrack {
  mount() {
    this.registerMusic('musBaseGameMain', { loop: true });
    this.registerMusic('musIntro');

    this._isMounted = true;
  }

  play() {
    this.getSound('musBaseGameMain')!.play();
  }

  playFromIntro() {
    const intro = this.getSound('musIntro')!;
    intro.play();
    intro.onComplete(() => {
      this.play();
    });
  }
}

export default MainMusicTrack;
