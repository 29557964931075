import { Particle } from 'pixi.js';
import ParticleRenderer from './particleRenderer';
import { registerLogCategory } from '../../../../debug/privateLogger';
import ParticleEmitterTimeline from './particleEmitterTimeline';

type TEmitterProgessParamType = {
  elapsedTime: number;
  elapsedTimeAtStart: number;
  elapsedProgress: number;
  emitterTime: number;
  emitterProgress: number;
};

type TParticleCreationPredictorInstance = Generator<number, void, TEmitterProgessParamType>;
type TParticleCreationPredictor = (progress: TEmitterProgessParamType) => TParticleCreationPredictorInstance;

type TEmitterParticleInterface = {
  update: (
    deltaTime: number,
    particleAge: number,
    timelineElapsedTime: number,
  ) => void;
  particle: Particle;
};
type TParticleCreatorInterface = (
  progress: TEmitterProgessParamType,
  removeParticle: () => void,
  index: number,
) => TEmitterParticleInterface;

const log = registerLogCategory('ParticleEmitter');

// Used to generate particles at specific times/frequency, etc. of a specific format/type as per code.
// Extreme flexibility is available, but it is ultimately about logical grouping and control.
class ParticleEmitter {
  private _particleCreationPredictor!: TParticleCreationPredictor;
  private _emitParticle!: TParticleCreatorInterface;

  private _particleRenderer!: ParticleRenderer;
  private _activeParticles = new Set<TEmitterParticleInterface>();
  private _particleAgesStart = new WeakMap<TEmitterParticleInterface, number>();
  private _particleDeltaStagger = new WeakMap<TEmitterParticleInterface, number>();
  private _staggerAcrossFrameDelta = true;

  constructor(
    // A generator function that predicts the next particle creation time
    particleCreationPredictor: TParticleCreationPredictor,
    // A function that creates a Particle and provides an update interface for it
    emitParticle: TParticleCreatorInterface,
    // A ParticleRenderer used to manage the display/rendering of the particle
    particleRenderer: ParticleRenderer,
    {
      staggerAcrossFrameDelta = true,
    }: {
      staggerAcrossFrameDelta?: boolean;
    } = {},
  ) {
    this._particleCreationPredictor = particleCreationPredictor;
    this._emitParticle = emitParticle;
    this._particleRenderer = particleRenderer;
    this._staggerAcrossFrameDelta = staggerAcrossFrameDelta;
    log(1)('ParticleEmitter', { _this: this, particleCreationPredictor, emitParticle, particleRenderer });
  };

  get particleCreationPredictor() {
    return this._particleCreationPredictor;
  }

  private _staggerProgressDelta(progress: TEmitterProgessParamType, deltaStagger: number) {
    return {
      ...progress,
      elapsedTime: progress.elapsedTime + deltaStagger,
      elapsedTimeAtStart: progress.elapsedTimeAtStart + deltaStagger,
      emitterTime: progress.emitterTime + deltaStagger,
    };
  }

  private _updatedSpecificParticle(
    particle: TEmitterParticleInterface,
    deltaTime: number,
    timelineElapsedTime: number,
  ) {
    const deltaStagger = this._particleDeltaStagger.get(particle)!;

    log(4)('updateParticles', {
      deltaTime,
      timelineElapsedTime,
      particle,
      started: this._particleAgesStart.get(particle),
      deltaStagger,
    });

    particle.update(
      deltaTime,
      timelineElapsedTime - this._particleAgesStart.get(particle)! + deltaStagger,
      timelineElapsedTime + deltaStagger,
    );
  }

  updateParticles(deltaTime: number, timelineElapsedTime: number) {
    log(3)('updateParticles', { deltaTime, timelineElapsedTime });
    Array.from(this._activeParticles.values()).forEach((particle) => {
      this._updatedSpecificParticle(particle, deltaTime, timelineElapsedTime);
    });
  }

  emitParticle(progress: TEmitterProgessParamType, index: number) {
    const ref = { current: undefined as unknown as TEmitterParticleInterface };
    const deltaStagger = this._staggerAcrossFrameDelta ? Math.random() * ParticleEmitterTimeline.lastDelta : 0;

    ref.current = this._emitParticle(progress, () => {
      this._activeParticles.delete(ref.current);
      this._particleRenderer.removeParticle(ref.current.particle);
    }, index);

    this._activeParticles.add(ref.current);
    this._particleAgesStart.set(ref.current, progress.elapsedTime);
    this._particleDeltaStagger.set(ref.current, deltaStagger);
    this._particleRenderer.addParticle(ref.current.particle);
    log(2)('emitParticle', { progress, index, particle: ref.current, deltaStagger });

    this._updatedSpecificParticle(ref.current, 0, progress.elapsedTime);
  }

  get activeParticleCount() {
    return this._activeParticles.size;
  }
}

export default ParticleEmitter;

export type {
  TParticleCreationPredictorInstance,
  TParticleCreationPredictor,
  TEmitterProgessParamType,
  TEmitterParticleInterface,
};
