import { vec2 } from 'gl-matrix';

const applyGravity = (container: { velocityY: number }, gravity: number, deltaTime: number) => {
  container.velocityY += gravity * deltaTime / 1000;
};

const applyFriction = (container: { velocity: number }, friction: number, deltaTime: number) => {
  const frictionDelta = friction * deltaTime / 1000;
  const velocityDelta = container.velocity * deltaTime / 1000;

  if (frictionDelta > velocityDelta)
    container.velocity = 0;
  else
    container.velocity -= frictionDelta;
};

// const applyWaveMotion = (
//   start: vec2,
//   end: vec2,
//   progress: number,
//   amplitude: number,
//   frequency: number,
//   phaseStart: number,
// ) => {
//   // Clamp progress and phaseStart to be between 0 and 1
//   progress = Math.min(Math.max(progress, 0), 1);
//   // @todo phaseStart should not be clamped, should be rolled over
//   phaseStart = Math.min(Math.max(phaseStart, 0), 1);
//
//   // Calculate the direction vector from start to end
//   const dirVec = vec2.create();
//   vec2.subtract(dirVec, end, start);
//
//   // Normalize the direction vector
//   vec2.normalize(dirVec, dirVec);
//
//   // Calculate the perpendicular vector to the direction (rotate by 90 degrees)
//   const perpDirVec = vec2.fromValues(-dirVec[1], dirVec[0]);
//
//   // Lerp between start and end points
//   const posVec = vec2.create();
//   vec2.lerp(posVec, start, end, progress);
//
//   // Apply sinusoidal wave oscillation along the perpendicular direction
//   const wave = Math.sin(frequency * progress * 2 * Math.PI + phaseStart * 2 * Math.PI) * amplitude;
//
//   // Calculate the final position by adding the wave to the perpendicular direction
//   vec2.add(posVec, posVec, vec2.scale(vec2.create(), perpDirVec, wave));
//
//   // Return the final x and y positions
//   return posVec;
// };

const applyWaveMotion = (
  start: vec2,
  end: vec2,
  progress: number,
  amplitude: number,
  frequency: number,
  phaseStart: number,
  phaseStartCorrection = false, // New optional property
) => {
  // Clamp progress to be between 0 and 1
  progress = Math.min(Math.max(progress, 0), 1);

  // Phase start is not clamped but rolled over into [0, 1] range
  phaseStart = phaseStart % 1;

  // Calculate the direction vector from start to end
  const dirVec = vec2.create();
  vec2.subtract(dirVec, end, start);

  // Normalize the direction vector
  vec2.normalize(dirVec, dirVec);

  // Calculate the perpendicular vector to the direction (rotate by 90 degrees)
  const perpDirVec = vec2.fromValues(-dirVec[1], dirVec[0]);

  // Lerp between start and end points
  const posVec = vec2.create();
  vec2.lerp(posVec, start, end, progress);

  // Apply sinusoidal wave oscillation along the perpendicular direction
  let wave = Math.sin(frequency * progress * 2 * Math.PI + phaseStart * 2 * Math.PI) * amplitude;

  // Correct phaseStart offset if the option is enabled
  if (phaseStartCorrection) {
    const correction = Math.sin(phaseStart * 2 * Math.PI) * amplitude;
    wave -= correction;
  }

  // Calculate the final position by adding the wave to the perpendicular direction
  vec2.add(posVec, posVec, vec2.scale(vec2.create(), perpDirVec, wave));

  // Return the final x and y positions
  return posVec;
};

export {
  applyGravity,
  applyFriction,
  applyWaveMotion,
};
