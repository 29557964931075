import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
//
// import { Application, ParticleContainer, Particle, Assets } from 'pixi.js';
//
// // 1) Create <canvas> and append to DOM
// const canvas = document.createElement('canvas');
// document.body.appendChild(canvas);
//
// // 2) Create a Pixi Application that draws to our new canvas
// const app = new Application({
//   width: 800,
//   height: 600,
//   backgroundColor: 0x1099bb,
//   view: canvas,
// });
// // @ts-ignore
// globalThis.__PIXI_APP__ = app;
// // 3) Make a ParticleContainer that tracks alpha
// const container = new ParticleContainer({
//   dynamicProperties: {
//     x: true,
//     y: true,
//     alpha: true,
//     // scaleX, scaleY, rotation, etc. if you need them
//   },
// });
// app.stage.addChild(container);
//
// // 4) Use an async function to load the bunny texture
// (async () => {
//   // Initialize the Assets system (optional but recommended in some setups)
//   await Assets.init();
//
//   // Register + load the bunny under an alias "bunny"
//   // (Pixi’s official bunny image from a public URL)
//   await Assets.add({
//     alias: 'bunny',
//     src: '/assets/games/dede/glowingParticle1.png',
//   });
//
//   // This actually downloads the file and stores it in the Assets manager
//   const bunnyTexture = await Assets.load('bunny');
//   console.log('Loaded texture:', bunnyTexture);
//
//   // Create a Particle using the newly loaded texture
//   const bunnyParticle = new Particle({
//     // IMPORTANT: Use the actual texture you got back from Assets.load
//     texture: bunnyTexture,
//     x: 200,
//     y: 200,
//   });
//
//   // 5) Add the particle to the container
//   //    If TypeScript says `addParticle` is correct, use that; otherwise `addChild` works too
//   container.addParticle(bunnyParticle);
//
//   // Done! You should see a semi-transparent bunny at (200, 200).
// })();
