import { Graphics, Sprite, Text, Texture } from 'pixi.js';
import { Dede } from '.';
import Popup from './models/popup';

export class AnteBetManager {
  popup!: Popup;

  constructor(private game: Dede) {
    this.popup = new Popup([{ label: 'Activate AnteBet?' }], {
      onOk: () => {
        this.game.anteBetActive = true;
      },
      onCancel: () => {},
    });
    this.game.onGoldenBetClick = () => {
      this.game.onClick.triggerEvent();
      if (this.game.anteBetActive) {
        this.game.anteBetActive = false;
      } else this.popup.mount();
    };
  }
}
