// splint sentence if it is too long
export const splitSentence = (sentence: string, maxLength: number) => {
  const words = sentence.split(' ');
  const lines: string[] = [];
  let currentLine = '';
  for (let i = 0; i < words.length; i++) {
    if (currentLine.length + words[i].length + 1 <= maxLength) {
      currentLine += words[i] + ' ';
    } else {
      lines.push(currentLine.trim());
      currentLine = words[i] + ' ';
    }
  }
  lines.push(currentLine.trim());
  return lines;
};
