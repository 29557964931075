import { Sprite, Texture } from 'pixi.js';
import GameController from '.';
import Button from '../button';
import {
  LANDSCAPE_ADD_BET_BUTTON_CONTENT_X,
  LANDSCAPE_ADD_BET_BUTTON_CONTENT_Y,
  LANDSCAPE_ADD_BET_BUTTON_HEIGHT,
  LANDSCAPE_ADD_BET_BUTTON_WIDTH,
  LANDSCAPE_ADD_BET_BUTTON_X,
  LANDSCAPE_ADD_BET_BUTTON_Y,
  LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_X,
  LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_Y,
  LANDSCAPE_AUTOPLAY_BUTTON_HEIGHT,
  LANDSCAPE_AUTOPLAY_BUTTON_WIDTH,
  LANDSCAPE_AUTOPLAY_BUTTON_X,
  LANDSCAPE_AUTOPLAY_BUTTON_Y,
  LANDSCAPE_INFO_BUTTON_CONTENT_X,
  LANDSCAPE_INFO_BUTTON_CONTENT_Y,
  LANDSCAPE_INFO_BUTTON_X,
  LANDSCAPE_INFO_BUTTON_Y,
  LANDSCAPE_SETTINGS_BUTTON_X,
  LANDSCAPE_SETTINGS_BUTTON_Y,
  LANDSCAPE_SOUND_BUTTON_X,
  LANDSCAPE_SOUND_BUTTON_Y,
  LANDSCAPE_SPIN_BUTTON_CONTENT_HEIGHT,
  LANDSCAPE_SPIN_BUTTON_CONTENT_WIDTH,
  LANDSCAPE_SPIN_BUTTON_CONTENT_X,
  LANDSCAPE_SPIN_BUTTON_CONTENT_Y,
  LANDSCAPE_SPIN_BUTTON_HEIGHT,
  LANDSCAPE_SPIN_BUTTON_WIDTH,
  LANDSCAPE_SPIN_BUTTON_X,
  LANDSCAPE_SPIN_BUTTON_Y,
  LANDSCAPE_SPIN_CONTAINER_HEIGHT,
  LANDSCAPE_SPIN_CONTAINER_WIDTH,
  LANDSCAPE_SPIN_CONTAINER_X,
  LANDSCAPE_SPIN_CONTAINER_Y,
  LANDSCAPE_STAKE_BUTTON_CONTENT_X,
  LANDSCAPE_STAKE_BUTTON_CONTENT_Y,
  LANDSCAPE_STAKE_BUTTON_HEIGHT,
  LANDSCAPE_STAKE_BUTTON_SCALE,
  LANDSCAPE_STAKE_BUTTON_WIDTH,
  LANDSCAPE_STAKE_BUTTON_X,
  LANDSCAPE_STAKE_BUTTON_Y,
  LANDSCAPE_SUB_BET_BUTTON_CONTENT_X,
  LANDSCAPE_SUB_BET_BUTTON_CONTENT_Y,
  LANDSCAPE_SUB_BET_BUTTON_HEIGHT,
  LANDSCAPE_SUB_BET_BUTTON_WIDTH,
  LANDSCAPE_SUB_BET_BUTTON_X,
  LANDSCAPE_SUB_BET_BUTTON_Y,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_HEIGHT,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_WIDTH,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_X,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_Y,
  LANDSCAPE_TURBO_SPIN_BUTTON_HEIGHT,
  LANDSCAPE_TURBO_SPIN_BUTTON_WIDTH,
  LANDSCAPE_TURBO_SPIN_BUTTON_X,
  LANDSCAPE_TURBO_SPIN_BUTTON_Y,
  SMALL_BUTTON_HEIGHT,
  SMALL_BUTTON_WIDTH,
} from './constants';
import Popup from '../popup';
import CachedSettingsManager from '../../../../cachedSettingsManager';
import { ANTEBET_MULTIPLIER } from '../../resources/constants';
import { getLocale } from '../../../../../localization';
import { splitSentence } from '../../../../utils/text';

export const mountLandscapeButtons = (controller: GameController) => {
  controller.settingsButton = new Button(controller.container, {
    backgroundTexture: 'config',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.onSettingsButtonClick.triggerEvent();
    },
    backgroundPosition: {
      x: LANDSCAPE_SETTINGS_BUTTON_X,
      y: LANDSCAPE_SETTINGS_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: 0,
      y: 0,
    },
  });

  controller.soundButton = new Button(controller.container, {
    backgroundTexture: CachedSettingsManager.get('isSoundEnabled') ? 'sound' : 'soundDisabled',
    onClick: () => {
      controller.game.onClick.triggerEvent();

      CachedSettingsManager.set('isSoundEnabled', !CachedSettingsManager.get('isSoundEnabled'));
      controller.soundButton.background.texture = Texture.from(
        CachedSettingsManager.get('isSoundEnabled') ? 'sound' : 'soundDisabled'
      );
    },
    backgroundPosition: {
      x: LANDSCAPE_SOUND_BUTTON_X,
      y: LANDSCAPE_SOUND_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: 0,
      y: 0,
    },
  });

  controller.infoButton = new Button(controller.container, {
    backgroundTexture: 'info',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.onInfoButtonClick.triggerEvent();
    },
    backgroundPosition: {
      x: LANDSCAPE_INFO_BUTTON_X,
      y: LANDSCAPE_INFO_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: LANDSCAPE_INFO_BUTTON_CONTENT_X,
      y: LANDSCAPE_INFO_BUTTON_CONTENT_Y,
    },
  });
};
