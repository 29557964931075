import { Game } from '../../lib/games/game';

export class TestHarnessClient {
  private handleMessage: (event: MessageEvent) => void;

  constructor(private game: Game) {
    // Define the handleMessage function
    this.handleMessage = this.handleIncomingMessage.bind(this);

    // Add event listener for messages
    window.addEventListener('message', this.handleMessage);
  }

  // Function to handle incoming messages
  private handleIncomingMessage(event: MessageEvent) {
    const message = event.data;

    if (typeof message === 'object' && message.type) {
      switch (message.type) {
        case 'testharness.spin':
          console.log('Received spin message', message);
          window.testData = { testSymbols: message.payload };
          this.game.anteBetActive = message.anteBetEnabled;
          this.game.runReels(false);
          break;
        case 'testharness.buyFreeSpin':
          console.log('Received buyFreeSpin message', message);
          window.testData = {};
          this.game.runReels(true);
          break;
        case 'testharness.simulateSpin':
          console.log('Received simulateSpin message', message);
          this.game.simulate(message.payload);
          break;
        case 'testharness.simulateJackpot':
          console.log('Received simulateJackpot message', message);
          window.testData = { jackpotTier: message.payload };
          this.game.runReels(false);
          break;
      }
    }
  }

  // Method to clean up and remove the event listener
  public destroy() {
    window.removeEventListener('message', this.handleMessage);
  }
}
