import { Ticker } from 'pixi.js';
import ParticleEmitter, { TParticleCreationPredictorInstance } from './particleEmitter';
import { registerLogCategory } from '../../../../debug/privateLogger';

type TParticleEmitterTimelineTimeline = {
  startTime: number;
  endTime: number;
  particleEmitterName: string;
};

const log = registerLogCategory('ParticleEmitterTimeline');

const lastDeltaRef = { delta: 0.001, lastTime: performance.now() };
Ticker.shared.add(() => {
  const now = performance.now();
  const delta = now - lastDeltaRef.lastTime;
  lastDeltaRef.delta = delta;
  lastDeltaRef.lastTime = now;
});

// This class controls the usage of one or more particle emitters across time.  It also runs their updates.
class ParticleEmitterTimeline {
  // A list (object) of ParticleEmitters that can be drawn upon
  private _emitters!: { [key: string]: ParticleEmitter };
  // A collection of timeline objects saying which emitters are used and when.
  // The same emmitter can be used multiple times, provided you coded them to be capable of such.
  private _timeline!: TParticleEmitterTimelineTimeline[];
  private _timeLineEmitterGenerators = new Map<
    number,
    { generator: TParticleCreationPredictorInstance; value: number; done: boolean }
  >();

  private _timeStarted!: number;
  private _timeStopParticleGeneration!: number;
  private _isBusyPromise!: Promise<void>;

  static get lastDelta() {
    return lastDeltaRef.delta;
  }

  constructor(emitters: { [key: string]: ParticleEmitter }, timeline: TParticleEmitterTimelineTimeline[]) {
    this._emitters = emitters;
    this._timeline = timeline;
    this._timeStarted = performance.now();
    this._timeStopParticleGeneration = this._timeline.reduce((acc, { endTime }) => Math.max(acc, endTime), 0);

    this._isBusyPromise = new Promise((resolve) => {
      const lifeCycleRef = { lastTime: performance.now(), tickerCallback: () => {
        const now = performance.now();
        const deltaTime = now - lifeCycleRef.lastTime;
        const elapsedTime = now - this._timeStarted;
        const timelineProgress = elapsedTime / this._timeStopParticleGeneration;
        lifeCycleRef.lastTime = now;

        this._process({ delta: deltaTime, progress: timelineProgress, elapsedTime });

        if (now - this._timeStarted > this._timeStopParticleGeneration && Object.values(this._emitters).every(
          (emitter) => emitter.activeParticleCount === 0,
        )) {
          Ticker.shared.remove(lifeCycleRef.tickerCallback, this);
          resolve();
          return;
        }
      } };

      Ticker.shared.add(lifeCycleRef.tickerCallback, this);
    });

    log(1)('ParticleEmitterTimeline', { _this: this, timeline, emitters });
  }

  get isBusyPromise() {
    return this._isBusyPromise;
  }

  private _process({
    delta: deltaTime,
    progress: timelineProgress,
    elapsedTime,
  }: { delta: number; progress: number; elapsedTime: number }) {
    log(2)('process', { deltaTime, timelineProgress, elapsedTime });

    this._timeline.forEach(({ startTime, endTime, particleEmitterName }, ind) => {
      log(3)('_timeline.forEach', { startTime, endTime, particleEmitterName });
      if (elapsedTime >= startTime) {
        const elapsedTimeAtStart = startTime;
        this._emitters[particleEmitterName].updateParticles(deltaTime, elapsedTime);

        if (elapsedTime <= endTime) {
          const elapsedProgress = timelineProgress;
          const emitterTime = elapsedTime - elapsedTimeAtStart;
          const emitterProgress = emitterTime / (endTime - startTime);

          const progressParam = {
            elapsedTime,
            elapsedTimeAtStart,
            elapsedProgress,
            emitterTime,
            emitterProgress,
          };

          if (!this._timeLineEmitterGenerators.has(ind)) {
            this._timeLineEmitterGenerators.set(
              ind,
              {
                generator: this._emitters[particleEmitterName].particleCreationPredictor(progressParam),
                value: 0,
                done: false,
              },
            );
            const { value, done } = this._timeLineEmitterGenerators.get(ind)!.generator.next();
            this._timeLineEmitterGenerators.get(ind)!.value = value as number;
            this._timeLineEmitterGenerators.get(ind)!.done = done ?? false;
            log(3)('generator created', { ind, value: this._timeLineEmitterGenerators.get(ind)!.value });
          }

          let nextEmissionTime = this._timeLineEmitterGenerators.get(ind)!.value;
          while (elapsedTime >= nextEmissionTime && !this._timeLineEmitterGenerators.get(ind)!.done) {
            this._emitters[particleEmitterName].emitParticle(progressParam, ind);

            if (!this._timeLineEmitterGenerators.get(ind)!.done) {
              const { value, done } = this._timeLineEmitterGenerators.get(ind)!.generator.next(progressParam);
              this._timeLineEmitterGenerators.get(ind)!.value = value as number;
              this._timeLineEmitterGenerators.get(ind)!.done = done ?? false;
              nextEmissionTime = this._timeLineEmitterGenerators.get(ind)!.value;
            }
            else
              this._timeLineEmitterGenerators.get(ind)!.value = -1;
            log(4)('emitted particle', { ind, value: this._timeLineEmitterGenerators.get(ind)!.value });
          }
        }
      }
    });
  }
}

export default ParticleEmitterTimeline;

export type {
  TParticleEmitterTimelineTimeline,
};
