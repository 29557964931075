// Decides if the currently set color scheme is set to 'light' or 'dark' at time it is run.
// Returns null if it is unable to decide.
const detectColorScheme = () => {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
    return 'dark';
  else
    return 'light';
};

// On bundle load, detect the current color scheme.
let colorScheme = detectColorScheme() as 'dark' | 'light';

if (window.matchMedia) {
  // Attempt to detect whenever the current color scheme updates and store the new state
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
    if (e.matches)
      colorScheme = 'dark';
    else
      colorScheme = 'light';
  });
}

// A wrapper function to always get the most recently known value when called
const getColorScheme = () => colorScheme;

export default getColorScheme;
