import { registerLogCategory } from '../../debug/privateLogger';
import { TLogLevel } from '../../debug/privateLogger/privateLogger.types';
import Queue from '../gameEvent/queue';

const mainLog = registerLogCategory('GameEvent');

export interface IGameEventDetails {
  stopPropagation: () => void;
  index: number;
}

export type TBlankEvent = (event: IGameEventDetails, _: unknown) => void;

const _mainQueue: Queue = new Queue();

class GameEvent<T extends (event: IGameEventDetails, value: V) => void = TBlankEvent, V = Parameters<T>[1]> {
  private _listenersWithPriority: { listener: T; priority: number }[] = [];
  private _log!: ReturnType<typeof registerLogCategory>;

  constructor(name: string) {
    const uid = Math.random().toString(36).substring(2, 8);
    const log = registerLogCategory(`GameEvent->${name}`);
    this._log = (logLevel: TLogLevel) => {
      return (...logItems: unknown[]) => {
        log(logLevel)(uid, ...logItems);
        mainLog(logLevel)(name, uid, ...logItems);
      };
    };
  }

  static get mainQueue() {
    return _mainQueue;
  }

  // Priority 0 is the default priority; higher priorities will be called first
  addEventListener(listener: T, priority: number = 0) {
    this._listenersWithPriority.push({ listener, priority });
    this._listenersWithPriority.sort((a, b) => b.priority - a.priority);
    this._log(2)('add event');
    return () => this.removeEventListener(listener);
  }

  addEventListenerOnce(listener: T, priority: number = 0) {
    const _listener = (event: IGameEventDetails, value: V) => {
      this.removeEventListener(_listener as T);
      return listener(event, value);
    };
    this._listenersWithPriority.push({ listener: _listener as T, priority });
    this._listenersWithPriority.sort((a, b) => b.priority - a.priority);
    this._log(2)('add once event');
  }

  removeEventListener(listener: T) {
    this._listenersWithPriority = this._listenersWithPriority.filter(
      (l) => l.listener !== listener
    );
  }

  removeAllListeners() {
    this._listenersWithPriority = [];
  }

  async triggerEvent(value?: V) {
    let shouldStopPropagation = false;
    this._log(2)('trigger event', { value });

    const event: IGameEventDetails = {
      stopPropagation: () => {
        shouldStopPropagation = true;
      },
      index: 0,
    };
    for (const { listener } of this._listenersWithPriority) {
      if (shouldStopPropagation) break;

      this._log(3)('trigger listener', { value });
      await listener(event, value as V);
      event.index++;
    }
  }
}
export default GameEvent;

export type { IGameEventDetails as IEventDetails };
