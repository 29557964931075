type TQueueItem = (...args: unknown[]) => (Promise<void> | void);
type TQueueEntry = { queueItem: TQueueItem; priority: number };

class Queue {
  private _queue: TQueueEntry[] = [];
  private _isProcessing = false;
  private _isPaused = false;

  get isPaused() {
    return this._isPaused;
  }

  pause() {
    this._isPaused = true;
  }

  resume() {
    this._isPaused = false;
    this._process();
  }

  private async _process() {
    if (this._isProcessing || this._isPaused || !this._queue.length)
      return;

    let promise = undefined;
    while (!promise && this._queue.length) {
      const { queueItem } = this._queue.shift() as TQueueEntry;
      promise = queueItem() ?? undefined;
    }

    if (promise) {
      this._isProcessing = true;
      await promise;
      this._isProcessing = false;

      this._process();
    }
  }

  add(queueItem: TQueueItem, priority = 0) {
    this._queue.push({ queueItem, priority });
    this._queue.sort(({ priority: priorityA }, { priority: priorityB }) => priorityB - priorityA);

    this._process();
  }
}

export default Queue;
