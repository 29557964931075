const getRandomPointInCircle = (radius: number, distributeEvenly = true) => {
  const angle = Math.random() * 2 * Math.PI; // Random angle between 0 and 2π
  // Square root for uniform distribution
  const distance = distributeEvenly ? Math.sqrt(Math.random()) : Math.random() * radius;
  const x = Math.cos(angle) * distance;
  const y = Math.sin(angle) * distance;
  return { x, y };
};

export {
  getRandomPointInCircle,
};
