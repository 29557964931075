// SymbolHelper.ts
export class ScreenSizeHelper {
  static getScreenSizes() {
    return [
      { text: 'iPhone 13', width: 390, height: 844 },
      { text: 'iPhone 13 Pro Max', width: 430, height: 932 },
      { text: 'Samsung Galaxy S21', width: 360, height: 800 },
      { text: 'Google Pixel 5', width: 393, height: 851 },
      { text: 'iPhone SE', width: 375, height: 667 },
      { text: 'iPhone 11', width: 414, height: 896 },
    ];
  }
}
