import { registerLogCategory } from '../../../debug/privateLogger';
import SoundBase from './soundBase';
import Debouncer from '../../../utils/debounce';

const soundLog = registerLogCategory('SoundEffect');

class SoundEffect extends SoundBase {
  private _debounce!: Debouncer<void>;

  constructor(
    soundName: string,
    {
      debounceTime = 150,
      duckingVolumeMultiplier = 0.3,
      duckOutDuration = 300,
      maxVolume = 1,
    }: {
      debounceTime?: number;
      duckingVolumeMultiplier?: number;
      duckOutDuration?: number;
      maxVolume?: number;
    } = {},
  ) {
    super(soundName, { duckingVolumeMultiplier, duckOutDuration, maxVolume });

    soundLog(1)('constructor', soundName, { debounceTime });

    this._debounce = new Debouncer<void>(debounceTime, { maxEmitDelay: debounceTime });

    this._debounce.onEmit.addEventListener(() => {
      soundLog(3)('onEmit', soundName);
      this._debouncedPlay();
    });
  }

  private _debouncedPlay() {
    soundLog(2)('play', this._soundName);
    this._soundBasePlay();
  }

  play() {
    soundLog(3)('request', this._soundName);
    this._debounce.add();
  }
}

export default SoundEffect;
