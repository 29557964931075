import React, { useEffect } from 'react';

export interface SliderButtonProps {
  onChange: (value: boolean) => void;
  value: boolean;
}

const OriginalWidth = 244;
const OriginalHeight = 124;

const SliderButton: React.FC<SliderButtonProps> = ({ onChange, value }) => {
  const [isEnabled, setIsEnabled] = React.useState(false);
  const bumpRef = React.useRef<HTMLImageElement>(null);
  const interValRef = React.useRef<NodeJS.Timeout | undefined>(undefined);

  const width = 60;
  const bumpWidth = 106 * (width / OriginalWidth) - 3;
  const bumpHeight = 101 * (width / OriginalWidth) - 3;

  useEffect(() => {
    setIsEnabled(value);
  }, [value]);
  const bumpSrc = isEnabled
    ? 'assets/games/dede/slideButton/bump.webp'
    : 'assets/games/dede/slideButton/grey-bump.webp';

  const pxStringToInt = (pxString: string) => parseInt(pxString.replace('px', ''));
  return (
    <div
      style={{
        background: `url('assets/games/dede/slideButton/slider.webp')`,
        width: width,
        maxWidth: width,
        height: OriginalHeight * (width / OriginalWidth),
        maxHeight: OriginalHeight * (width / OriginalWidth),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      onClick={() => {
        let newValue = !isEnabled;
        setIsEnabled(newValue);
        onChange(newValue);
        clearInterval(interValRef.current as NodeJS.Timeout);
        interValRef.current = setInterval(() => {
          if (newValue) {
            bumpRef.current!.style.marginLeft = `${
              pxStringToInt(bumpRef.current!.style.marginLeft) + 1
            }px`;
            if (pxStringToInt(bumpRef.current!.style.marginLeft) >= width - bumpWidth - 2) {
              clearInterval(interValRef.current as NodeJS.Timeout);
            }
          } else {
            bumpRef.current!.style.marginLeft = `${
              pxStringToInt(bumpRef.current!.style.marginLeft) - 1
            }px`;
            if (pxStringToInt(bumpRef.current!.style.marginLeft) <= 2) {
              clearInterval(interValRef.current as NodeJS.Timeout);
            }
          }
        });
      }}
    >
      {/* <img src='assets/games/dede/slideButton/slider.webp' /> */}
      <img
        ref={bumpRef}
        style={{
          marginLeft: '2px',
          width: bumpWidth,
          height: bumpHeight,
        }}
        src={bumpSrc}
      />
    </div>
  );
};

export default SliderButton;
