import { Dede } from '.';
import { Spine } from '@pixi/spine-pixi';

const X_OFFSET_LANDSCAPE = 1155;
const Y_OFFSET_LANDSCAPE = 800;
const Y_OFFSET = -129;

export class LogoManager {
  private logoSpine!: Spine;

  constructor(private game: Dede) {}

  handleResize = () => {
    const orientation = this.game.getOrientation();
    if (orientation === 'portrait') {
      this.logoSpine.x = this.game.width / 2;
      this.logoSpine.y =
        this.game.reelsManager.container.y + Y_OFFSET * this.game.reelsManager.scale;
    }
    else {
      this.logoSpine.x =
        this.game.reelsManager.container.x + X_OFFSET_LANDSCAPE * this.game.reelsManager.scale;
      this.logoSpine.y =
        this.game.reelsManager.container.y + Y_OFFSET_LANDSCAPE * this.game.reelsManager.scale;
    }
    this.logoSpine.scale.set(0.15 * this.game.reelsManager.scale);
    this.logoSpine.zIndex = 4;
  };

  async mount() {
    this.logoSpine = Spine.from({ skeleton: 'logoData', atlas: 'logoAtlas' });
    this.logoSpine.state.setAnimation(0, 'animation', true);
    this.game.app.stage.addChild(this.logoSpine);
    this.handleResize();

    this.game.onResize.addEventListener(this.handleResize);
  }
}
