// @ts-ignore
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { IGameSettings } from '../../lib/game/managers/settingsManager';
import { Game } from '../../lib/games/game';
import { useState } from 'react';
import { Button, Flex, Modal } from 'antd';

const GameSettingsEditor: React.FC<{ onSave: () => void }> = ({ onSave }) => {
  const [initialSettings] = useState<IGameSettings>(Game.settings.getJSON());
  const [settings, setSettings] = useState<IGameSettings>(initialSettings);
  const [editorKey, setEditorKey] = useState(Date.now());

  const handleRefresh = () => {
    setSettings(Game.settings.getJSON());
    setEditorKey(Date.now());
  };
  const handleSave = () => {
    Game.settings.load(settings);
    onSave();
  };
  const handleReset = () => {
    Modal.confirm({
      title: 'Factory Reset',
      content: 'Are you sure you want to reset all settings to default?',
      onOk: () => {
        Game.settings.reset();
        setSettings(Game.settings.getJSON());
        setEditorKey(Date.now());
        onSave();
      },
    });
  };
  return (
    <div>
      <Editor
        key={editorKey}
        mode="form"
        value={settings}
        onChange={(e: IGameSettings) => {
          setSettings(e);
        }}
        history
      />
      <Flex>
        <Button
          disabled={JSON.stringify(initialSettings) === JSON.stringify(settings)}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button onClick={handleRefresh}>Refresh</Button>
        <Button onClick={handleReset}>Factory Reset</Button>
      </Flex>
    </div>
  );
};

export default GameSettingsEditor;
